import { Component, Input } from '@angular/core';
import { Report } from '../report/report';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TypeTreasuryService } from '../type-treasury/type-treasury.service';
import { TypeTreasury } from '../type-treasury/type-treasury';

@Component({
  selector: 'app-request-information',
  templateUrl: './request-information.component.html',
  styleUrls: ['./request-information.component.css'],
})
export class RequestInformationComponent {
  form = new UntypedFormGroup({
    nameType: new UntypedFormControl(''),
    comments: new UntypedFormControl(''),
  });
  typeTreasury: TypeTreasury[] = [];
  @Input() requestInformation: Report;
  constructor(private _typeTreasuryService: TypeTreasuryService) {}

  ngOnInit(): void {
    this.getTypeTreasury();
  }
  getTypeTreasury() {
    this._typeTreasuryService.getTypesTreasure().subscribe((x: any) => {
      this.typeTreasury = x;
      this.setForm();
    });
  }
  setForm(): void {
    var NAME_TYPE = this.requestInformation.NAME_TYPE;
    if (NAME_TYPE == null) {
      var typeTreasury = this.typeTreasury.find(x => x.ID_TYPE_TREASURY == this.requestInformation.ID_TYPE_TREASURY);
      NAME_TYPE = typeTreasury.NAME_TYPE;
    }
    this.form.setValue({
      nameType: NAME_TYPE,
      comments: this.requestInformation.COMMENTS,
    });
  }
}
