import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TypeEmployee } from 'src/app/type-employee/type-employee';
import { TypeEmployeeService } from 'src/app/type-employee/type-employee.service';
import Swal from 'sweetalert2';
import { TypeExpense } from '../type-expense';
import { TypeExpenseService } from '../type-expense.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-type-expense-edit',
  templateUrl: './type-expense-edit.component.html',
  styleUrls: ['./type-expense-edit.component.css'],
})
export class TypeExpenseEditComponent implements OnInit {
  @Input() typeExpense: TypeExpense;
  @Output() updateTypeExpense = new EventEmitter();

  typeEmployees: TypeEmployee[] = [];
  editTypeExpense: TypeExpense = new TypeExpense();
  form = new UntypedFormGroup({
    tipoGasto: new UntypedFormControl('', Validators.required),
    tipoEmpleado: new UntypedFormControl([], Validators.required),
    descripcion: new UntypedFormControl('', Validators.required),
  });
  isDesktop: boolean = false;
  public typeEmployeeFilterControl: FormControl = new FormControl();
  public filteredtypeEmployee: ReplaySubject<TypeEmployee[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  constructor(
    private _typeExpenseService: TypeExpenseService,
    private _typeEmployee: TypeEmployeeService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.markFormGroupTouched(this.form);
    this.getTypeEmploye()
      .asObservable()
      .subscribe(x => {
        this.clone();
      });
    this.getInfoDevice();
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }
  getTypeEmploye() {
    var typeEmployee = new Subject<TypeEmployee[]>();
    this._typeEmployee.getTypeEmployees().subscribe((x: any) => {
      this.typeEmployees = x;
      this.filteredtypeEmployee.next(this.typeEmployees.slice());
      //Apply changes subscripted to the filter
      this.typeEmployeeFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTypeItemComboBox();
      });

      typeEmployee.next(this.typeEmployees);
    });

    return typeEmployee;
  }
  protected filterTypeItemComboBox() {
    if (!this.typeEmployees) {
      return;
    }

    let search = this.typeEmployeeFilterControl.value;
    if (!search) {
      this.filteredtypeEmployee.next(this.typeEmployees.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredtypeEmployee.next(
      this.typeEmployees.filter(typeEmployee => typeEmployee.NAME_TYPE_EMPLOYEE.toLowerCase().indexOf(search) > -1)
    );
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  update() {
    Swal.fire({
      icon: 'info',
      title: 'Actualizando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editTypeExpense.editable = false;
    this.editTypeExpense.NAME_TYPE_EXPENSE = this.form.value.tipoGasto;
    this.editTypeExpense.DESCRIPTION = this.form.value.descripcion;
    this.editTypeExpense.ID_TYPE_EMPLOYEE = this.form.value.tipoEmpleado.ID_TYPE_EMPLOYEE;
    var self = this;
    this._typeExpenseService.update(this.editTypeExpense).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la edición. Nombre duplicado',
        });
      } else {
        self.popUpUpdate();
      }
    });
  }
  popUpUpdate() {
    Swal.fire({
      icon: 'success',
      title: 'Tipo de gasto actualizado',
      text: 'El tipo de gasto fué actualizado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      this.updateTypeExpense.emit();
    });
  }

  habilitar() {
    Swal.fire({
      icon: 'info',
      title: 'Habilitando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editTypeExpense.ID_TYPE_AVAILABLE = 1;
    var self = this;
    this._typeExpenseService.update(this.editTypeExpense).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del tipo de gasto',
        });
      } else {
        self.updateHabilitar();
      }
    });
  }

  updateHabilitar() {
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'Tipo de gasto habilitado.',
      text: 'El tipo de gasto fué habilitado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      self.updateTypeExpense.emit();
    });
  }

  deshabilitar() {
    Swal.fire({
      icon: 'info',
      title: 'Deshabilitando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editTypeExpense.ID_TYPE_AVAILABLE = 2;
    var self = this;
    this._typeExpenseService.update(this.editTypeExpense).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del tipo de gasto',
        });
      } else {
        self.updateDeshabilitar();
      }
    });
  }

  updateDeshabilitar() {
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'Tipo de gasto deshabilitado.',
      text: 'El tipo de gasto fué deshabilitado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      self.updateTypeExpense.emit();
    });
  }

  clone() {
    Object.assign(this.editTypeExpense, this.typeExpense);
    this.form.controls.tipoGasto.setValue(this.editTypeExpense.NAME_TYPE_EXPENSE);
    this.form.controls.descripcion.setValue(this.editTypeExpense.DESCRIPTION);
    var typeEmployee = this.typeEmployees.find(x => x.ID_TYPE_EMPLOYEE === this.editTypeExpense.ID_TYPE_EMPLOYEE);
    this.form.controls.tipoEmpleado.setValue(typeEmployee);
  }
}
