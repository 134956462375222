export class TypeExpense {
    
    constructor(
        public ID_TYPE_EXPENSE: number = 0,
        public NAME_TYPE_EXPENSE: string = "",
        public ID_TYPE_EMPLOYEE: number = 0,
        public DESCRIPTION: string = "",
        public editable: boolean = false,
        public disabled: boolean = false,
        public ID_TYPE_AVAILABLE : number = 0
    ){

    }
}