import { Component, OnInit } from '@angular/core';
import { Employee } from '../employee/employee';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Modal } from '../misc/modal';

@Component({
  selector: 'app-sesion',
  templateUrl: './sesion.component.html',
  styleUrls: ['./sesion.component.css'],
})
export class SesionComponent extends Modal implements OnInit {
  clients: Employee[] = [];
  correoString = new String();
  passString = new String();
  invalid: boolean = false;
  user: Employee;

  form = new UntypedFormGroup({
    username: new UntypedFormControl('', Validators.required),
    pass: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private app: AppComponent,
    private router: Router,
    private authService: AuthService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  ngOnInit() {
    this.resetLocalStorage();
  }

  resetLocalStorage() {
    localStorage.removeItem('token');
    this.app.setLogIn(-1);
  }

  loginUser() {
    Swal.fire({
      icon: 'info',
      title: 'Iniciando sesión...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    var username = this.form.value.username;
    var pass = this.form.value.pass;
    if (username === '' || pass === '') {
      this.invalid = true;
      return;
    }
    this.authService.login(username, pass).subscribe((res: any) => {
      if (res.code == '200') {
        Swal.fire({
          icon: 'success',
          title: 'Inicio de sesión.',
          timer: 2200,
          text: 'Bienvenido.',
          didOpen: () => {
            Swal.showLoading(null);
          },
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        localStorage.setItem('token', res.token);
        this.app.userIsLogged();
        this.router.navigate(['']);
      } else {
        if (res.code == '401') {
          Swal.fire({
            icon: 'error',
            title: 'Ingreso no permitido.',
            text: 'Usuario o contraseña incorrectas.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error en el ingreso.',
            text: 'Por favor intentarlo más tarde.',
          });
        }
      }
    });
  }
}
