import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from '../misc/modal';
import {TypeWork} from './type-work'
import {TypeWorkService} from './type-work.service'

@Component({
  selector: 'app-type-work',
  templateUrl: './type-work.component.html',
  styleUrls: ['./type-work.component.css']
})
export class TypeWorkComponent extends Modal implements OnInit {
  
  agregadoNew: boolean = true
  agregadoEdit: boolean = true
  exitoNew: boolean = false
  exitoEdit: boolean = false
  typesWorks: TypeWork[] = []
  edit : boolean = false
  editSeleccionado : TypeWork
  constructor(
    private _TypeWorkService: TypeWorkService, private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
   }

  ngOnInit() {
    this.getTypesWork();
  }

  editTypeWork(type: TypeWork)
  {
    this.edit = true;
    this.editSeleccionado = type;
    this.createError(3);
    this.editError(1);
  }

  getTypesWork(){
    this._TypeWorkService.getTypesWork()
    .subscribe((typesWorks : any) => this.typesWorks = typesWorks)
  }

  create(type: TypeWork){
    this.createError(1);
    if(type.DESCRIPTION == "" 
      || type.NAME_TYPE_WORK == ""
      || type.PRICE <=0)
      {
        this.createError(2);
        return;
      }

    for(var i = 0 ; i<this.typesWorks.length;i++)
    {
      if(this.typesWorks[i].NAME_TYPE_WORK == type.NAME_TYPE_WORK)
      {
        this.createError(2);
        return;
      }
    }
    
    if(this.exitoNew)
    {
      this._TypeWorkService.create(type)
      .subscribe(status => this.getTypesWork())
      this.createError(1);
    }
  }

  destroy(type: TypeWork){
    this._TypeWorkService.destroy(type)
    .subscribe(status => this.getTypesWork())
  }

  update(type: TypeWork){
    this.editError(1);
    if(type.ID_TYPE_WORK != -1)
    {
      if(type.DESCRIPTION == "" 
      || type.NAME_TYPE_WORK == ""
      || type.PRICE <=0)
      {
        this.editError(2);
        return;
      }

      for(var i = 0; i<this.typesWorks.length;i++)
      {
        if(this.typesWorks[i].NAME_TYPE_WORK == type.NAME_TYPE_WORK 
          && this.typesWorks[i].ID_TYPE_WORK != type.ID_TYPE_WORK)
        {
          this.editError(2);
          return;
        }
      }
 
      if(this.exitoEdit)
      {
        this._TypeWorkService.update(type)
        .subscribe(status => this.getTypesWork())
      }
    }
    this.edit = false;
    this.editError(3)
  }

  createError(valido : number)
  {
    //Agregado con exito
    if(valido == 1)
    {
      this.agregadoNew = true;
      this.exitoNew = true;
    }
    else{
    //Error de ingreso
      if(valido == 2)
      {
        this.agregadoNew = false;
        this.exitoNew = false;
      }
      //Estado inicial o neutro
      else{
        this.agregadoNew = true;
        this.exitoNew = false;
      }
    }
  }

  editError(valido: number)
  {
     //Agregado con exito
     if(valido == 1)
     {
       this.agregadoEdit = true;
       this.exitoEdit = true;
     }
     else{
     //Error de ingreso
       if(valido == 2)
       {
         this.agregadoEdit = false;
         this.exitoEdit = false;
       }
       //Estado inicial o neutro
       else{
         this.agregadoEdit = true;
         this.exitoEdit = false;
       }
     }
  }
}
