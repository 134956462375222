import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Item } from 'src/app/item/item';
import { ItemService } from 'src/app/item/item.service';
import { Provider } from 'src/app/provider/provider';
import { ProviderService } from 'src/app/provider/provider.service';
import Swal from 'sweetalert2';
import { Inventory } from '../inventory';

import { InventoryService } from '../inventory.service';
import { DatePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';


@Component({
  selector: 'app-new-inventory',
  templateUrl: './new-inventory.component.html',
  styleUrls: ['./new-inventory.component.css']
})
export class NewInventoryComponent implements OnInit {
  @Output() updateInventory= new EventEmitter();
  maxDate : string
  items: Item[] = [];
  providers: Provider[] = [];
  inventory = new Inventory;
  form = new UntypedFormGroup({
    item: new UntypedFormControl([],Validators.required),
    provider: new UntypedFormControl([],Validators.required),
    amount : new UntypedFormControl('', [Validators.required,
      Validators.pattern('[1-9][0-9]*')]),
    priceByUnitProvider: new UntypedFormControl('',Validators.required),
    totalPriceProvider: new UntypedFormControl('',Validators.required),
    priceByUnitStock: new UntypedFormControl('',Validators.required),
    totalPriceStock: new UntypedFormControl('',Validators.required),
    profit: new UntypedFormControl('',Validators.required),
    totalProfit: new UntypedFormControl('',Validators.required),
    comments: new UntypedFormControl(''),
    dateIn: new UntypedFormControl('',   Validators.required)
  })
  isDesktop : boolean = false;
  newInventory = new Inventory()
  public itemFilterControl: FormControl = new FormControl();
  public filteredItems: ReplaySubject<Item[]> = new ReplaySubject(1);

    public providerFilterControl: FormControl = new FormControl();
  public filteredProviders: ReplaySubject<Provider[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  constructor(
    private _adapter: DateAdapter<any>,
    private _itemsService : ItemService,
    private _providerService : ProviderService,
    private _inventaryService : InventoryService,
    private datePipe: DatePipe,
    private deviceService: DeviceDetectorService,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) {
    this._locale = 'es';
    this._adapter.setLocale(this._locale);
   }
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  ngOnInit() {
    this.getItems()
    this.getProvideers()
    this.getDate()  
    this.markFormGroupTouched(this.form)
    this.getInfoDevice()
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.filteredItems
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
          this.singleSelect.compareWith = (a: Item, b: Item) => a && b && a.ID_ITEM === b.ID_ITEM;
      });
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getDate(){
    var date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.form.controls.dateIn.setValue(date)
    this.maxDate = date
  }

  ngOnDestroy() {
    //this._onDestroy.next();
    this._onDestroy.complete();
  }

  getItems()
  {
    this._itemsService.getItems()
    .subscribe((x:any) => {this.items = x
    this.filteredItems.next(this.items.slice());
    //Apply changes subscripted to the filter
    this.itemFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItemsComboBox();
      });
    })
  }

  protected filterItemsComboBox() {
    if (!this.items) {
      return;
    }

    let search = this.itemFilterControl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredItems.next(
      this.items.filter(item => item.NAME_ITEM.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterProvidersComboBox() {
    if (!this.providers) {
      return;
    }

    let search = this.providerFilterControl.value;
    if (!search) {
      this.filteredProviders.next(this.providers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredProviders.next(
      this.providers.filter(provider => provider.NAME_PROVIDER.toLowerCase().indexOf(search) > -1)
    );
  }
  getProvideers()
  {
    this._providerService.getProviders()
    .subscribe((x:any) => {this.providers = x
    this.filteredProviders.next(this.providers.slice());
    //Apply changes subscripted to the filter
    this.providerFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProvidersComboBox();
      });
    })
  }

  calculate(){
    var amount = this.form.get("amount").value
    var priceByUnitProvider = this.form.get("priceByUnitProvider").value
    var totalProvider = amount * priceByUnitProvider

    

    var amount = this.form.get("amount").value
    var priceByUnitProvider = this.form.get("priceByUnitProvider").value
    var totalProvider = amount * priceByUnitProvider

    this.form.controls.totalPriceProvider.setValue(totalProvider)
    var item = this.form.get("item").value
    if(item != undefined){
      this.form.controls.priceByUnitStock.setValue(item.PRICE)
      var totalPrice = item.PRICE * amount;
      this.form.controls.totalPriceStock.setValue(totalPrice)
      this.form.controls.profit.setValue(item.PRICE - priceByUnitProvider)
      this.form.controls.totalProfit.setValue(totalPrice - totalProvider)
    }
  }

  create(){
    Swal.fire({
      icon: 'info',
      title: "Generando el registro de inventario...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var itemSelected : Item = this.form.get("item").value
    var providerSelected : Provider = this.form.get("provider").value
    this.newInventory.ID_ITEM = itemSelected.ID_ITEM
    this.newInventory.ID_PROVIDER = providerSelected.ID_PROVIDER
    this.newInventory.AMOUNT = this.form.get("amount").value
    this.newInventory.PRICE_BY_UNIT_PROVIDER = this.form.get("priceByUnitProvider").value
    this.newInventory.PRICE_TOTAL_PROVIDER = this.form.get("totalPriceProvider").value
    this.newInventory.PRICE_BY_UNIT_STOCK = this.form.get("priceByUnitStock").value
    this.newInventory.PRICE_TOTAL_STOCK = this.form.get("totalPriceStock").value
    this.newInventory.PROFIT = this.form.get("profit").value
    this.newInventory.TOTAL_PROFIT = this.form.get("totalProfit").value
    this.newInventory.DATE_IN = this.form.get("dateIn").value
    this.newInventory.COMMENTS = this.form.get("comments").value

    var self = this;
    this._inventaryService.create(this.newInventory)
    .subscribe((value:any) => {
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la creación del inventario',
        })
        self.ngOnInit();
      }else{
        self.popUpCreate()
        self.ngOnInit();
        self.form.reset()
        
        self.form.controls.comments.setValue('');
        self.getDate()
        self.newInventory = new Inventory
        self.updateInventory.emit()
      }
    });
  }

  popUpCreate(){
    Swal.fire({
      icon: 'success',
      title: 'Inventario agregado realizada',
      text: 'El inventario fué procesado con éxito'
    })
  }
}
