import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from '../misc/modal';
import {Client} from './client'
import {ClientService} from './client.service'


@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent extends Modal implements OnInit {

  agregadoNew: boolean = true
  agregadoEdit: boolean = true
  exitoNew: boolean = false
  exitoEdit: boolean = false
  clients: Client[] = []
  edit : boolean = false
  editSeleccionado : Client

  constructor(
    private _clientService: ClientService, modalServiceExt: NgbModal) {
      super(modalServiceExt);
    }

  ngOnInit() {
    this.getClients();
  }

  getClients(){
    this._clientService.getClients().subscribe((clients : any) => this.clients = clients)
  }

}