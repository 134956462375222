import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { TypeEmployee } from '../type-employee';

@Component({
  selector: 'app-type-employee-new',
  templateUrl: './type-employee-new.component.html',
  styleUrls: ['./type-employee-new.component.css']
})
export class TypeEmployeeNewComponent implements OnInit {
  @Output() createNewTypeEmployeeEvent = new EventEmitter();
  newTypeEmployee = new TypeEmployee;
  
  constructor() { }

  ngOnInit() {
  }

  create(){
    this.createNewTypeEmployeeEvent.emit(this.newTypeEmployee);
    this.newTypeEmployee = new TypeEmployee;
  }

}
