import { Component, OnInit } from '@angular/core';

import { TypeEmployeeService } from './type-employee.service';
import { TypeEmployee } from './type-employee';


@Component({
  selector: 'app-type-employee',
  templateUrl: './type-employee.component.html',
  styleUrls: ['./type-employee.component.css']
})
export class TypeEmployeeComponent implements OnInit {

  typeEmployees: TypeEmployee[] = []
  edit : boolean = false
  editSeleccionado : TypeEmployee
  constructor(
    private _typeEmployeeService: TypeEmployeeService
  ) { }

  ngOnInit() {
    this.getTypeEmployees();
  }
  editTypeEmployee(typeEmployee: TypeEmployee)
  {
    this.edit = true;
    this.editSeleccionado = typeEmployee;
  }

  getTypeEmployees(){
    this._typeEmployeeService.getTypeEmployees()
    .subscribe((typeEmployees : any) => this.typeEmployees = typeEmployees)
  }

  create(typeEmployee: TypeEmployee){
    this._typeEmployeeService.create(typeEmployee)
    .subscribe(status => this.getTypeEmployees())
  }

  destroy(typeEmployee: TypeEmployee){
    this._typeEmployeeService.destroy(typeEmployee)
    .subscribe(status => this.getTypeEmployees())
  }

  update(typeEmployee: TypeEmployee){
    if(typeEmployee.ID_TYPE_EMPLOYEE != -1)
    {
      this._typeEmployeeService.update(typeEmployee)
      .subscribe(status => this.getTypeEmployees())
    }
    this.edit = false;
  }

}
