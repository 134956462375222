import { Component, OnInit } from '@angular/core';

import { TYPESTATEREQUESTService} from './type-state-request.service'
import { TYPESTATEREQUEST } from './type-state-request'


@Component({
  selector: 'app-type-state-request',
  templateUrl: './type-state-request.component.html',
  styleUrls: ['./type-state-request.component.css']
})
export class TYPESTATEREQUESTComponent implements OnInit {

  typeStateRequest: TYPESTATEREQUEST[] = []

  edit : boolean = false
  editSeleccionado : TYPESTATEREQUEST

  constructor(
    private _typeStateRequestService: TYPESTATEREQUESTService
  ) { }

  
  getTypeStateRequests(){
    this._typeStateRequestService.getTypeStateRequests()
    .subscribe((typeStateRequest:any) => this.typeStateRequest = typeStateRequest)
  }

  ngOnInit() {
   this. getTypeStateRequests();
  }

  editTypeStateRequest(TYPESTATEREQUEST: TYPESTATEREQUEST)
  {
    this.edit = true;
    this.editSeleccionado = TYPESTATEREQUEST;
  }

  create(typestate: TYPESTATEREQUEST)
  {
    this._typeStateRequestService.create(typestate)
    .subscribe(status => this.getTypeStateRequests())
  }
  destroy(typestate: TYPESTATEREQUEST){
    this._typeStateRequestService.destroy(typestate)
    .subscribe(status => this.getTypeStateRequests())
  }
  update(typestate: TYPESTATEREQUEST){
    if(typestate.ID_TYPE_STATE_REQUEST != -1)
    {
      this._typeStateRequestService.update(typestate)
      .subscribe(status => this.getTypeStateRequests())
    }
  }

}
