import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Inventory } from '../inventory';
import { NumberFormatPipe } from 'src/app/number-format.pipe';

@Component({
  selector: 'app-inventory-info',
  templateUrl: './inventory-info.component.html',
  styleUrls: ['./inventory-info.component.css'],
})
export class InventoryInfoComponent implements OnInit {
  @Input() inventory: Inventory;
  constructor(private datePipe: DatePipe, private numberFormatPipe: NumberFormatPipe) {}
  form = new UntypedFormGroup({
    DATE_IN: new UntypedFormControl('', Validators.required),
    ID_INVENTORY_REGISTER: new UntypedFormControl('', Validators.required),
    ID_ITEM: new UntypedFormControl('', Validators.required),
    ITEM_NAME: new UntypedFormControl(''),
    ID_PROVIDER: new UntypedFormControl('', [Validators.required]),
    PROVIDER_NAME: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    AMOUNT: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    PRICE_BY_UNIT_PROVIDER: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    PRICE_TOTAL_PROVIDER: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    PRICE_BY_UNIT_STOCK: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    PRICE_TOTAL_STOCK: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    PROFIT: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    TOTAL_PROFIT: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    COMMENTS: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
  });

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    var date = this.datePipe.transform(new Date(this.inventory.DATE_IN), 'yyyy-MM-dd');
    this.form.controls.DATE_IN.setValue(date);
    this.form.controls.ID_INVENTORY_REGISTER.setValue(this.inventory.ID_INVENTORY);
    this.form.controls.ID_ITEM.setValue(this.inventory.ID_ITEM);
    this.form.controls.ITEM_NAME.setValue(this.inventory.NAME_ITEM);
    this.form.controls.ID_PROVIDER.setValue(this.inventory.ID_PROVIDER);
    this.form.controls.PROVIDER_NAME.setValue(this.inventory.NAME_PROVIDER);
    this.form.controls.AMOUNT.setValue(this.numberFormatPipe.transform(this.inventory.AMOUNT));
    this.form.controls.PRICE_BY_UNIT_PROVIDER.setValue(
      this.numberFormatPipe.transform(this.inventory.PRICE_BY_UNIT_PROVIDER)
    );
    this.form.controls.PRICE_TOTAL_PROVIDER.setValue(
      this.numberFormatPipe.transform(this.inventory.PRICE_TOTAL_PROVIDER)
    );
    this.form.controls.PRICE_BY_UNIT_STOCK.setValue(
      this.numberFormatPipe.transform(this.inventory.PRICE_BY_UNIT_STOCK)
    );
    this.form.controls.PRICE_TOTAL_STOCK.setValue(this.numberFormatPipe.transform(this.inventory.PRICE_TOTAL_STOCK));
    this.form.controls.PROFIT.setValue(this.numberFormatPipe.transform(this.inventory.PROFIT));
    this.form.controls.TOTAL_PROFIT.setValue(this.numberFormatPipe.transform(this.inventory.TOTAL_PROFIT));
    this.form.controls.COMMENTS.setValue(this.inventory.COMMENTS);
  }
}
