import { Component, OnInit } from '@angular/core';

import { TypeItemService } from './type-item.service';
import { TypeItem } from './type-item';
import { Modal } from '../misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-type-item',
  templateUrl: './type-item.component.html',
  styleUrls: ['./type-item.component.css']
})
export class TypeItemComponent extends Modal implements OnInit {

  typeItems: TypeItem[] = []

  constructor(
    private _typeItemService: TypeItemService, private modalServiceExt: NgbModal
    ) {super(modalServiceExt) }

  ngOnInit() {
    this.getTypeItems();
  }

  getTypeItems(){
    this._typeItemService.getTypeItems()
    .subscribe((typeItems : any) => this.typeItems = typeItems)
  }

}
