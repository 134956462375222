import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import Swal from 'sweetalert2';
import { TypeEmployee } from './type-employee'

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeEmployeeService {

  constructor(
    private _http:HttpClient
  ) { }

  create(typeEmployee: TypeEmployee){
    return this._http.post('/type_employees',typeEmployee)
  }

  destroy(typeEmployee: TypeEmployee){
    return this._http.delete('/type_employees/' + typeEmployee.ID_TYPE_EMPLOYEE)
  }

  update(typeEmployee: TypeEmployee){
    return this._http.put('/type_employees' ,typeEmployee)
  }

  getTypeEmployees(){
    return this._http.get('/type_employees')
  }

  getTypeEmployee(typeEmployee: TypeEmployee){
    return this._http.get('/type_employees/' + typeEmployee.ID_TYPE_EMPLOYEE)
  }
}
