import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-inicio',
  templateUrl: './navbar-inicio.component.html',
  styleUrls: ['./navbar-inicio.component.css']
})
export class NavbarInicioComponent implements OnInit {
  navbar : boolean = true;
  constructor() { }
  ngOnInit() {
  }

  decollapse()
  {
    this.navbar = false;
    this.activar(300)
    .then( x =>
      this.collapse());
  }
  collapse()
  {
    this.navbar = true;
  }
  async activar(ms: number){
    return new Promise( resolve => setTimeout(resolve, ms));
  }
}