import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import {LineItem} from '../line-item'
import {LineItemService} from '../line-item.service'
import {Item} from '../../item/item'
import {ItemService} from '../../item/item.service'
import {Request} from '../../request/request'

@Component({
  selector: 'app-line-item-new',
  templateUrl: './line-item-new.component.html',
  styleUrls: ['./line-item-new.component.css']
})
export class LineItemNewComponent implements OnInit {

  @Input() request:Request;
  @Output() createNewLineItem = new EventEmitter();
  newLine = new LineItem;
  items : Item[] = []

  constructor(
    private _lineItemService: LineItemService,
    private _itemService: ItemService
  ) { }

  ngOnInit() {
    this.getItems();
    this.newLine.ID_REQUEST = this.request.ID_REQUEST;
  }

  create()
  {
    this.createNewLineItem.emit(this.newLine)
    this.newLine = new LineItem;
    this.newLine.ID_REQUEST = this.request.ID_REQUEST;
  }
  getItems(){
    this._itemService.getItems()
    .subscribe((items : any) => this.items = items)
  }

}
