import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

import decode from 'jwt-decode'
import { Employee } from '../employee/employee';
@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private authService : AuthService,
    public router : Router
  ){}

  canActivate(route : ActivatedRouteSnapshot):boolean
  {

    const expectedRole = route.data.expectedRole;
    
    if(!this.authService.isAuth()){
      this.router.navigate(['login']);
      return false;
    }else{
      const token = localStorage.getItem('token')
      const user : Employee = decode(token);
      if(user.ID_TYPE_EMPLOYEE != expectedRole){
        if(user.ID_TYPE_EMPLOYEE == 2){
          this.router.navigate(['/employee']);
          return false;
        }else{
          this.router.navigate(['login']);
          return false;
        }
      }
    } 
    return true;
  }
}
