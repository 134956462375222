import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EmployeeService } from 'src/app/employee/employee.service';
import { Client } from 'src/app/payment/client';
import { Report } from 'src/app/report/report';
import { ReportService } from 'src/app/report/report.service';
import Swal from 'sweetalert2';
import { PaymentService } from '../payment.service';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-uncollectible',
  templateUrl: './payment-uncollectible.component.html',
  styleUrls: ['./payment-uncollectible.component.css'],
})
export class PaymentUncollectibleComponent extends Modal implements OnInit {
  @Input() client: Client;
  @Output() refreshClientEvent = new EventEmitter();
  changes: boolean;

  reportsUncollectable: Report[] = [];

  displayedColumns: string[] = [
    'ID_REQUEST',
    'NAME_EMPLOYEE',
    'DATE_REQUEST',
    'TOTAL_PRICE',
    'NAME_TYPE_REQUEST',
    'DATE_DELIVERY',
    'OPCIONES',
  ];
  dataSource = new MatTableDataSource<Report>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private _paymentService: PaymentService,
    private _reportService: ReportService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  ngOnInit() {
    this.getUncollectibleByClient(this.client);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getUncollectibleByClient(this.client);
  }

  onChangeCollectible(change: boolean) {
    this.closeModal();
    this.changes = change;
    this.reload();
  }

  updateClients(changes: boolean) {
    this.changes = changes;
  }

  reload() {
    if (this.changes) {
      this.refreshClientEvent.emit(this.client);
      this.changes = false;
    }
  }

  entregarFactura(report: Report) {
    Swal.fire({
      icon: 'info',
      title: 'Entregando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    var self = this;
    this._paymentService.setRequestStatus(report).subscribe((value: any) => {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la entrega de la orden',
          footer: '<a> Problemas con la actualización del producto.</a>',
        });
      } else {
        self.popUpEntregar();
        self.getUncollectibleByClient(self.client);
      }
    });
  }

  popUpEntregar() {
    Swal.fire({
      icon: 'success',
      title: 'Orden entregada',
      text: 'La orden de trabajo fue entrega con éxito',
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getUncollectibleByClient(client: Client) {
    var self = this;
    var clientRequest: Client = new Client();
    Object.assign(clientRequest, client);
    clientRequest.ID_TYPE_COLLECTABLE = 2;
    clientRequest.ID_TYPE_STATE_READY = 2;
    this._paymentService.getClientHistory(clientRequest).subscribe((value: any) => {
      self.reportsUncollectable = value;
      self.reportsUncollectable.forEach(element => {
        var reportSend = new Report();
        reportSend.ID_REQUESTP = element.ID_REQUEST;
        reportSend.COMMENTS = element.COMMENTS;
        self._paymentService.getRequestLogHistory(reportSend).subscribe(xv => (element.logs = xv));
        self._reportService.getReport7(reportSend).subscribe(xv => (element.lines = xv));
      });
      self.dataSource = new MatTableDataSource(self.reportsUncollectable);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
      self.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'DATE_REQUEST':
            var date = new Date(item.DATE_REQUEST);
            return date;
          case 'DATE_DELIVERY':
            var date = new Date(item.DATE_DELIVERY);
            return date;

          default:
            return item[property];
        }
      };
    });
  }
}
