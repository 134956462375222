import { Component, OnInit } from '@angular/core';

import { EmployeeService } from './employee.service';
import { Employee } from './employee';
import { Modal } from '../misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent extends Modal implements OnInit {

  employees: Employee[] = []
  edit : boolean = false
  editSeleccionado : Employee

  constructor(
    private _employeeService: EmployeeService, private modalServiceExt: NgbModal
    ) {super(modalServiceExt
  ) }

  ngOnInit() {
    this.getEmployees();
  }

  getEmployees(){
    this._employeeService.getEmployees()
    .subscribe((employees : any) => this.employees = employees)
  }

}
