import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CedulasService {

  constructor(private http:HttpClient) { }

  getNombreClientes(cedula : number) {
    var baseURL = environment.crCedulaURL+'/'+cedula;
    return this.http.get(baseURL,{headers:{skip:"true"}});
  }
}
