import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { Client } from 'src/app/client/client';
import { ClientService } from 'src/app/client/client.service';
import { TypeTreasury } from 'src/app/type-treasury/type-treasury';
import { TypeTreasuryService } from 'src/app/type-treasury/type-treasury.service';

@Component({
  selector: 'app-header-request',
  templateUrl: './header-request.component.html',
  styleUrls: ['./header-request.component.css']
})
export class HeaderRequestComponent implements OnInit {

  @Input() factureForm: UntypedFormGroup;
  @Output() updateClient = new EventEmitter();
  @Output() updateTreasury = new EventEmitter();
  clientSelected: Client;
  renderLastNames: boolean;
  
  typeTreasury: TypeTreasury[] = [];
  Hacienda: boolean;
  ngOnInit(): void {
    this.getClients();
    this.getTypeTreasury();
  }

  clients: Client[] = [];
  public clientFilterControl: FormControl = new FormControl();
  public filteredClient: ReplaySubject<Client[]> = new ReplaySubject(1);
  public typeTreasuryFilterControl: FormControl = new FormControl();
  public filteredTypeTreasury: ReplaySubject<TypeTreasury[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  constructor(
      private _clientService: ClientService,
      private _typeTreasuryService: TypeTreasuryService,
      private deviceService: DeviceDetectorService,
  ){

  }

  getTypeTreasury() {
    this._typeTreasuryService.getTypesTreasure().subscribe((x: any) => {
      this.typeTreasury = x
      this.filteredTypeTreasury.next(this.typeTreasury.slice());

          // Apply changes subscribed to the filter
          this.typeTreasuryFilterControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterTypeTreasuryComboBox();
            });
    });
  }

  protected filterTypeTreasuryComboBox() {
    if (!this.typeTreasury) {
      return;
    }
  
    let search = this.typeTreasuryFilterControl.value;
    if (!search) {
      this.filteredTypeTreasury.next(this.typeTreasury.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
  
    this.filteredTypeTreasury.next(
      this.typeTreasury.filter(typeTreasury => typeTreasury.NAME_TYPE.toLowerCase().indexOf(search) > -1)
    );
  }

  onClientChange() {
    var idClient = this.factureForm.value.idClient;
    this.clientSelected = idClient;
    this.updateClient.emit(this.clientSelected);
    if (this.clientSelected == undefined) {
      this.factureForm.controls.nameClient.setValue('');
      this.factureForm.controls.lastName.setValue('');
      this.factureForm.controls.last_2_Name.setValue('');
      this.factureForm.controls.taxClient.setValue('');
      this.factureForm.controls.direccionClient.setValue('');
      this.factureForm.controls.emailClient.setValue('');
      this.factureForm.controls.phoneClient.setValue('');
      this.factureForm.controls.regionClient.setValue('');
      this.factureForm.controls.provinciaClient.setValue('');
      this.factureForm.controls.cantonClient.setValue('');
      this.factureForm.controls.distritoClient.setValue('');
    } else {
      this.factureForm.controls.nameClient.setValue(this.clientSelected.NAME_CLIENT)
      this.factureForm.controls.lastName.setValue(this.clientSelected.LAST_NAME);
      this.factureForm.controls.last_2_Name.setValue(this.clientSelected.LAST_2_NAME);
      this.factureForm.controls.taxClient.setValue(this.clientSelected.TAX*100)
      this.factureForm.controls.direccionClient.setValue(this.clientSelected.ADDRESS)
      this.factureForm.controls.emailClient.setValue(this.clientSelected.EMAIL)
      this.factureForm.controls.phoneClient.setValue(this.clientSelected.PHONE_NUMBER)
      this.factureForm.controls.regionClient.setValue(this.clientSelected.NAME_REGION)
      this.factureForm.controls.provinciaClient.setValue(this.clientSelected.PROVINCIA)
      this.factureForm.controls.cantonClient.setValue(this.clientSelected.CANTON)
      this.factureForm.controls.distritoClient.setValue(this.clientSelected.DISTRITO)
      if (this.clientSelected.JURIDICA){
        this.renderLastNames = false
      }
      else{
        this.renderLastNames = true
      }
    }
  }

  changeTypeTreasury() {
    this.updateTreasury.emit();
  }

  getClients()
  {
    this._clientService.getClients()
    .subscribe((x:any) => {this.clients = x
      this.clients.forEach(element => {
      element.NAME_CLIENT_ID = element.NAME_CLIENT + " " + element.LAST_NAME + " " + element.LAST_2_NAME + " - "+element.IDN
    });
    this.filteredClient.next(this.clients.slice());

        // Apply changes subscribed to the filter
        this.clientFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterClientComboBox();
          });
    })

    
  }

  protected filterClientComboBox() {
    if (!this.clients) {
      return;
    }
  
    let search = this.clientFilterControl.value;
    if (!search) {
      this.filteredClient.next(this.clients.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
  
    this.filteredClient.next(
      this.clients.filter(client => client.NAME_CLIENT_ID.toLowerCase().indexOf(search) > -1)
    );
  }

}
