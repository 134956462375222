import { Component, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Client } from '../client';
import { formatDate } from '@angular/common';
import { Console } from 'console';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-payment-view',
  templateUrl: './payment-view.component.html',
  styleUrls: ['./payment-view.component.css'],
})
export class PaymentViewComponent implements OnChanges {
  @Input() client: Client;
  constructor(private elRef: ElementRef) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.setInformation();
  }

  form = new UntypedFormGroup({
    cedula: new UntypedFormControl(''),
    name_client: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    total_debt: new UntypedFormControl(''),
    days: new UntypedFormControl(''),
    provincia: new UntypedFormControl(''),
    canton: new UntypedFormControl(''),
    distrito: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
  });

  setInformation() {
    this.form.controls.name_client.setValue(
      this.client.NAME_CLIENT + ' ' + this.client.LAST_NAME + ' ' + this.client.LAST_2_NAME
    );
    this.form.controls.cedula.setValue(this.client.IDN);
    this.form.controls.phone.setValue(this.client.PHONE_NUMBER);
    this.form.controls.total_debt.setValue(this.client.TOTAL_DEBT);
    this.form.controls.days.setValue(this.client.DAYS_OF_INDEBTEDNESS);
    this.form.controls.provincia.setValue(this.client.PROVINCIA);
    this.form.controls.canton.setValue(this.client.CANTON);
    this.form.controls.distrito.setValue(this.client.DISTRITO);
    this.form.controls.address.setValue(this.client.ADDRESS);
    this.form.controls.email.setValue(this.client.EMAIL);
  }

  scroll(scrolled: number) {
    this.elRef.nativeElement.scrollIntoView();
    // now account for fixed header
    var scrolledY = window.scrollY;

    if (scrolledY) {
      window.scroll(0, scrolledY - scrolled);
    }
  }
}
