import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import { AuthService } from '../services/auth.service';

import { Employee } from '../employee/employee';
import decode from 'jwt-decode'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private router : Router,
    private authService : AuthService)
  {}
  canActivate(route : ActivatedRouteSnapshot):boolean
  {

    const expectedRole = route.data.expectedRole;
    
    if(!this.authService.isAuth()){
      this.router.navigate(['login']);
      return false;
    }else{
      const token = localStorage.getItem('token')
      const user : Employee = decode(token);
      var isValid : boolean = false;
      expectedRole.forEach(roleRequested => {
        
        if(user.ID_TYPE_EMPLOYEE == roleRequested){
          isValid = true;
        }
      });
      if(isValid){
        return true;
      }else{
        return false
      }
    } 
  }

}
