import { Component, OnInit, ViewChild, Input, SimpleChanges, Output } from '@angular/core';
import {ItemComponent} from '../item.component';
import {ItemService} from '../item.service';
import {Item} from '../item';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tabla-item',
  templateUrl: './tabla-item.component.html',
  styleUrls: ['./tabla-item.component.css']
})

export class TablaItemComponent extends Modal implements OnInit {
  @Input() items : Item[];
  public itemSeleccionado : Item;
  public editable : Boolean = false;
  displayedColumns: string[] = ['ID_ITEM', 'NAME_ITEM','STOCK','PRICE','OPCIONES'];
  dataSource = new MatTableDataSource<Item>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private itemComponent : ItemComponent,
    private itemService : ItemService,
    private modalServiceExt: NgbModal
    ) {super(modalServiceExt) }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.items);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
}

  editar(item:Item){
    this.itemSeleccionado = item;
    this.editable = true;
    item.ID_ITEM
  }

  

  eliminarItem(item:Item)
  {
    Swal.fire({
      icon: 'info',
      title: "Eliminando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var self = this;
    this.itemService.destroy(item)
    .subscribe(function (value) {
      if (value == 500){
        Swal.fire({ 
          icon: 'error',
          title: 'Oops...',
          text: 'Error al eliminar'
        })
      }
      else{
        if (value == 501){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No es posible eliminar este producto. Ya se encuentra asociado a una orden de trabajo.'
          })
        }else{
          self.popUpDelete()
        }
      }
      
    });
  }
  popUpDelete(){
    Swal.fire({
      icon: 'success',
      title: 'Producto eliminado',
      text: 'El producto fué eliminado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      this.getItems()
    })
  }
  getItems()
  {
    var self = this;
    this.itemService.getItems()
    .subscribe((x : any) =>{
      self.items = x;
      self.dataSource = new MatTableDataSource(self.items);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
    });
  }
}
