import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Report } from 'src/app/report/report';
import { ReportService } from 'src/app/report/report.service';
import { AuthService } from 'src/app/services/auth.service';
import { RequestEdit } from '../request-edit';
import { RequestEditService } from '../request-edit.service';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-request-without-process',
  templateUrl: './request-without-process.component.html',
  styleUrls: ['./request-without-process.component.css'],
})
export class RequestWithoutProcessComponent extends Modal implements OnInit {
  @Input() requestWithoutProcess: RequestEdit[];

  factureForm: UntypedFormGroup;
  displayedColumns: string[] = ['ID_REQUEST', 'NAME_CLIENT', 'NAME_REGION', 'TOTAL_PRICE', 'DATE_REQUEST', 'OPCIONES'];
  dataSource = new MatTableDataSource<RequestEdit>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  showNamesLines: String[] = [];
  bdNamesLines: String[] = [];

  constructor(
    private _reportService: ReportService,
    private requestEditService: RequestEditService,
    private authService: AuthService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  ngOnInit() {
    this.getWithoutProcessing();
    this.bdNamesLines = ['NAME', 'AMOUNT', 'PRICE', 'TOTAL_PRICE'];
    this.showNamesLines = ['Articulo/Trabajo', 'Cantidad', 'Precio unitario', 'Precio subtotal'];
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.requestWithoutProcess);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
    self.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'DATE_REQUEST':
          var date = new Date(item.DATE_REQUEST);
          return date;

        default:
          return item[property];
      }
    };
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }

  getWithoutProcessing() {
    var self = this;
    this.requestEditService.getWithoutProcessing(this.userIsLogged()).subscribe((x: any) => {
      self.requestWithoutProcess = x;
      self.dataSource = new MatTableDataSource(self.requestWithoutProcess);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
      self.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'DATE_REQUEST':
            var date = new Date(item.DATE_REQUEST);
            return date;

          default:
            return item[property];
        }
      };

      self.requestWithoutProcess.forEach(element => {
        var reportSend = new Report();
        reportSend.ID_REQUESTP = element.ID_REQUEST;
        self._reportService.getReport7(reportSend).subscribe(xv => (element.lines = xv));
      });
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
