import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import Swal from 'sweetalert2';
import { Item } from './item'

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(
    private _htpp:HttpClient
  ) { }

  create(item: Item){
    return this._htpp.post('/items',item)
  }

  destroy(item: Item){
    return this._htpp.delete('/items/' + item.ID_ITEM)
  }

  update(item: Item){
    return this._htpp.put('/items' ,item)
  }

  getItems(){
    return this._htpp.get('/items')
  }

  getItem(item: Item){
    return this._htpp.get('/items/' + item.ID_ITEM)
  }
}
