import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import {Report} from './report'

@Injectable({
  providedIn: 'root'
})
export class ReportService {


  constructor(
    private _http:HttpClient
  ) { }

  getReport0(){
    return this._http.get('/report0')
  }

  getReport1(report:Report){
    return this._http.post('/report1', report)
  }

  getReport2(report:Report){
    return this._http.post('/report2', report)
  }

  getReport3(report:Report){
    return this._http.post('/report3', report)
  }

  getReport4(report:Report){
    return this._http.post('/report4', report)
  }

  getReport5(report:Report){
    return this._http.post('/report5', report)
  }

  getReport6(report:Report){
    return this._http.post('/report6', report)
  }

  getReport7(report:Report){
    return this._http.post('/report7', report)
  }

  getReport8(report:Report){
    return this._http.post('/report8', report)
  }

  getReport9(report:Report){
    return this._http.post('/report9', report)
  }

  getUncollectible(report: Report){
    return this._http.post('/uncollectableRequest', report)
  }

  incompletePayments(report:Report){
    return this._http.post('/incompletePayments', report)
  }

  requestCreatedByEmployee(report:Report){
    return this._http.post('/requestCreatedByEmployee', report)
  }
  requestCreatedDates(report:Report){
    return this._http.post('/requestCreatedDates', report)
  }

  paymentsByEmployee(report:Report){
    return this._http.post('/paymentsByEmployee', report)
  }

  resumeEmployees(report:Report){
    return this._http.post('/resumeClient', report)
  }

  getPaymentByType(report:Report){
    return this._http.post('/paymentByType', report)
  }

  getReport10(report:Report){
    return this._http.post('/report10', report)
  }

  reporteRutas(report:Report){
    return this._http.post('/reporteRutas', report)
  }

}
