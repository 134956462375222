import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor {

  intercept( req, next){
    if (req.headers.get("skip")){
      const request = req.clone({
        headers: req.headers.delete("skip")
      })
      return next.handle(request);
    }
  
    const token = localStorage.getItem('token')
    const tokenHeader = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    })
    return next.handle(tokenHeader)
  }

  constructor() { }
}
