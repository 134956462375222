export class TypeItem {
    constructor(
        public ID_TYPE_ITEM: number = 0,
        public NAME_TYPE_ITEM: string = "",
        public ID_TYPE_AVAILABLE : number = 0,
        public disabled = false,
        public editable: boolean = false
    ){

    }
}