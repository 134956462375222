export class LineItem {
    constructor(
        public ID_ITEM: number = 0,
        public ID_LINE_ITEM: number = 0,
        public ID_REQUEST: number = 0,
        public AMOUNT: number = 0,
        public PRICE_BY_UNIT: number = 0,
        public DELETED : boolean = false
    ){}
}
