import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeItem } from '../type-item';

@Component({
  selector: 'app-type-item-list',
  templateUrl: './type-item-list.component.html',
  styleUrls: ['./type-item-list.component.css']
})
export class TypeItemListComponent implements OnInit {
  @Input() typeItems: TypeItem[];
  @Output() destroyTypeItemEvent = new EventEmitter();
  @Output() updateTypeItemEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  destroy(typeItem:TypeItem){
    const response = confirm('delete?')
    if(response){
      this.destroyTypeItemEvent.emit(typeItem);
    }
  }

  update(typeItem:TypeItem){
    this.updateTypeItemEvent.emit(typeItem);
  }
}
