import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {SesionComponent} from './sesion/sesion.component'
import {AdminComponent} from './admin/admin.component'
import {RegionComponent} from './region/region.component'
import {TypeExpenseComponent} from './type-expense/type-expense.component'
import {TypeItemComponent} from './type-item/type-item.component'
import {TypeWorkComponent} from './type-work/type-work.component'
import {ClientComponent} from './client/client.component'
import {ClientHistoryComponent} from './client-history/client-history.component'
import {EmployeeComponent} from './employee/employee.component'
import {ItemComponent} from './item/item.component'
import {ReportComponent } from './report/report.component';
import {RequestComponent} from './request/request.component';
import {EmployeeInicioComponent} from './employee-inicio/employee-inicio.component'
import {PaymentComponent } from './payment/payment.component';
import {ExpenseComponent} from './expense/expense.component';
import {ReporteIndividualComponent} from './reporte-individual/reporte-individual.component'
import {RequestEditComponent} from './request-edit/request-edit.component'
import {ProviderComponent} from './provider/provider.component'
import {InventoryComponent} from './inventory/inventory.component'
import {RequestDisplayComponent} from './request-display/request-display.component'
import {NewsComponent} from './news/news.component'
import { RoleGuard } from './guards/role.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {path: 'login',component:SesionComponent},
  {path: '', component: AdminComponent, data:{expectedRole: '1' }, canActivate:[RoleGuard]},
  {path: 'verProvider', component: ProviderComponent, data:{expectedRole: ['1'] }, canActivate:[RoleGuard]},
  {path: 'verRegion', component: RegionComponent, data:{expectedRole: ['1'] }, canActivate:[RoleGuard]},
  {path: 'employee', component: EmployeeInicioComponent, data:{expectedRole: ['1','2'] }, canActivate:[AuthGuard]},
  {path: 'verTipoGastos', component: TypeExpenseComponent, data:{expectedRole: ['1'] }, canActivate:[RoleGuard]},
  {path: 'verTipoItem', component: TypeItemComponent, data:{expectedRole: ['1'] }, canActivate:[RoleGuard]},
  {path: 'verTipoTrabajo', component: TypeWorkComponent, data:{expectedRole: ['1'] }, canActivate:[RoleGuard]},
  {path: 'verCliente', component: ClientComponent, data:{expectedRole: ['1','2'] },canActivate:[AuthGuard]},
  {path: 'history', component: ClientHistoryComponent, data:{expectedRole: ['1','2'] },canActivate:[AuthGuard]},
  {path: 'verEmployee', component: EmployeeComponent, data:{expectedRole: ['1'] },canActivate:[RoleGuard]},
  {path: 'verItem', component: ItemComponent, data:{expectedRole: ['1'] }, canActivate:[RoleGuard]},
  {path: 'verInventario', component: InventoryComponent, data:{expectedRole: ['1'] }, canActivate:[RoleGuard]},
  {path: 'verRequest', component: RequestComponent, data:{expectedRole: ['1','2'] }, canActivate:[AuthGuard]},
  {path: 'updateRequest', component: RequestEditComponent, data:{expectedRole: ['1','2'] }, canActivate:[AuthGuard]},
  {path: 'displayRequest', component: RequestDisplayComponent, data:{expectedRole: ['1','2'] }, canActivate:[AuthGuard]},
  {path: 'verReport', component: ReportComponent, data:{expectedRole: ['1','2'] }, canActivate:[AuthGuard]},
  {path: 'verPayment', component: PaymentComponent,data:{expectedRole: ['1','2'] } , canActivate:[AuthGuard]},
  {path: 'verIndividual', component: ReporteIndividualComponent, data:{expectedRole: ['1','2'] }, canActivate:[AuthGuard]},
  {path: 'verExpense', component: ExpenseComponent, data:{expectedRole: ['1','2'] }, canActivate:[AuthGuard]},
  {path: 'verRequestPending', component: RequestEditComponent, data:{expectedRole: ['1','2'] },canActivate:[AuthGuard]},
  {path: 'news', component: NewsComponent, data:{expectedRole: ['1','2'] },canActivate:[AuthGuard]},
  { path: "**", redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [SesionComponent]