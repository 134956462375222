import { Component, Input, OnInit } from '@angular/core';
import { Employee } from '../employee';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TypeEmployee } from 'src/app/type-employee/type-employee';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css'],
})
export class EmployeeDetailsComponent implements OnInit {
  @Input() employee: Employee;
  typesEmployees: TypeEmployee[] = [];
  form = new UntypedFormGroup({
    idEmployee: new UntypedFormControl([], Validators.required),
    tipoEmpleado: new UntypedFormControl([], Validators.required),
    userName: new UntypedFormControl('', Validators.required),
    estado: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.email]),
    name: new UntypedFormControl('', [Validators.required]),
    phone: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'),
    ]),
    direccion: new UntypedFormControl('', Validators.required),
  });
  constructor() {}

  ngOnInit() {
    this.setForm();
  }
  setForm() {
    console.log(this.employee);
    this.form.controls.tipoEmpleado.setValue(this.employee.NAME_TYPE_EMPLOYEE);
    this.form.controls.idEmployee.setValue(this.employee.ID_EMPLOYEE);
    this.form.controls.userName.setValue(this.employee.USERNAME);
    this.form.controls.name.setValue(this.employee.NAME_EMPLOYEE);
    this.form.controls.email.setValue(this.employee.EMAIL);
    this.form.controls.phone.setValue(this.employee.PHONE);
    this.form.controls.direccion.setValue(this.employee.ADDRESS);
    this.form.controls.estado.setValue(this.employee.TYPE_AVAILABLE_DESC);
  }
}
