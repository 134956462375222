import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Item } from '../item';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css']
})
export class ItemListComponent implements OnInit {
  @Input() items: Item[];
  @Output() updateItemEvent = new EventEmitter();
  @Output() destroyItemEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  destroy(item:Item){
    const response = confirm('delete?')
    if(response){
      this.destroyItemEvent.emit(item);
    }
  }
  update(item:Item){
    this.updateItemEvent.emit(item);
  }
}

