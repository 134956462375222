import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {TypeWork} from '../type-work'

@Component({
  selector: 'app-type-work-list',
  templateUrl: './type-work-list.component.html',
  styleUrls: ['./type-work-list.component.css']
})
export class TypeWorkListComponent implements OnInit {

  @Input() typesWorks: TypeWork[];
  @Output() destroyTypeWorkEvent = new EventEmitter();
  @Output() updateTypeWorkEvent = new EventEmitter();
  constructor() { }
  ngOnInit() {
  }

  destroy(typeWork:TypeWork){
    const response = confirm('delete?')
    if(response){
      this.destroyTypeWorkEvent.emit(typeWork);
    }
  }

  update(typeWork:TypeWork){
    this.updateTypeWorkEvent.emit(typeWork);
  }
}
