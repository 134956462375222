import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-costa-rica',
  templateUrl: './costa-rica.component.html',
  styleUrls: ['./costa-rica.component.css']
})
export class CostaRicaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
