export class ReporteIndividual {
  constructor(
    public ID_EMPLOYEE: number = -1,
    public NAME_EMPLOYEE: string = '',
    public USERNAME: string = '',
    public AMOUNT_DEPOSIT: number = -1,
    public JURIDICA: number = -1,
    public EXPENSE: number = -1,
    public FECHA_INICIAL: string = '',
    public FECHA_FINAL: string = '',
    public ID_REQUEST: string = '',
    public DATE_PAY: string = '',
    public NAME_CLIENT: string = '',
    public LAST_NAME: string = '',
    public LAST_2_NAME: string = ''
  ) {}
}
