export class RequestEdit {
  lines: Object;
  constructor(
    public ID_REQUEST: number = 0,
    public ID_CLIENT: number = 0,
    public NAME_CLIENT: string = "",
    public ID_REGION: number = 0,
    public NAME_REGION: string = "",
    public LAST_NAME: string = "",
    public LAST_2_NAME: string = "",
    public ID_EMPLOYEES: number = 0,
    public DATE_REQUEST: string = "",
    public TOTAL_PRICE : number = 0,
    public ID_TYPE_STATE_REQUEST: number = 0,
    public ID_TYPE_TREASURY: number = 0,
    public ID_TYPE_PAYMENT: number = 0,
    public ID_REQUEST_STATUS: number = 0,
    public ID_TYPE_PROCESSING: number = 0,
    public ID_TYPE_STATE_READY: number = 0,
    public DATE_DELIVERY: string = "",
    public COMMENTS: string = "",
    public DETAILS: boolean = false
){

}
}
