import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";

import { TypeItem } from './type-item'
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeItemService {

  constructor(
    private _http:HttpClient
  ) { }

  create(typeItem: TypeItem){
    return this._http.post('/type_items',typeItem)
  }

  destroy(typeItem: TypeItem){
    return this._http.delete('/type_items/' + typeItem.ID_TYPE_ITEM)
  }

  update(typeItem: TypeItem){
    return this._http.put('/type_items' ,typeItem)
  }

  getTypeItems(){
    return this._http.get('/type_items')
  }

  getTypeItem(typeItem: TypeItem){
    return this._http.get('/type_items/' + typeItem.ID_TYPE_ITEM)
  }
}
