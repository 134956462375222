import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from '../app.component';
import { Route, Router } from '@angular/router';
import { Employee } from '../employee/employee';
import { EmployeeService } from '../employee/employee.service';
import { AuthGuard } from '../guards/auth.guard';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.css'],
})
export class NavbarAdminComponent implements OnInit {
  @ViewChild('navbarToggler', { static: false }) navbarToggler: ElementRef;

  navbar: boolean = false;
  constructor(private router: Router, private auth: AuthGuard, private authService: AuthService) {}
  display = 'none';
  usuario: Employee;
  @Output() logginOutEvent = new EventEmitter();
  ngOnInit() {
    this.userIsLogged();
  }

  userIsLogged() {
    const employee: Employee = this.authService.getUserLogged();
    if (employee != null) {
      this.usuario = employee;
    } else {
      this.usuario = null;
    }
  }

  logOut() {
    this.logginOutEvent.emit(3);
    this.router.navigate(['login']);
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapse() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }
}
