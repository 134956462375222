export class Client {
    constructor(
        public ID_CLIENT: number = 0,
        public ID_REGION: number = 0,
        public IDN: string = "",
        public NAME_CLIENT: string = "",
        public LAST_NAME: string = "",
        public LAST_2_NAME: string = "",
        public JURIDICA: boolean = false,
        public NAME_CLIENT_ID: string = "",
        public ADDRESS: string = "",
        public EMAIL: string = "",
        public PHONE_NUMBER : string = "",
        public NAME_REGION: string = "",
        public ID_PROVINCIA: number = 0,
        public PROVINCIA: string = "",
        public ID_CANTON: number = 0,
        public CANTON: string = "",
        public ID_DISTRITO: number = 0,
        public DISTRITO: string = "",
        public TAX: number = 0,
        public editable: boolean = false,
        public details: boolean = false
    ){}
}
