import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import Swal from 'sweetalert2';
import {ReporteIndividual} from './reporte-individual'
@Injectable({
  providedIn: 'root'
})
export class ReporteIndividualService {

  constructor(
    private _htpp:HttpClient
  ) { }

  getIndividualReport(reporteIndividual : ReporteIndividual){
    return this._htpp.post('/individualReport', reporteIndividual)
  }
  getIndividualExpense(reporteIndividual : ReporteIndividual){
    return this._htpp.post('/individualExpense', reporteIndividual)
  }
  getExpensesByEmployee(reporteIndividual : ReporteIndividual){
    return this._htpp.post('/individualExpenseByEmployee', reporteIndividual)
  }

  getIndividualReportLogs(reporteIndividual : ReporteIndividual){
    return this._htpp.post('/individualReportLogs', reporteIndividual)
  }
}
