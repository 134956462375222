import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TypeItem } from '../type-item';
import { TypeItemService } from '../type-item.service';

@Component({
  selector: 'app-type-item-edit',
  templateUrl: './type-item-edit.component.html',
  styleUrls: ['./type-item-edit.component.css']
})
export class TypeItemEditComponent implements OnInit {
  @Input() typeItem : TypeItem;
  @Output() updateTypeItem = new EventEmitter();
  editTypeItem: TypeItem = new TypeItem;
  form = new UntypedFormGroup({
    tipoItem: new UntypedFormControl('',Validators.required)
  })

  constructor(
    private _typeItemService : TypeItemService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  ngOnInit() {
    this.clone();
    this.markFormGroupTouched(this.form)
  }

  habilitar(){
    Swal.fire({
      icon: 'info',
      title: "Habilitando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.editTypeItem.ID_TYPE_AVAILABLE = 1;
    var self = this;
    this._typeItemService.update(this.editTypeItem)
    .subscribe(function (value){
      if(value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del tipo de producto.'
        })
      }else{
        self.updateHabilitar()
      }
    })
  }

  deshabilitar(){
    Swal.fire({
      icon: 'info',
      title: "Deshabilitando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading()
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.editTypeItem.ID_TYPE_AVAILABLE = 2;
    var self = this;
    this._typeItemService.update(this.editTypeItem)
    .subscribe(function (value){
      if(value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del tipo de producto'
        })
      }else{
        self.updateDeshabilitar()
      }
    })
  }

  updateDeshabilitar(){
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'Tipo de producto deshabilitado.',
      text: 'El tipo de producto fué deshabilitado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      self.updateTypeItem.emit()
    })
  }

  updateHabilitar(){
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'Tipo de producto habilitado.',
      text: 'El tipo de producto fué habilitado.',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      self.updateTypeItem.emit()
    })
  }

  update(){
    this.editTypeItem.editable = false;
    var self = this;
    this.editTypeItem.NAME_TYPE_ITEM = this.form.value.tipoItem
    this._typeItemService.update(this.editTypeItem)
    .subscribe(function (value) {
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la edición. Nombre duplicado'
        })
      }else{
        self.popUpUpdate()
      }
    });
  }
  popUpUpdate(){
    Swal.fire({
      icon: 'success',
      title: 'Tipo de item actualizado',
      text: 'El tipo de item fué actualizado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      this.updateTypeItem.emit()
    })
  }
  clone()
  {
    Object.assign(this.editTypeItem,this.typeItem);
    this.form.controls.tipoItem.setValue(this.editTypeItem.NAME_TYPE_ITEM);
  }

}
