import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Region } from '../region';

@Component({
  selector: 'app-region-list',
  templateUrl: './region-list.component.html',
  styleUrls: ['./region-list.component.css']
})
export class RegionListComponent implements OnInit {
  @Input() regions: Region[];
  @Output() destroyRegionEvent = new EventEmitter();
  @Output() updateRegionEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  destroy(region:Region){
    const response = confirm('delete?')
    if(response){
      this.destroyRegionEvent.emit(region);
    }
  }

  update(region:Region){
    this.updateRegionEvent.emit(region);
  }
}
