import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeEmployee } from '../type-employee';

@Component({
  selector: 'app-type-employee-list',
  templateUrl: './type-employee-list.component.html',
  styleUrls: ['./type-employee-list.component.css']
})
export class TypeEmployeeListComponent implements OnInit {
  @Input() typeEmployees: TypeEmployee[];
  @Output() destroyTypeEmployeeEvent = new EventEmitter();
  @Output() updateTypeEmployeeEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  destroy(typeEmployee: TypeEmployee){
    const response = confirm('delete?')
    if(response){
      this.destroyTypeEmployeeEvent.emit(typeEmployee);
    }
  }

  update(typeEmployee: TypeEmployee){
    this.updateTypeEmployeeEvent.emit(typeEmployee);
  }

}
