import { Component, OnInit,Input} from '@angular/core';
import {TypeEmployee} from '../type-employee'
import {TypeEmployeeComponent} from '../type-employee.component'
import {TypeEmployeeService} from '../type-employee.service'

@Component({
  selector: 'app-tabla-type-employee',
  templateUrl: './tabla-type-employee.component.html',
  styleUrls: ['./tabla-type-employee.component.css']
})
export class TablaTypeEmployeeComponent implements OnInit {
  @Input() typeEmployees : TypeEmployee[];
  public typeEmployeeSeleccionado : TypeEmployee
  display = 'none'
  typeEmployeeEliminar : TypeEmployee;
  constructor(
    private typeEmployeeService : TypeEmployeeService,
    private typeEmployeeComponent : TypeEmployeeComponent
  ) { }

  ngOnInit() {
    this.getTypeEmployees()
  }

  editar(p:TypeEmployee){
    this.typeEmployeeComponent.editTypeEmployee(p)
  }
  eliminarTypeEmployee()
  {
    this.typeEmployeeService.destroy(this.typeEmployeeEliminar).
    subscribe(status =>
      this.getTypeEmployees())
  }
  getTypeEmployees()
  {
    this.typeEmployeeService.getTypeEmployees()
    .subscribe((x:any) => this.typeEmployees = x);
  }

   //Model implementation
   openModalDialog(nombre:TypeEmployee){
     this.typeEmployeeEliminar = nombre;
    this.display = 'block';
  }

  closeModalDialog(){
    this.display = 'none';
  }

}
