import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";

import {Comment} from './comment'
import { Client } from '../client/client';



@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(    
    private _http:HttpClient
    ) { }
    create(comment: Comment){
      return this._http.post('/comment',comment)
    }
  
    update(comment: Comment){
      return this._http.put('/comment' ,comment)
    }
    destroy(comment: Comment){
      return this._http.delete('/comment/' + comment.ID_COMMENT)
    }
  
    getcomments(){
      return this._http.get('/comment')
    }
  
    getCommentOfClient(client: Client)
    {
      return this._http.get('/comment/'+client.ID_CLIENT)
    }
    getcomment(comment: Comment){
      return this._http.get('/comment/' + comment.ID_COMMENT)
    }
}
