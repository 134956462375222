import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {TypeTreasury} from '../type-treasury'

@Component({
  selector: 'app-type-treasury-list',
  templateUrl: './type-treasury-list.component.html',
  styleUrls: ['./type-treasury-list.component.css']
})
export class TypeTreasuryListComponent implements OnInit {
  @Input() typesTreasure: TypeTreasury[];
  @Output() destroyTypeTreasuryEvent = new EventEmitter();
  @Output() updateTypeTreasuryEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  destroy(typesTreasure:TypeTreasury){
    const response = confirm('delete?')
    if(response){
      this.destroyTypeTreasuryEvent.emit(typesTreasure);
    }
  }

  update(typesTreasure:TypeTreasury){
    this.updateTypeTreasuryEvent.emit(typesTreasure);
  }
}
