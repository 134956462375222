import { Component, OnInit, Input } from '@angular/core';
import { RequestService } from './request.service';
import { Client } from '../client/client';
import { Employee } from '../employee/employee';
import { TypeWork } from '../type-work/type-work';
import { Item } from '../item/item';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormArray,
  Validators,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { FullRequest } from './full-request';
import { LineItem } from '../line-item/line-item';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Modal } from '../misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestMisc } from '../misc/requestMisc';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css'],
})
export class RequestComponent extends Modal implements OnInit {
  employee: Employee;
  clientSelected: Client;
  factureForm: UntypedFormGroup;
  renderLastNames: boolean;
  Hacienda: boolean;
  isDesktop: boolean = false;

  constructor(
    private _requestService: RequestService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  userIsLogged() {
    const employee: Employee = this.authService.getUserLogged();
    if (employee != null) {
      this.employee = employee;
    }
  }

  getControls(frmGrp: UntypedFormGroup, key: string) {
    return (<UntypedFormArray>frmGrp.controls[key]).controls;
  }

  updateSelectedClient(event) {
    this.clientSelected = event;
    RequestMisc.refreshTax(this.factureForm, this.clientSelected);
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }
  isForHacienda(): boolean {
    var idTreasury = this.factureForm.get('idTypeTreasury').value.ID_TYPE_TREASURY;
    if (idTreasury == 1) {
      this.Hacienda = true;
      return true;
    }
    this.Hacienda = false;
    return false;
  }
  getFormValidationErrors() {
    Object.keys(this.factureForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.factureForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  ngOnInit() {
    this.userIsLogged();
    this.factureForm = this.factureForm = RequestMisc.formInit();
    this.factureForm.controls.itemsSeleccionados.setValue([]);
    this.factureForm.controls.typeWorkSeleccionados.setValue([]);
    this.getEmployee();
    this.factureForm.controls.totalTrabajo.setValue(0.0);
    this.factureForm.controls.totalTrabajoImpuesto.setValue(0.0);
    RequestMisc.setValidatorsLines(this.factureForm);
    this.getInfoDevice();
  }
  //Identifier of an object, type: 1 for work 2 for item
  removeLineForm(index: number, type: number) {
    RequestMisc.setValidatorsLines(this.factureForm);
    const control = <UntypedFormArray>this.factureForm.get('lines');
    for (var i = 0; i < control.length; i++) {
      var line = control.at(i);
      var WorkOrItem = line.get('WorkOrItem').value;
      if (WorkOrItem == type) {
        var ID = line.get('ID').value;
        if (ID == index) {
          control.removeAt(i);

          RequestMisc.getTotalWorks(this.factureForm, this.clientSelected);
          return;
        }
      }
    }
  }

  computeSubTotalLine(index: number) {
    RequestMisc.computeSubTotalLine(index, this.factureForm, this.clientSelected);
  }

  /**
   * Used by the UI to delete some line
   * @param index Index number of the line to be removed
   */
  removeLineButton(index: number) {
    const lineSelected = <UntypedFormArray>this.factureForm.get('lines');
    var type = lineSelected.at(index).get('WorkOrItem').value;
    if (type == 1) {
      var currentlySelected = this.factureForm.value.idTypeWork;
      var idToBeDeleted = lineSelected.at(index).get('ID').value;

      //Remove from typeWorkSeleccionado
      var idx = this.factureForm.controls.typeWorkSeleccionados.value.findIndex(x => x.ID_TYPE_WORK == idToBeDeleted);
      this.factureForm.controls.typeWorkSeleccionados.value.splice(idx, 1);

      //Remove from mat-select
      var idxCurrently = currentlySelected.findIndex(x => x == idToBeDeleted);
      currentlySelected.splice(idxCurrently, 1);
      this.factureForm.controls.idTypeWork.setValue(currentlySelected);

      //Remove line from view
      this.removeLineForm(idToBeDeleted, type);
      this.closeModal();
    } else {
      var currentlySelected = this.factureForm.value.idItem;
      var idToBeDeleted = lineSelected.at(index).get('ID').value;

      //Remove from itemSeleccionado
      var idx = this.factureForm.controls.itemsSeleccionados.value.findIndex(x => x.ID_ITEM == idToBeDeleted);
      this.factureForm.controls.itemsSeleccionados.value.splice(idx, 1);

      //Remove from mat-select
      var idxCurrently = currentlySelected.findIndex(x => x == idToBeDeleted);
      currentlySelected.splice(idxCurrently, 1);
      this.factureForm.controls.idItem.setValue(currentlySelected);

      //Remove line from view
      this.removeLineForm(idToBeDeleted, type);
      this.closeModal();
    }
  }

  create() {
    Swal.fire({
      icon: 'info',
      title: 'Creando orden...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    var newRequest = new FullRequest();
    //REQUEST
    newRequest.ID_CLIENT = this.clientSelected.ID_CLIENT;
    newRequest.COMMENTS = this.factureForm.get('comments').value;
    newRequest.ID_EMPLOYEE = this.employee.ID_EMPLOYEE;
    newRequest.ID_TYPE_STATE_REQUEST = 1;
    newRequest.ID_REQUEST_STATUS = 2;
    newRequest.ID_TYPE_PAYMENT = 4;
    newRequest.ID_TYPE_TREASURY = this.factureForm.get('idTypeTreasury').value.ID_TYPE_TREASURY;
    newRequest.TOTAL_PRICE = this.factureForm.get('totalTrabajo').value;

    if (this.isForHacienda()) {
      //1 es de hacienda
      newRequest.TAX = this.clientSelected.TAX;
      newRequest.TOTAL_PRICE = this.factureForm.get('totalTrabajoImpuesto').value;
    }
    //Lines
    const control = <UntypedFormArray>this.factureForm.get('lines');

    for (var i = 0; i < control.length; i++) {
      var line = control.at(i);
      var cant = line.get('cantidad').value;
      var precio = line.get('precio').value;
      var WorkOrItem = line.get('WorkOrItem').value;
      var ID = line.get('ID').value;
      var lineRequest = new LineItem();
      lineRequest.ID_ITEM = ID;
      lineRequest.AMOUNT = cant;
      lineRequest.PRICE_BY_UNIT = precio;
      if (WorkOrItem == 1) {
        newRequest.LINES_TYPEWORK.push(lineRequest);
      } else {
        newRequest.LINES_ITEM.push(lineRequest);
      }
    }

    var price = this.factureForm.get('totalTrabajo').value.toString(); // Falta incluir impuesto
    if (this.isForHacienda()) {
      //1 es de hacienda
      var tempTot = Number(this.factureForm.get('totalTrabajoImpuesto').value);
      price = Math.ceil(tempTot).toString();
    }

    var self = this;
    this._requestService.createNewFullRequest(newRequest).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la creación de la orden',
          footer: '<a>El monto solicitado de alguno articulos es superior al solicitado actualmente.</a>',
        });
        self.ngOnInit();
      } else {
        self.popUpCreate();
        self.ngOnInit();
      }
    });
  }

  popUpCreate() {
    Swal.fire({
      icon: 'success',
      title: 'Orden de trabajo realizada',
      text: 'La orden de trabajo fué procesada con éxito',
      confirmButtonText: 'Aceptar',
    });
  }

  getEmployee() {
    this.factureForm.controls.nameEmployee.setValue(this.employee.NAME_EMPLOYEE);
    this.factureForm.controls.phoneEmployee.setValue(this.employee.PHONE);
    this.factureForm.controls.emailEmployee.setValue(this.employee.EMAIL);
  }
  onChange() {
    var idClient = this.factureForm.value.idClient;
    this.clientSelected = idClient;
    RequestMisc.onClientChange(this.factureForm);
    if (this.clientSelected != undefined) {
      if (this.clientSelected.JURIDICA) {
        this.renderLastNames = false;
      } else {
        this.renderLastNames = true;
      }
      RequestMisc.refreshTax(this.factureForm, this.clientSelected);
    }
  }
}
