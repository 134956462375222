import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Report } from './report';
import { ReportService } from './report.service';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl, FormBuilder } from '@angular/forms';
import { TipoReporte } from './tipo-reporte';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDatepicker } from '@angular/material/datepicker';
import { imageData } from '../resources/image';
import { DatePipe, formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { PaymentService } from '../payment/payment.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Region } from '../region/region';
import { RegionService } from '../region/region.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { Modal } from '../misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter } from '@angular/material/core';

const { jsPDF } = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent extends Modal implements OnInit {
  @ViewChild('ReportDiv', { static: false }) myDivRef: ElementRef;

  isString = 0;
  isNumber = 1;
  isDate = 2;

  reports: Report[] = [];
  Descripcion_report: String = '';
  isDesktop: boolean = false;

  reportsCreatedBy: Report[] = [];
  paymentsByEmployee: Report[] = [];
  resumeClient: any[] = [];
  resumePaymentType: any[] = [];

  bdNames: String[] = [];
  showNames: String[] = [];
  dataTypes: number[] = [];
  dataTypesLines: number[] = [];

  showDepositTable: boolean = false;
  showResume: boolean = false;
  bdNamesLines: String[] = [];
  showNamesLines: String[] = [];
  TITULO: String = 'Reporte';
  buttonDetails: String = 'Detalle';
  showPDF = 0;

  public typeReportFilterControl: FormControl = new FormControl();
  public filteredTypeReport: ReplaySubject<TipoReporte[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();

  public regionFilterControl: FormControl = new FormControl();
  public filteredRegion: ReplaySubject<Region[]> = new ReplaySubject(1);

  date = new Date();
  selectTipoValue = '0';
  showDataRange = false;
  showRegion = false;
  showPDFButton = false;
  initialDateValue = '';
  finalDateValue = '';
  minDate = '';
  reportForm: UntypedFormGroup;
  tipoReport: TipoReporte[] = [];
  tipoReporte = [
    'Clientes deudores más de un mes',
    'Gastos por tipo',
    'Ingresos y egresos',
    'Todos los ingresos',
    'Todos los egresos',
    'Inventario',
    'Órdenes de trabajo generadas',
    'Órdenes de trabajo de hacienda',
    'Ingresos por vendedores',
    'Reporte general de hacienda',
    'Órdenes de trabajo incobrables',
    'Reporte por ruta',
  ];

  depositos: number = 0;
  salidas: number = 0;
  dataSource = new MatTableDataSource<Report>();

  regions: Region[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private _reportService: ReportService,
    private _paymentService: PaymentService,
    private _regionService: RegionService,
    private deviceService: DeviceDetectorService,
    private modalServiceExt: NgbModal,
    private dateAdapter: DateAdapter<Date>
  ) {
    super(modalServiceExt);
    this.reportForm = new UntypedFormGroup({
      //FORM CLIENTE
      ID_Report: new UntypedFormControl([], Validators.required),
      initialDateValue: new UntypedFormControl('', Validators.required),
      finalDateValue: new UntypedFormControl('', Validators.required),
      region: new UntypedFormControl([]),
    });
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }

  getRegions() {
    this._regionService.getRegions().subscribe((regions: any) => {
      this.regions = regions;
      this.filteredRegion.next(this.regions.slice());
      // Apply changes subscribed to the filter
      this.regionFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterRegionComboBox();
      });
    });
  }

  protected filterRegionComboBox() {
    if (!this.regions) {
      return;
    }

    let search = this.regionFilterControl.value;
    if (!search) {
      this.filteredRegion.next(this.regions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredRegion.next(this.regions.filter(region => region.NAME_REGION.toLowerCase().indexOf(search) > -1));
  }

  formInit() {
    this.getRegions();
  }

  ngOnInit() {
    this.formInit();
    var i = 0;
    this.tipoReporte.forEach(element => {
      var newReport = new TipoReporte();
      newReport.ID_TIPO_REPORTE = i;
      newReport.NOMBRE_REPORTE = this.tipoReporte[i];
      this.tipoReport.push(newReport);
      i += 1;
    });

    this.filteredTypeReport.next(this.tipoReport.slice());
    // Apply changes subscribed to the filter
    this.typeReportFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterTypeReportComboBox();
    });
  }

  protected filterTypeReportComboBox() {
    if (!this.tipoReport) {
      return;
    }

    let search = this.typeReportFilterControl.value;
    if (!search) {
      this.filteredTypeReport.next(this.tipoReport.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredTypeReport.next(
      this.tipoReport.filter(typeReport => typeReport.NOMBRE_REPORTE.toLowerCase().indexOf(search) > -1)
    );
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  getInitialDate() {
    var initial = this.reportForm.value.initialDateValue.toISOString().substring(0, 10);
    return initial;
  }

  getFinalDate() {
    var final = this.reportForm.value.finalDateValue.toISOString().substring(0, 10);
    return final;
  }

  setMinDate() {
    var date = this.reportForm.value.initialDateValue;
    var finalDate = this.reportForm.value.finalDateValue;
    var dateI = new Date(date);
    var dateF = new Date(finalDate);

    if (dateI >= dateF || finalDate == '') {
      this.reportForm.controls.finalDateValue.setValue(date);
    }
    this.minDate = date;
  }

  create() {
    var idReport = this.reportForm.value.ID_Report.ID_TIPO_REPORTE;
    if (idReport == 8) {
      this.showPDFButton = true;
    } else {
      this.showPDFButton = false;
    }
    if (idReport == 3 || idReport == 4 || idReport == 8) {
      this.showDepositTable = true;
      if (idReport != 8) {
        this.showResume = true;
      } else {
        this.showResume = false;
      }
    } else {
      this.showDepositTable = false;
      this.showResume = false;
      if (idReport == 11) {
        this.showResume = true;
      }
    }
    this.buttonDetails = 'Detalle';
    switch (idReport) {
      case 0:
        this.bdNames = ['NAME_CLIENT', 'IDN', 'TOTAL_DEBT', 'OLDER_DATE', 'DAYS_OF_INDEBTEDNESS'];
        this.showNames = ['Nombre del cliente', 'Cédula', 'Total de la deuda', 'Fecha', 'Dias de endeudedamiento'];
        this.dataTypes = [this.isString, this.isString, this.isNumber, this.isDate, this.isNumber];
        this.deudoresMes();
        this.Descripcion_report =
          'Genera un reporte del clientes que tienen algúna deuda pendiente mayor a 30 días en el sistema.';
        break;
      case 1:
        var report = new Report();
        report.INITIALDATE = this.getInitialDate();
        report.FINALDATE = this.getFinalDate();
        this.bdNames = ['NAME_TYPE_EXPENSE', 'TOTAL_EXPENSE'];
        this.showNames = ['Tipo de gasto', 'Total del gasto'];
        this.dataTypes = [this.isString, this.isNumber];
        this.gastoPorTipo(report);
        this.Descripcion_report =
          'Genera un reporte de los gastos entre la fecha de inicio y la fecha final de acuerdo al tipo de gasto.';
        break;
      case 2:
        var report = new Report();
        report.INITIALDATE = this.getInitialDate();
        report.FINALDATE = this.getFinalDate();
        this.bdNames = ['TIPO', 'TOTAL'];
        this.showNames = ['Tipo', 'Total'];
        this.dataTypes = [this.isString, this.isNumber];
        this.Descripcion_report =
          'Genera un reporte general del total de ingresos y el total de egresos entre la fecha de inicio y la fecha final.';
        this.ingresosEgresos(report);
        break;
      case 3:
        var report = new Report();
        report.INITIALDATE = this.getInitialDate();
        report.FINALDATE = this.getFinalDate();
        this.bdNames = [
          'NAME_EMPLOYEE',
          'NAME_CLIENT',
          'LAST_NAME',
          'LAST_2_NAME',
          'IDN',
          'AMOUNT_DEPOSIT',
          'AMOUNT_DUE',
          'DUE_AFTER_DEPOSIT',
          'DATE_PAY',
        ];
        this.showNames = [
          'Nombre del colaborador',
          'Nombre del cliente',
          '1° Apellido',
          '2° Apellido',
          'Cédula del cliente',
          'Monto del depósito',
          'Monto inicial de la deuda',
          'Deuda después del pago',
          'Fecha del pago',
        ];
        this.dataTypes = [
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isNumber,
          this.isNumber,
          this.isNumber,
          this.isDate,
        ];
        this.ingresos(report);
        this.Descripcion_report =
          'Genera un reporte con cada uno de los registros de ingresos y la suma del total de movimientos. Además muestra un resúmen del total de movimientos por colaborador y un desglose de los ingresos totales por tipo entre la fecha de inicio y la fecha final.. ';
        break;
      case 4:
        var report = new Report();
        report.INITIALDATE = this.getInitialDate();
        report.FINALDATE = this.getFinalDate();
        this.bdNames = ['NAME_EMPLOYEE', 'NAME_TYPE_EXPENSE', 'DATE_EXPENSE', 'DESCRIPTION_EXPENSE', 'EXPENSE'];
        this.showNames = [
          'Nombre del colaborador',
          'Tipo del gasto',
          'Fecha del gasto',
          'Descripción del gasto',
          'Monto gastado',
        ];
        this.dataTypes = [this.isString, this.isString, this.isDate, this.isString, this.isNumber];
        this.Descripcion_report =
          'Genera un reporte con cada uno de los registro de egresos y la suma del total de movimientos. Además muestra un resúmen del total de movimientos por colaborador y un desglose de los ingresos totales por tipo entre la fecha de inicio y la fecha final.. ';
        this.egresos(report);
        break;
      case 5:
        this.bdNames = ['ID_ITEM', 'NAME_TYPE_ITEM', 'STOCK', 'NAME_ITEM', 'PRICE'];
        this.showNames = [
          'Código del item',
          'Nombre del tipo item',
          'Existencias en inventario',
          'Nombre del item',
          'Precio sugerido',
        ];
        this.dataTypes = [this.isString, this.isString, this.isNumber, this.isString, this.isNumber];
        this.Descripcion_report = 'Genera un reporte del inventario disponible.';
        this.stock(report);
        break;
      case 6:
        var report = new Report();
        report.INITIALDATE = this.getInitialDate();
        report.FINALDATE = this.getFinalDate();
        this.bdNames = [
          'ID_REQUEST',
          'NAME_CLIENT',
          'LAST_NAME',
          'LAST_2_NAME',
          'IDN',
          'NAME_EMPLOYEE',
          'DATE_REQUEST',
          'TOTAL_PRICE',
          'NAME_TYPE_REQUEST',
          'NAME_TYPE',
          'NAME_REQUEST_STATUS',
          'DATE_DELIVERY',
          'OPCIONES',
        ];
        this.showNames = [
          '#',
          'Nombre del cliente',
          '1° Apellido',
          '2° Apellido',
          'Cédula del cliente',
          'Nombre del colaborador',
          'Fecha de creación',
          'Precio total',
          'Estado de la factura',
          'Tipo de facturación',
          'Estado de entrega',
          'Fecha de entrega',
          'Opciones',
        ];
        this.dataTypes = [
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isDate,
          this.isNumber,
          this.isString,
          this.isString,
          this.isString,
          this.isDate,
          this.isString,
        ];
        this.bdNamesLines = ['NAME', 'DESCRIPTION', 'AMOUNT', 'PRICE', 'TOTAL_PRICE'];
        this.showNamesLines = ['Articulo/Trabajo', 'Descripción', 'Cantidad', 'Precio unitario', 'Precio subtotal'];
        this.dataTypesLines = [this.isString, this.isString, this.isNumber, this.isNumber, this.isNumber];
        this.Descripcion_report =
          'Genera un reporte con cada factura en estado procesada entre la fecha de inicio y la fecha final.';
        this.facturas(report);
        break;
      case 7:
        var report = new Report();
        report.INITIALDATE = this.getInitialDate();
        report.FINALDATE = this.getFinalDate();
        this.bdNames = [
          'ID_REQUEST',
          'NAME_CLIENT',
          'LAST_NAME',
          'LAST_2_NAME',
          'IDN',
          'NAME_EMPLOYEE',
          'DATE_REQUEST',
          'TOTAL_BEFORE_TAX',
          'TOTAL_TAX',
          'TOTAL_PRICE',
          'NAME_TYPE_REQUEST',
          'NAME_TYPE',
          'NAME_REQUEST_STATUS',
          'DATE_DELIVERY',
          'OPCIONES',
        ];
        this.showNames = [
          '#',
          'Nombre del cliente',
          '1° Apellido',
          '2° Apellido',
          'Cédula del cliente',
          'Nombre del colaborador',
          'Fecha de creación',
          'Monto subtotal',
          'Impuesto',
          'Subtotal + IVA',
          'Estado de la factura',
          'Tipo de facturación',
          'Estado de la factura',
          'Fecha de entrega',
          'Opciones',
        ];
        this.dataTypes = [
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isDate,
          this.isNumber,
          this.isNumber,
          this.isNumber,
          this.isString,
          this.isString,
          this.isString,
          this.isDate,
          this.isString,
        ];
        this.bdNamesLines = ['NAME', 'DESCRIPTION', 'AMOUNT', 'PRICE', 'TOTAL_BEFORE_TAX', 'TOTAL_TAX', 'TOTAL_PRICE'];
        this.showNamesLines = [
          'Articulo/Trabajo',
          'Descripción',
          'Cantidad',
          'Precio unitario',
          'Subtotal sin IVA',
          'IVA',
          'Precio subtotal',
        ];
        this.dataTypesLines = [
          this.isString,
          this.isString,
          this.isNumber,
          this.isNumber,
          this.isNumber,
          this.isNumber,
          this.isNumber,
        ];
        this.facturasHacienda(report);
        this.Descripcion_report =
          'Genera un reporte con cada factura de hacienda en estado procesada entre la fecha de inicio y la fecha final.';
        break;
      case 8:
        var report = new Report();
        report.INITIALDATE = this.getInitialDate();
        report.FINALDATE = this.getFinalDate();
        this.bdNames = ['ID_EMPLOYEE', 'NAME_EMPLOYEE', 'USERNAME', 'AMOUNT_DEPOSIT', 'OPCIONES'];
        this.showNames = ['Id del colaborador', 'Nombre del colaborador', 'Nombre de usuario', 'Monto obtenido'];
        this.dataTypes = [this.isString, this.isString, this.isString, this.isNumber];
        this.bdNamesLines = [
          'ID_REQUEST',
          'NAME_EMPLOYEE',
          'ESTADO_ENTREGA',
          'NAME_TYPE_REQUEST',
          'DATE_PAY',
          'AMOUNT_DEPOSIT',
          'DEUDA',
        ];
        this.showNamesLines = ['Id factura', 'Colaborador', 'Entrega', 'Estado', 'Fecha', 'Último depósito', 'Deuda'];
        this.dataTypesLines = [
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isDate,
          this.isNumber,
          this.isNumber,
        ];
        this.ingresosVendedores(report);
        this.Descripcion_report =
          'Genera un reporte de cada colaborador con el monto obtenido entre la fecha de inicio y la fecha final. Puede generar un archivo PDF por cada colaborador y obtener un reporte de las facturas incompletas.';
        break;
      case 9:
        var report = new Report();
        report.INITIALDATE = this.getInitialDate();
        report.FINALDATE = this.getFinalDate();
        this.bdNames = ['TIPO', 'TOTAL'];
        this.dataTypes = [this.isString, this.isNumber];
        this.showNames = ['Tipo de dato', 'Total de dinero asociado'];
        this.Descripcion_report =
          'Genera un reporte de los ingresos por facturas electrónicas, los ingresos sin el impuesto y el total de impuestos a pagar entre la fecha de inicio y la fecha final.';
        this.hacienda(report);
        break;
      case 10:
        var report = new Report();
        this.bdNames = [
          'ID_REQUEST',
          'NAME_CLIENT',
          'LAST_NAME',
          'LAST_2_NAME',
          'IDN',
          'NAME_EMPLOYEE',
          'DATE_REQUEST',
          'TOTAL_PRICE',
          'NAME_TYPE_REQUEST',
          'NAME_TYPE',
          'NAME_REQUEST_STATUS',
          'DATE_DELIVERY',
          'OPCIONES',
        ];
        this.showNames = [
          '#',
          'Nombre del cliente',
          '1° Apellido',
          '2° Apellido',
          'Cédula del cliente',
          'Nombre del colaborador',
          'Fecha de creación',
          'Precio total',
          'Estado de la factura',
          'Tipo de facturación',
          'Estado de entrega',
          'Fecha de entrega',
          'Opciones',
        ];
        this.dataTypes = [
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isDate,
          this.isNumber,
          this.isString,
          this.isString,
          this.isString,
          this.isDate,
          this.isString,
        ];
        this.bdNamesLines = ['NAME', 'AMOUNT', 'PRICE', 'TOTAL_PRICE'];
        this.showNamesLines = ['Articulo/Trabajo', 'Cantidad', 'Precio unitario', 'Precio subtotal'];
        this.dataTypesLines = [this.isString, this.isNumber, this.isNumber, this.isNumber];
        this.uncollectible(report);
        this.Descripcion_report = 'Genera un reporte de las facturas que fueron seleccionadas como incobrables.';
        break;

      case 11:
        var report = new Report();
        report.INITIALDATE = this.getInitialDate();
        report.FINALDATE = this.getFinalDate();
        this.bdNames = [
          'ID_REQUEST',
          'IDN',
          'NAME_CLIENT',
          'LAST_NAME',
          'LAST_2_NAME',
          'DATE_REQUEST',
          'NAME_TYPE',
          'NAME_REGION',
          'TYPE_PAYMENT',
          'AMOUNT_DEPOSIT',
          'DATE_PAY',
        ];
        this.showNames = [
          '#',
          'Cédula del cliente',
          'Nombre del cliente',
          '1° Apellido',
          '2° Apellido',
          'Fecha de creación',
          'Tipo Factura',
          'Ruta',
          'Tipo de pago',
          'Depósito',
          'Fecha de pago',
        ];
        this.dataTypes = [
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isString,
          this.isDate,
          this.isString,
          this.isString,
          this.isString,
          this.isNumber,
          this.isDate,
        ];

        this.reportePorRutas(report);
        this.Descripcion_report = 'Genera reporte de pagos para cada ruta de acuerdo a las fechas establecidas.';
        break;
    }

    this.scroll();
  }

  scroll() {
    this.myDivRef.nativeElement.scrollIntoView();
    // now account for fixed header
    var scrolledY = window.scrollY;

    if (scrolledY) {
      window.scroll(0, scrolledY - 85);
    }
  }

  deudoresMes() {
    var self = this;
    this._reportService.getReport0().subscribe(x => self.updateReports(x));
  }

  gastoPorTipo(report: Report) {
    var self = this;
    this._reportService.getReport1(report).subscribe(x => self.updateReports(x));
  }

  ingresosEgresos(report: Report) {
    var self = this;
    this._reportService.getReport2(report).subscribe(x => self.updateReports(x));
  }

  ingresos(report: Report) {
    var self = this;
    this._reportService.getReport3(report).subscribe(x => {
      self.updateReports(x);
    });
    this._reportService.resumeEmployees(report).subscribe(x => self.updateResume(x));
    this._reportService.getPaymentByType(report).subscribe(x => {
      self.updatePaymentType(x);
    });
  }

  filtrarTipoPago(log, ID_REGION) {
    let registroPagos = new Map<string, number>();
    log.forEach(pago => {
      if (pago.ID_REGION === ID_REGION) {
        if (registroPagos.get(pago.TYPE_PAYMENT) == undefined) {
          registroPagos.set(pago.TYPE_PAYMENT, pago.AMOUNT_DEPOSIT);
        } else {
          var num = registroPagos.get(pago.TYPE_PAYMENT);
          registroPagos.set(pago.TYPE_PAYMENT, pago.AMOUNT_DEPOSIT + num);
        }
      }
    });
    let keys = Array.from(registroPagos.keys());
    let values = Array.from(registroPagos.values());

    var array = [];
    var total = 0;
    for (var i = 0; i < keys.length; i++) {
      var jsonObject = '{"NAME" : "' + keys[i] + '", "AMOUNT":' + values[i] + '}';
      array.push(jsonObject);
      total += values[i];
    }
    var jsonObject = '{"NAME" : "Total", "AMOUNT":' + total + '}';
    array.push(jsonObject);
    var jsonData = JSON.parse('[' + array.toString() + ']');
    this.updatePaymentType(jsonData);
  }

  updatePaymentType(x) {
    this.resumePaymentType = x;
  }

  egresos(report: Report) {
    var self = this;
    this._reportService.getReport4(report).subscribe(x => {
      self.updateReports(x);
    });
    this._reportService.resumeEmployees(report).subscribe(x => self.updateResume(x));
  }

  stock(report: Report) {
    var self = this;
    this._reportService.getReport5(report).subscribe(x => self.updateReports(x));
  }

  getReport6(report: Report) {
    var self = this;
    this._reportService.getReport6(report).subscribe(x => self.updateReports(x));
  }

  facturas(report: Report) {
    var self = this;
    this._reportService.getReport6(report).subscribe(function (value) {
      self.updateReports(value);
      self.reports.forEach(element => {
        var reportSend = new Report();
        reportSend.ID_REQUESTP = element.ID_REQUEST;
        self._reportService.getReport7(reportSend).subscribe(xv => (element.lines = xv));
      });
    });
  }

  reportePorRutas(report: Report) {
    var self = this;
    report.ID_REGION = self.reportForm.value.region.ID_REGION;
    this._reportService.reporteRutas(report).subscribe(function (value) {
      self.updateReports(value);
      self.filtrarTipoPago(value, self.reportForm.value.region.ID_REGION);
    });
  }
  facturasHacienda(report: Report) {
    var self = this;
    this._reportService.getReport8(report).subscribe(function (value) {
      self.updateReports(value);
      self.reports.forEach(element => {
        element.TOTAL_BEFORE_TAX = Math.floor(element.TOTAL_PRICE / (element.TAX + 1));
        element.TOTAL_TAX = element.TOTAL_PRICE - element.TOTAL_BEFORE_TAX;
        var reportSend = new Report();
        reportSend.ID_REQUESTP = element.ID_REQUEST;
        self._reportService.getReport7(reportSend).subscribe(function (xv) {
          element.lines = xv;
        });
      });
    });
  }

  ingresosVendedores(report: Report) {
    this.buttonDetails = 'Incompletas';
    var self = this;
    this._reportService.getReport9(report).subscribe(x => {
      self.updateReports(x);
      self.reports.forEach(element => {
        var reportSend = new Report();
        reportSend.FECHA_INICIAL = this.getInitialDate();
        reportSend.FECHA_FINAL = this.getFinalDate();
        reportSend.ID_EMPLOYEE = element.ID_EMPLOYEE;
        self._reportService.incompletePayments(reportSend).subscribe((x: any) => {
          element.lines = x;
        });
      });
    });
  }

  async generatePDF(report: Report) {
    Swal.fire({
      icon: 'info',
      title: 'Generando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    report.INITIALDATE = this.getInitialDate();
    report.FINALDATE = this.getFinalDate();

    this._reportService.paymentsByEmployee(report).subscribe((value: any) => {
      this.paymentsByEmployee = value;
    });
    var reportsCreatedBy = await this._reportService
      .requestCreatedDates(report)
      .toPromise()
      .then(async (reportsCreatedBy: any) => {
        for (let index = 0; index < reportsCreatedBy.length; index++) {
          var reportSend = new Report();
          reportSend.ID_REQUESTP = reportsCreatedBy[index].ID_REQUEST;

          await this._paymentService
            .getRequestLogHistory(reportSend)
            .toPromise()
            .then(val => {
              reportsCreatedBy[index].logs = val;
            });

          await this._reportService
            .getReport7(reportSend)
            .toPromise()
            .then(val => {
              reportsCreatedBy[index].lines = val;
            });
        }
        this.createPDF(report, reportsCreatedBy);
        Swal.fire({
          icon: 'success',
          title: 'Listo!',
          showConfirmButton: false,
          timer: 1000,
        });
      });
  }

  createPDF(report: Report, reportsCreatedBy: any) {
    const doc = new jsPDF();
    doc.addImage(imageData, 'JPEG', 10, 240, 190, 46);

    doc.setFontSize(22);
    doc.setTextColor(14, 168, 147);
    doc.text(20, 20, 'Reporte de facturación');

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    var format = 'dd/MM/yyyy';
    const locale = 'en-US';
    var hoy = formatDate(new Date(), format, locale);
    var doo = new Date(this.reportForm.value.initialDateValue);
    var fechaInicial = formatDate(new Date(doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)), format, locale);
    var doo = new Date(this.reportForm.value.finalDateValue);
    var fechaFinal = formatDate(new Date(doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)), format, locale);

    var splitText = doc.splitTextToSize(
      'Reporte de facturación expendido en la fecha: ' +
        hoy +
        '.\n' +
        'Este reporte contiene todas las facturas generadas desde el ' +
        fechaInicial +
        ' hasta el ' +
        fechaFinal,
      180
    );
    doc.text(20, 30, splitText);

    //doc.setFontSize(18);
    //doc.setTextColor(14, 168, 147);
    //doc.text(20, 20, 'Reporte de facturas creadas');
    reportsCreatedBy.forEach(element => {
      element.DATE_REQUEST = new Date(element.DATE_REQUEST);
      const datePipe = new DatePipe('en-US');
      element.DATE_REQUEST = datePipe.transform(element.DATE_REQUEST, 'yyyy-MM-dd HH:mm:ss');
      doc.addPage();
      doc.setFontSize(22);
      doc.setTextColor(14, 168, 147);
      doc.text(20, 20, 'Número de factura ' + element.ID_REQUEST);

      doc.autoTable({
        head: [['Fecha de creación', 'Cliente', 'Cédula', 'Tipo de factura']],
        body: [[element.DATE_REQUEST, element.NAME_CLIENT, element.IDN, element.NAME_TYPE]],
        startY: 35,
      });

      doc.setFontSize(12);
      doc.setTextColor(12, 32, 131);
      doc.text(20, doc.previousAutoTable.finalY + 10, 'Detalle de la factura');

      var lines = [];

      element.lines.forEach(dataline => {
        lines.push([dataline.NAME, dataline.AMOUNT, dataline.PRICE, dataline.TOTAL_PRICE]);
      });
      doc.autoTable({
        head: [['Articulo/Trabajo', 'Cantidad', 'Precio unitario', 'Precio subtotal']],
        body: lines,
        startY: doc.previousAutoTable.finalY + 15,
      });

      doc.setFontSize(12);
      doc.setTextColor(12, 32, 131);
      doc.text(20, doc.previousAutoTable.finalY + 10, 'Pagos asociados:');

      var linespa = [];
      element.logs.forEach(dataline => {
        dataline.DATE_PAY = new Date(dataline.DATE_PAY);
        dataline.DATE_PAY = datePipe.transform(dataline.DATE_PAY, 'yyyy-MM-dd HH:mm:ss');
        linespa.push([
          dataline.NAME_EMPLOYEE,
          dataline.AMOUNT_DEPOSIT,
          dataline.NAME_TYPE_PAYMENT,
          dataline.AMOUNT_DUE,
          dataline.DUE_AFTER_DEPOSIT,
          dataline.DATE_PAY,
        ]);
      });
      doc.autoTable({
        head: [
          [
            'Nombre del colaborador',
            'Monto del deposito',
            'Método de pago',
            'Deuda inicial',
            'Deuda depues del deposito',
            'Fecha de pago',
          ],
        ],
        body: linespa,
        startY: doc.previousAutoTable.finalY + 15,
      });
    });

    doc.addPage();
    doc.setFontSize(12);
    doc.setTextColor(12, 32, 131);
    doc.text(20, 20, 'Registro de cobros');

    var lines = [];
    var sum = 0;
    this.paymentsByEmployee.forEach(dataline => {
      sum += dataline.AMOUNT_DEPOSIT;
      var datepipe: DatePipe = new DatePipe('en-US');
      var newDate = new Date(dataline.DATE_PAY);
      let newStringDate = datepipe.transform(newDate, 'dd/MM/yyyy, h:mm:ss a');
      lines.push([dataline.ID_REQUEST, newStringDate, dataline.AMOUNT_DEPOSIT, dataline.DEUDA]);
    });
    doc.autoTable({
      head: [['ID Factura', 'Día de cobro', 'Cantidad', 'Deuda']],
      body: lines,
      startY: 35,
    });

    doc.setFontSize(12);
    doc.setTextColor(12, 32, 131);
    doc.text(20, doc.previousAutoTable.finalY + 10, 'Para un total de:');

    doc.autoTable({
      head: [['Total']],
      body: [[sum]],
      startY: doc.previousAutoTable.finalY + 15,
    });

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    doc.save('Historial ' + report.NAME_EMPLOYEE + ' ' + hoy + ' .pdf');
  }

  hacienda(report: Report) {
    var self = this;
    this._reportService
      .getReport10(report)
      .toPromise()
      .then(x => self.updateReports(x));
  }

  uncollectible(report: Report) {
    var self = this;
    this._reportService.getUncollectible(report).subscribe(function (value) {
      self.updateReports(value);
      self.reports.forEach(element => {
        var reportSend = new Report();
        reportSend.ID_REQUESTP = element.ID_REQUEST;
        self._reportService.getReport7(reportSend).subscribe(xv => (element.lines = xv));
      });
    });
  }

  updateReports(value) {
    this.reports = value;
    this.dataSource = new MatTableDataSource(this.reports);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'DATE_PAY':
          var date = new Date(item.DATE_PAY);
          return date;

        case 'DATE_REQUEST':
          var date = new Date(item.DATE_REQUEST);
          return date;
        case 'DATE_EXPENSE':
          var date = new Date(item.DATE_EXPENSE);
          return date;
        case 'DATE_DELIVERY':
          var date = new Date(item.DATE_DELIVERY);
          return date;
        default:
          return item[property];
      }
    };
  }

  updateResume(value) {
    this.resumeClient = value;
  }

  updateSelect() {
    var idReport = this.reportForm.value.ID_Report.ID_TIPO_REPORTE;
    if (idReport != '0' && idReport != '5' && idReport != '10') {
      this.showDataRange = true;
      if (idReport == 11) {
        this.showRegion = true;
        this.reportForm.controls['region'].setValidators([Validators.required]);
        this.reportForm.controls['region'].updateValueAndValidity();
      } else {
        this.showRegion = false;
      }
      this.reportForm.controls['initialDateValue'].setValidators([Validators.required]);
      this.reportForm.controls['initialDateValue'].updateValueAndValidity();
      this.reportForm.controls['finalDateValue'].setValidators([Validators.required]);
      this.reportForm.controls['finalDateValue'].updateValueAndValidity();
    } else {
      this.showDataRange = false;
      this.showRegion = false;
      this.reportForm.controls['region'].clearValidators();
      this.reportForm.controls['region'].updateValueAndValidity();
      this.reportForm.controls['initialDateValue'].clearValidators();
      this.reportForm.controls['initialDateValue'].updateValueAndValidity();
      this.reportForm.controls['finalDateValue'].clearValidators();
      this.reportForm.controls['finalDateValue'].updateValueAndValidity();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    var idReport = this.reportForm.value.ID_Report.ID_TIPO_REPORTE;
    switch (idReport) {
      case 3:
        this.depositos = 0;
        this.dataSource.filteredData.forEach(element => {
          this.depositos += element['AMOUNT_DEPOSIT'];
        });
        break;
      case 4:
        this.salidas = 0;
        this.dataSource.filteredData.forEach(element => {
          this.salidas += element['EXPENSE'];
        });
        break;
      case 8:
        this.depositos = 0;
        this.dataSource.filteredData.forEach(element => {
          this.depositos += element['AMOUNT_DEPOSIT'];
        });
        break;
      case 11:
        this.filtrarTipoPago(this.dataSource.filteredData, this.reportForm.value.region);
      default:
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
    }
  }
}
