import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {TYPESTATEREQUEST} from '../type-state-request';

@Component({
  selector: 'app-type-state-request-list',
  templateUrl: './type-state-request-list.component.html',
  styleUrls: ['./type-state-request-list.component.css']
})
export class TYPESTATEREQUESTListComponent implements OnInit {

  @Input() typeStateRequests: TYPESTATEREQUEST[];
  @Output() destroyTypeStateRequestEvent = new EventEmitter();
  @Output() updateTypeStateRequestEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  destroy(typeStateRequest: TYPESTATEREQUEST)
  {
    const response = confirm('delete?')
    if(response){
      this.destroyTypeStateRequestEvent.emit(typeStateRequest);
    }
    
  }

  update(typeStateRequest: TYPESTATEREQUEST)
  {
    this.updateTypeStateRequestEvent.emit(typeStateRequest);
  }
}
