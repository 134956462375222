import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ReporteIndividualService } from './reporte-individual.service';
import { ReporteIndividual } from './reporte-individual';
import { Employee } from '../employee/employee';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../services/auth.service';
import { ExpenseService } from '../expense/expense.service';
import { Expense } from '../expense/expense';
import { exec } from 'child_process';

@Component({
  selector: 'app-reporte-individual',
  templateUrl: './reporte-individual.component.html',
  styleUrls: ['./reporte-individual.component.css'],
})
export class ReporteIndividualComponent implements OnInit {
  Descripcion_report: String = '';
  usuario: Employee;
  individualForm: UntypedFormGroup;
  reporte = new ReporteIndividual();
  reporteIndividual: ReporteIndividual[] = [];
  expensesByEmployee: Expense[] = [];
  expense: ReporteIndividual;
  minDate = '';
  displayedColumns: string[] = [
    'ID_REQUEST',
    'NAME_EMPLOYEE',
    'AMOUNT_DEPOSIT',
    'DATE_PAY',
    'NAME_CLIENT',
    'LAST_NAME',
    'LAST_2_NAME',
  ];
  displayedColumnsExpense: string[] = [
    'ID_EXPENSE',
    'NAME_EMPLOYEE',
    'DATE_EXPENSE',
    'NAME_TYPE_EXPENSE',
    'DESCRIPTION_EXPENSE',
    'VOUCHER',
    'EXPENSE',
  ];
  dataSource = new MatTableDataSource<ReporteIndividual>();
  dataSourceExpense = new MatTableDataSource<Expense>();

  @ViewChild('Pagin1', { static: true }) paginator: MatPaginator;
  @ViewChild('Sort1', { static: true }) sort: MatSort;

  @ViewChild('Pagin2', { static: true }) paginator2: MatPaginator;
  @ViewChild('Sort2', { static: true }) sort2: MatSort;

  constructor(
    private reporteIndividualService: ReporteIndividualService,
    private expenseService: ExpenseService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.formInit();
    this.getUserLogged();
  }
  userIsLogged() {
    return this.authService.getUserLogged();
  }
  getUserLogged() {
    this.usuario = this.userIsLogged();
  }

  formInit() {
    this.individualForm = new UntypedFormGroup({
      initialDateValue: new UntypedFormControl('', Validators.required),
      finalDateValue: new UntypedFormControl('', Validators.required),
    });
  }
  setMinDate() {
    var date = this.individualForm.value.initialDateValue;
    var finalDate = this.individualForm.value.finalDateValue;
    var dateI = new Date(date);
    var dateF = new Date(finalDate);

    if (dateI >= dateF || finalDate == '') {
      this.individualForm.controls.finalDateValue.setValue(date);
    }
    this.minDate = date;
  }

  getIndividualReport() {
    var self = this;
    this.reporte.ID_EMPLOYEE = this.usuario.ID_EMPLOYEE;
    this.reporte.FECHA_INICIAL = this.individualForm.value.initialDateValue.toISOString().substring(0, 10);
    this.reporte.FECHA_FINAL = this.individualForm.value.finalDateValue.toISOString().substring(0, 10);
    this.reporteIndividualService.getIndividualExpense(this.reporte).subscribe((x: any) => {
      this.expense = x;
    });
    this.reporteIndividualService.getIndividualReport(this.reporte).subscribe((x: any) => {
      this.reporteIndividual = x;

      this.reporteIndividual.forEach(function (value) {
        /*if (value.VOUCHER == null) {
          value.VOUCHER = '';
        }*/
      });
    });
    this.reporteIndividualService.getIndividualReportLogs(this.reporte).subscribe((value: any) => {
      self.updateIncome(value);
    });

    this.reporteIndividualService.getExpensesByEmployee(this.reporte).subscribe((x: any) => {
      this.expensesByEmployee = x;
      self.updateExpense(this.expensesByEmployee);
    });
    this.reporte = new ReporteIndividual();
    this.Descripcion_report =
      'Genera un reporte para este colaborador con los ingresos y egresos totales entre la fecha de inicio y la fecha final.';
  }

  updateIncome(value) {
    this.dataSource = new MatTableDataSource(value);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'DATE_PAY':
          var date = new Date(item.DATE_PAY);
          return date;

        default:
          return item[property];
      }
    };
  }

  updateExpense(value) {
    value.forEach(x => {
      if (x.VOUCHER == 'null') {
        x.VOUCHER = '';
      }
    });
    this.dataSourceExpense = new MatTableDataSource(value);
    this.dataSourceExpense.paginator = this.paginator2;
    this.dataSourceExpense.sort = this.sort2;
    this.dataSourceExpense.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'DATE_EXPENSE':
          var date = new Date(item.DATE_EXPENSE);
          return date;
        case 'VOUCHER':
        default:
          return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilterExpense(filterValue: string) {
    this.dataSourceExpense.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceExpense.paginator) {
      this.dataSourceExpense.paginator.firstPage();
    }
  }
}
