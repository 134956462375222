import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Client } from '../client/client';

export class RequestMisc {
  content: any;
  constructor() {}

  static getTotalWorks(factureForm: UntypedFormGroup, clientSelected : Client) {
    const control = <UntypedFormArray>factureForm.get('lines');
    factureForm.controls.totalTrabajo.setValue(0.0);
    factureForm.controls.totalTrabajoImpuesto.setValue(0.0);

    for (var i = 0; i < control.length; i++) {
      var line = control.at(i);
      var cant = line.get('cantidad').value;
      var precio = line.get('precio').value;
      try {
        var acumTotalWork = factureForm.get('totalTrabajo').value + cant * precio;
        var acumTotalWorkImp =
          factureForm.get('totalTrabajoImpuesto').value + cant * precio * (1 + clientSelected.TAX);

        factureForm.controls.totalTrabajo.setValue(acumTotalWork);
        factureForm.controls.totalTrabajoImpuesto.setValue(acumTotalWorkImp);
      } catch (error) {}
    }
  }

  static findInvalidControls(factureForm: UntypedFormGroup) {
    const invalid = [];
    const controls = factureForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  
  static setValidatorsLines(factureForm: UntypedFormGroup) {
    var id = factureForm.value.idTypeWork;
    if(id == null)
    {
      factureForm.controls["idTypeWork"].setValidators([Validators.required]);
      factureForm.controls["idTypeWork"].updateValueAndValidity();
    }
    else{
      if(id.length >0)
      {
        factureForm.controls["idItem"].clearValidators();
        factureForm.controls["idItem"].updateValueAndValidity();
      }
      else
      {
        factureForm.controls["idItem"].setValidators([Validators.required]);
        factureForm.controls["idItem"].updateValueAndValidity();
      }
    }
    
    var id = factureForm.value.idItem;
    if(id == null)
    {
      factureForm.controls["idItem"].setValidators([Validators.required]);
      factureForm.controls["idItem"].updateValueAndValidity();
    }
    else{
      if(id.length >0)
      {
        factureForm.controls["idTypeWork"].clearValidators();
        factureForm.controls["idTypeWork"].updateValueAndValidity();
      }
      else
      {
        factureForm.controls["idTypeWork"].setValidators([Validators.required]);
        factureForm.controls["idTypeWork"].updateValueAndValidity();
      }
    }
  }

  static computeSubTotalLine(index: number, factureForm: UntypedFormGroup, clientSelected : Client)
  {
    const control = <UntypedFormArray>factureForm.get('lines')
    var line = control.at(index)
    var cant = line.get("cantidad").value
    var precio = line.get("precio").value

    try {
      var resul = cant * precio
      line.get("precioSubTotal").setValue(resul);
      line.get("precioSubTotalImpuesto").setValue(resul*(1+clientSelected.TAX));
    } catch (error) {
      line.get("precioSubTotal").setValue(-1);
    }
    
    
    RequestMisc.getTotalWorks(factureForm, clientSelected);
  }

  static onClientChange(factureForm: UntypedFormGroup)
  {
    var idClient : Client = factureForm.value.idClient;
    if(idClient == undefined)
    {
      factureForm.controls.nameClient.setValue("")
      factureForm.controls.lastName.setValue("")
      factureForm.controls.last_2_Name.setValue("")
      factureForm.controls.taxClient.setValue("")
      factureForm.controls.direccionClient.setValue("")
      factureForm.controls.emailClient.setValue("")
      factureForm.controls.phoneClient.setValue("")
      factureForm.controls.regionClient.setValue("")
      factureForm.controls.provinciaClient.setValue("")
      factureForm.controls.cantonClient.setValue("")
      factureForm.controls.distritoClient.setValue("")
    }
    else{
      factureForm.controls.nameClient.setValue(idClient.NAME_CLIENT)
      factureForm.controls.lastName.setValue(idClient.LAST_NAME);
      factureForm.controls.last_2_Name.setValue(idClient.LAST_2_NAME);
      factureForm.controls.taxClient.setValue(idClient.TAX*100)
      factureForm.controls.direccionClient.setValue(idClient.ADDRESS)
      factureForm.controls.emailClient.setValue(idClient.EMAIL)
      factureForm.controls.phoneClient.setValue(idClient.PHONE_NUMBER)
      factureForm.controls.regionClient.setValue(idClient.ID_REGION)
      factureForm.controls.regionClient.setValue(idClient.NAME_REGION)
      factureForm.controls.provinciaClient.setValue(idClient.PROVINCIA)
      factureForm.controls.cantonClient.setValue(idClient.CANTON)
      factureForm.controls.distritoClient.setValue(idClient.DISTRITO)
    }
  }

  static refreshTax(factureForm: UntypedFormGroup, clientSelected : Client){
    const control = <UntypedFormArray>factureForm.get('lines')
    for (let index = 0; index < control.length; index++) {
      RequestMisc.computeSubTotalLine(index, factureForm, clientSelected)
    } 
  }

  static formInit() : UntypedFormGroup {
    var fb: UntypedFormBuilder = new UntypedFormBuilder()
    return new UntypedFormGroup({
      //FORM ZONA
      idZone: new UntypedFormControl(''),
      //FORM CLIENTE
      nameClient: new UntypedFormControl('',Validators.required),
      lastName: new UntypedFormControl(''),
      last_2_Name: new UntypedFormControl(''),
      idClient: new UntypedFormControl([],Validators.required),
      taxClient: new UntypedFormControl(0,Validators.required),
      direccionClient: new UntypedFormControl(''),
      emailClient: new UntypedFormControl(''),
      phoneClient: new UntypedFormControl('',Validators.required),
      regionClient: new UntypedFormControl('',Validators.required),
      provinciaClient: new UntypedFormControl(''),
      cantonClient: new UntypedFormControl(''),
      distritoClient: new UntypedFormControl(''),
      //FORM EMPLEADO
      emailEmployee: new UntypedFormControl(''),
      nameEmployee: new UntypedFormControl('',Validators.required),
      phoneEmployee: new UntypedFormControl('',Validators.required),
      //idTypePayment: new FormControl('',Validators.required),
      idTypeTreasury: new UntypedFormControl([],Validators.required),

      totalTrabajo: new UntypedFormControl(0, Validators.required),
      totalTrabajoImpuesto: new UntypedFormControl(0, Validators.required),
      lastWorksSelected: new UntypedFormControl([]),
      lastItemsSelected: new UntypedFormControl([]),
      typeWorkSeleccionados: new UntypedFormControl([]),
      itemsSeleccionados: new UntypedFormControl([]),
      
      //Comentarios
      comments: new UntypedFormControl(''),

      //Seleccionar trabajos
      idTypeWork: new UntypedFormControl([],Validators.required),
      //Seleccionar Item
      idItem: new UntypedFormControl([],Validators.required),
      //Lineas trabajo
      lines : fb.array([])
      
        });
  }
}
