import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypePaymentService {

  constructor(
    private _http:HttpClient
  ) { }
  getTypePayments(){
    return this._http.get('/Type_Payment')
  }
}