import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { Employee } from 'src/app/employee/employee';
import { RequestEdit } from '../request-edit';
import { EmployeeService } from '../../employee/employee.service';
import { TypeTreasuryService } from '../../type-treasury/type-treasury.service';
import { ClientService } from '../../client/client.service';
import { LineRequestService } from '../../line-request/line-request.service';
import { RequestService } from '../../request/request.service';
import { LineItemService } from '../../line-item/line-item.service';

import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  ValidationErrors,
  UntypedFormArray,
} from '@angular/forms';
import { TypeTreasury } from 'src/app/type-treasury/type-treasury';
import { Item } from 'src/app/item/item';
import { TypeWork } from 'src/app/type-work/type-work';
import { Client } from '../../client/client';
import { LineRequest } from '../../line-request/line-request';
import { Request } from '../../request/request';

import { LineItem } from '../../line-item/line-item';
import { FullRequest } from 'src/app/request/full-request';

import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestMisc } from 'src/app/misc/requestMisc';
@Component({
  selector: 'app-edit-pending',
  templateUrl: './edit-pending.component.html',
  styleUrls: ['./edit-pending.component.css'],
})
export class EditPendingComponent extends Modal implements OnInit {
  @Input() request: RequestEdit;
  @Input() items: Item[];
  @Input() typeWorks: TypeWork[];

  @Output() updateRequestEvent = new EventEmitter();
  @Output() cancelEditEvent = new EventEmitter();

  employee: Employee;
  client: Client;
  factureForm: UntypedFormGroup;

  linesRequest: LineRequest[] = [];
  linesItem: LineItem[] = [];
  Hacienda: boolean;
  renderLastNames: boolean;
  typeTreasury: TypeTreasury[] = [];

  totalTrabajo: number = 0;
  totalTrabajoImpuesto: number = 0;
  isDesktop: boolean = false;
  editarRequest: RequestEdit = new RequestEdit();

  constructor(
    private _clientService: ClientService,
    private _employeeService: EmployeeService,
    private _typeTreasuryService: TypeTreasuryService,
    private _lineRequestService: LineRequestService,
    private _lineItemService: LineItemService,
    private _requestService: RequestService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private elRef: ElementRef,
    private deviceService: DeviceDetectorService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  async ngOnInit() {
    this.getUserLogged();
    this.factureForm = RequestMisc.formInit();
    this.getEmployee();
    this.getInfoDevice();
    this.getInit();
  }

  async getInit() {
    await this.getClient();

    this.factureForm.controls.itemsSeleccionados.setValue([]);
    this.factureForm.controls.typeWorkSeleccionados.setValue([]);
    await this.getLinesWorkFromPending();
    await this.getLinesItemFromPending();
    this.getComments();
    this.factureForm.controls.totalTrabajo.setValue(0.0);
    this.factureForm.controls.totalTrabajoImpuesto.setValue(0.0);
    this.setValidatorsLines();
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  isForHacienda(): boolean {
    var idTreasury = this.factureForm.get('idTypeTreasury').value.ID_TYPE_TREASURY;
    if (idTreasury == 1) {
      this.Hacienda = true;
      return true;
    }
    this.Hacienda = false;
    return false;
  }

  setValidatorsLines() {
    var id = this.factureForm.value.idTypeWork;
    if (id == null) {
      this.factureForm.controls['idTypeWork'].setValidators([Validators.required]);
      this.factureForm.controls['idTypeWork'].updateValueAndValidity();
    } else {
      //Hay mas de un trabajo
      if (id.length > 0) {
        //Deshabilito el requerimiento del form de los items
        this.factureForm.controls['idItem'].clearValidators();
        this.factureForm.controls['idItem'].updateValueAndValidity();
      } else {
        // Si no hay trabajos, habilito el requerimiento del form de los items
        this.factureForm.controls['idItem'].setValidators([Validators.required]);
        this.factureForm.controls['idItem'].updateValueAndValidity();
      }
    }

    var id = this.factureForm.value.idItem;
    if (id == null) {
      this.factureForm.controls['idItem'].setValidators([Validators.required]);
      this.factureForm.controls['idItem'].updateValueAndValidity();
    } else {
      if (id.length > 0) {
        this.factureForm.controls['idTypeWork'].clearValidators();
        this.factureForm.controls['idTypeWork'].updateValueAndValidity();
      } else {
        this.factureForm.controls['idTypeWork'].setValidators([Validators.required]);
        this.factureForm.controls['idTypeWork'].updateValueAndValidity();
      }
    }
  }

  scroll() {
    this.elRef.nativeElement.scrollIntoView();
    // now account for fixed header
    var scrolledY = window.scrollY;

    if (scrolledY) {
      window.scroll(0, scrolledY - 85);
    }
  }

  getControls(frmGrp: UntypedFormGroup, key: string) {
    return (<UntypedFormArray>frmGrp.controls[key]).controls;
  }
  initiatFormItem(item: Item, amount: number): UntypedFormGroup {
    var form = this.fb.group({
      cantidad: new UntypedFormControl({ value: amount }, [Validators.required]),
      precio: new UntypedFormControl({ value: item.PRICE }, [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      trabajo: new UntypedFormControl({ value: item.NAME_ITEM }, Validators.required),
      precioSubTotal: new UntypedFormControl({ value: '0' }, [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      precioSubTotalImpuesto: new UntypedFormControl({ value: '0' }),
      WorkOrItem: new UntypedFormControl({ value: '2' }),
      ID: new UntypedFormControl({ value: '' }),
    });

    form.controls.cantidad.setValue(amount);
    form.controls.precio.setValue(item.PRICE);
    form.controls.trabajo.setValue(item.NAME_ITEM);
    form.controls.precioSubTotal.setValue(item.PRICE * amount);

    form.controls.precioSubTotalImpuesto.setValue(item.PRICE * amount * (1 + this.client.TAX));
    form.controls.WorkOrItem.setValue(2); //Uno para los trabajo
    form.controls.ID.setValue(item.ID_ITEM); //Uno para los trabajo

    return form;
  }

  initiatForm(typeWork: TypeWork, amount: number): UntypedFormGroup {
    var form = this.fb.group({
      cantidad: new UntypedFormControl({ value: amount }, [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      precio: new UntypedFormControl({ value: typeWork.PRICE }, [
        Validators.required,
        Validators.pattern('[1-9][0-9]*'),
      ]),
      trabajo: new UntypedFormControl({ value: typeWork.NAME_TYPE_WORK }, Validators.required),
      precioSubTotal: new UntypedFormControl({ value: '0' }, [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      precioSubTotalImpuesto: new UntypedFormControl({ value: '0' }),
      WorkOrItem: new UntypedFormControl({ value: '1' }),
      ID: new UntypedFormControl({ value: '' }),
    });
    form.controls.cantidad.setValue(amount);
    form.controls.precio.setValue(typeWork.PRICE);
    form.controls.trabajo.setValue(typeWork.NAME_TYPE_WORK);
    form.controls.precioSubTotal.setValue(typeWork.PRICE * amount);

    form.controls.precioSubTotalImpuesto.setValue(typeWork.PRICE * amount * (1 + this.client.TAX));
    form.controls.WorkOrItem.setValue(1); //Uno para los trabajo
    form.controls.ID.setValue(typeWork.ID_TYPE_WORK); //Uno para los trabajo
    return form;
  }

  addLineForm(typeWork: TypeWork, amount = 1) {
    this.setValidatorsLines();
    const control = <UntypedFormArray>this.factureForm.get('lines');
    control.push(this.initiatForm(typeWork, amount));

    RequestMisc.getTotalWorks(this.factureForm, this.client);
    this.findInvalidControls();
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.factureForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  addLineFormItem(item: Item, amount = 1) {
    this.setValidatorsLines();
    const control = <UntypedFormArray>this.factureForm.get('lines');
    control.push(this.initiatFormItem(item, amount));
    RequestMisc.getTotalWorks(this.factureForm, this.client);
    this.findInvalidControls();
  }
  async getClient() {
    //Do request
    var client: Client = new Client();
    client.ID_CLIENT = this.request.ID_CLIENT;
    //Await for the client response
    var realClient = await this._clientService.getClient(client).toPromise();
    this.client = realClient[0];
    this.factureForm.controls.idClient.setValue(this.client);
    RequestMisc.onClientChange(this.factureForm);
    if (this.client.JURIDICA) {
      this.renderLastNames = false;
    } else {
      this.renderLastNames = true;
    }
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }

  getUserLogged() {
    this.employee = this.userIsLogged();
  }
  getEmployee() {
    var employee: Employee = new Employee();

    employee.ID_EMPLOYEE = this.request.ID_EMPLOYEES;
    this._employeeService.getEmployee(employee).subscribe(employee => {
      this.employee = employee[0];
      this.factureForm.controls.emailEmployee.setValue(this.employee.EMAIL);
      this.factureForm.controls.nameEmployee.setValue(this.employee.NAME_EMPLOYEE);
      this.factureForm.controls.phoneEmployee.setValue(this.employee.PHONE);
    });
  }

  getComments() {
    this.factureForm.controls.comments.setValue(this.request.COMMENTS);
  }

  async getLinesWorkFromPending() {
    //Reset the controls for works

    var request: Request = new Request();
    request.ID_REQUEST = this.request.ID_REQUEST;

    this._lineRequestService.getLines(request).subscribe((lines: any) => {
      this.linesRequest = lines;
      var ID_WORKS: number[] = [];
      var WORKS: TypeWork[] = [];
      for (var i = 0; i < this.linesRequest.length; i++) {
        var work: TypeWork;
        work = this.typeWorks.find(element => element.ID_TYPE_WORK == this.linesRequest[i].ID_TYPE_WORK);

        work.PRICE = this.linesRequest[i].PRICE_REQUEST;
        ID_WORKS.push(work.ID_TYPE_WORK);
        WORKS.push(work);
      }
      this.factureForm.controls.idTypeWork.setValue(ID_WORKS);
      this.factureForm.controls.typeWorkSeleccionados.setValue(WORKS);

      for (var i = 0; i < this.linesRequest.length; i++) {
        this.addLineForm(this.factureForm.controls.typeWorkSeleccionados.value[i], this.linesRequest[i].AMOUNT_REQUEST);
      }
    });
  }

  async getLinesItemFromPending() {
    var request: Request = new Request();
    request.ID_REQUEST = this.request.ID_REQUEST;
    this._lineItemService.getLines(request).subscribe((lines: any) => {
      this.linesItem = lines;
      var ID_ITEMS: number[] = [];
      var ITEMS: Item[] = [];
      for (var i = 0; i < this.linesItem.length; i++) {
        var item: Item;
        item = this.items.find(element => element.ID_ITEM == this.linesItem[i].ID_ITEM);
        item.ID_ITEM = this.linesItem[i].ID_ITEM;
        item.PRICE = this.linesItem[i].PRICE_BY_UNIT;
        ID_ITEMS.push(item.ID_ITEM);
        ITEMS.push(item);
      }
      this.factureForm.controls.idItem.setValue(ID_ITEMS);
      this.factureForm.controls.itemsSeleccionados.setValue(ITEMS);
      for (var i = 0; i < this.linesItem.length; i++) {
        this.addLineFormItem(this.factureForm.controls.itemsSeleccionados.value[i], this.linesItem[i].AMOUNT);
      }
    });
  }
  //Identifier of an object, type: 1 for work 2 for item
  removeLineForm(index: number, type: number) {
    this.setValidatorsLines();
    const control = <UntypedFormArray>this.factureForm.get('lines');

    for (var i = 0; i < control.length; i++) {
      var line = control.at(i);
      var WorkOrItem = line.get('WorkOrItem').value;
      if (WorkOrItem == type) {
        var ID = line.get('ID').value;
        if (ID == index) {
          control.removeAt(i);
          RequestMisc.getTotalWorks(this.factureForm, this.client);
          return;
        }
      }
    }
  }

  /**
   * Used by the UI to delete some line
   * @param index Index number of the line to be removed
   */
  removeLineButton(index: number) {
    const lineSelected = <UntypedFormArray>this.factureForm.get('lines');
    var type = lineSelected.at(index).get('WorkOrItem').value;
    if (type == 1) {
      var currentlySelected = this.factureForm.value.idTypeWork;
      var idToBeDeleted = lineSelected.at(index).get('ID').value;

      //Remove from typeWorkSeleccionado
      var idx = this.factureForm.controls.typeWorkSeleccionados.value.findIndex(x => x.ID_TYPE_WORK == idToBeDeleted);
      this.factureForm.controls.typeWorkSeleccionados.value.splice(idx, 1);

      //Remove from mat-select
      var idxCurrently = currentlySelected.findIndex(x => x == idToBeDeleted);
      currentlySelected.splice(idxCurrently, 1);
      this.factureForm.controls.idTypeWork.setValue(currentlySelected);

      //Remove line from view
      this.removeLineForm(idToBeDeleted, type);
      this.closeModal();
    } else {
      var currentlySelected = this.factureForm.value.idItem;
      var idToBeDeleted = lineSelected.at(index).get('ID').value;

      //Remove from itemSeleccionado
      var idx = this.factureForm.controls.itemsSeleccionados.value.findIndex(x => x.ID_ITEM == idToBeDeleted);
      this.factureForm.controls.itemsSeleccionados.value.splice(idx, 1);

      //Remove from mat-select
      var idxCurrently = currentlySelected.findIndex(x => x == idToBeDeleted);
      currentlySelected.splice(idxCurrently, 1);
      this.factureForm.controls.idItem.setValue(currentlySelected);

      //Remove line from view
      this.removeLineForm(idToBeDeleted, type);
      this.closeModal();
    }
  }

  computeSubTotalLine(index: number) {
    RequestMisc.computeSubTotalLine(index, this.factureForm, this.client);
  }

  extractGeneralInformationFromForm(newRequest: FullRequest) {
    newRequest.ID_REQUEST = this.request.ID_REQUEST;
    newRequest.ID_TYPE_PROCESSING = this.request.ID_TYPE_PROCESSING;
    //REQUEST
    newRequest.ID_CLIENT = this.factureForm.get('idClient').value.ID_CLIENT;
    newRequest.COMMENTS = this.factureForm.get('comments').value;
    newRequest.ID_EMPLOYEE = this.employee.ID_EMPLOYEE;
    newRequest.ID_TYPE_STATE_REQUEST = this.request.ID_TYPE_STATE_REQUEST;
    newRequest.ID_REQUEST_STATUS = this.request.ID_REQUEST_STATUS;
    newRequest.ID_TYPE_TREASURY = this.factureForm.get('idTypeTreasury').value.ID_TYPE_TREASURY;
    newRequest.TOTAL_PRICE = this.factureForm.get('totalTrabajo').value;
    if (this.isForHacienda()) {
      //1 es de hacienda
      newRequest.TAX = this.client.TAX;
      newRequest.TOTAL_PRICE = this.factureForm.get('totalTrabajoImpuesto').value;
    } else {
      newRequest.TAX = 0;
    }

    //TODO: Check this
    newRequest.ID_TYPE_COLLECTABLE = 1;
  }

  extractLinesFromForm(newRequest: FullRequest): LineRequest[] {
    const control = <UntypedFormArray>this.factureForm.get('lines');
    var linesRequestExist: LineRequest[] = [];
    //Almaceno los tipos de trabajo que deben estar incluidos en la base de datos. (Es decir los reales)
    var linesRequestReal: LineRequest[] = [];

    var linesItemsExist: LineItem[] = [];
    var linesItemsReal: LineItem[] = [];

    //Para todos los que están en la vista del panel
    for (var i = 0; i < control.length; i++) {
      var line = control.at(i);
      var cant = line.get('cantidad').value;
      var precio = line.get('precio').value;
      var WorkOrItem = line.get('WorkOrItem').value;
      var ID = line.get('ID').value;
      var linePending = new LineItem();
      linePending.ID_ITEM = ID;
      linePending.AMOUNT = cant;
      linePending.PRICE_BY_UNIT = precio;
      //ID para nuevos
      linePending.ID_LINE_ITEM = -1;
      if (WorkOrItem == 1) {
        //Linea del request previo, si encuentra una es por que este tipo de
        // trabajo se mantiene aún seleccionado
        var lineRequest: LineRequest = this.linesRequest.find(x => x.ID_TYPE_WORK === ID);
        if (lineRequest != undefined) {
          //Todavia se mantienen
          linePending.ID_LINE_ITEM = lineRequest.ID_LINE_REQUEST;
          linesRequestExist.push(lineRequest);
          //Lo agrego a la lista de trabajos reales por que se mantiene
          linesRequestReal.push(lineRequest);
        } else {
          //Se creo un registro de tipo de trabajo nuevo, que debe agregarse.
          lineRequest = new LineRequest();
          lineRequest.ID_REQUEST = this.request.ID_REQUEST;
          lineRequest.AMOUNT_REQUEST = cant;
          lineRequest.ID_TYPE_WORK = ID;
          lineRequest.PRICE_REQUEST = precio;

          //Lo agrego a la lista de trabajos reales por que es nuevo
          linesRequestReal.push(lineRequest);
        }
        //Meto los que se mantiene o nuevos al nuevo request
        newRequest.LINES_TYPEWORK.push(linePending);
      } else {
        var lineItem: LineItem = this.linesItem.find(x => x.ID_ITEM === ID);
        if (lineItem != undefined) {
          //Todavia se mantienen
          linePending.ID_LINE_ITEM = lineItem.ID_LINE_ITEM;
          linesItemsExist.push(lineItem);
          linesItemsReal.push(lineItem);
        } else {
          lineItem = new LineItem();
          lineItem.ID_REQUEST = this.request.ID_REQUEST;
          lineItem.AMOUNT = cant;
          lineItem.ID_ITEM = ID;
          lineItem.PRICE_BY_UNIT = precio;
          linesItemsReal.push(lineItem);
        }
        newRequest.LINES_ITEM.push(linePending);
      }
    }

    //LinesRequest tiene todas las lines que estaban asociadas al request en la base de datos
    this.linesRequest.forEach(element => {
      var index = this.factureForm.controls.typeWorkSeleccionados.value.findIndex(
        x => x.ID_TYPE_WORK == element.ID_TYPE_WORK
      );
      if (index < 0) {
        var linePending = new LineItem();
        linePending.ID_LINE_ITEM = element.ID_LINE_REQUEST;
        linePending.DELETED = true;
        newRequest.LINES_TYPEWORK.push(linePending);
      }
    });

    this.linesItem.forEach(element => {
      var index = this.factureForm.controls.itemsSeleccionados.value.findIndex(x => x.ID_ITEM == element.ID_ITEM);
      if (index < 0) {
        var linePending = new LineItem();
        linePending.ID_LINE_ITEM = element.ID_LINE_ITEM;
        linePending.DELETED = true;
        newRequest.LINES_ITEM.push(linePending);
      }
    });

    return linesRequestReal;
  }

  update() {
    Swal.fire({
      icon: 'info',
      title: 'Actualizando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    var newRequest = new FullRequest();
    this.extractGeneralInformationFromForm(newRequest);
    var linesRequestReal: LineRequest[] = this.extractLinesFromForm(newRequest);

    var self = this;
    this._requestService.updateRequest(newRequest).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la actualización de la factura',
          footer: '<a>El monto solicitado de alguno articulos es superior al solicitado actualmente.</a>',
        });
        self.ngOnInit();
      } else {
        var req = new RequestEdit();
        req.ID_REQUEST = -1;
        self.updateRequestEvent.emit(req);

        var request = new Request();
        request.ID_REQUEST = newRequest.ID_REQUEST;
        var newLines: LineRequest[] = [];
        self._lineRequestService.getLines(request).subscribe((lines: any) => {
          newLines = lines;
          newLines.forEach(element => {
            //TODO: CHECK THIS
            linesRequestReal.find(x => x.ID_TYPE_WORK == element.ID_TYPE_WORK).ID_LINE_REQUEST =
              element.ID_LINE_REQUEST;
          });
        });
        self.linesRequest = linesRequestReal;
        self.popUpUpdate();
      }
    });
  }

  popUpUpdate() {
    Swal.fire({
      icon: 'success',
      title: 'Actualización realizada',
      text: 'Se realizó la actualización de la factura',
      confirmButtonText: 'Aceptar',
    });
  }
  cancel() {
    this.cancelEditEvent.emit();
  }
}
