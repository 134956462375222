import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TypeItem } from '../type-item';
import { TypeItemService } from '../type-item.service';

@Component({
  selector: 'app-type-item-new',
  templateUrl: './type-item-new.component.html',
  styleUrls: ['./type-item-new.component.css']
})
export class TypeItemNewComponent implements OnInit {
  @Output() updateTypeItem = new EventEmitter();
  newTypeItem = new TypeItem;
  form = new UntypedFormGroup({
    tipoItem: new UntypedFormControl('',Validators.required)
  })
  constructor(
    private _typeItemService : TypeItemService
  ) { }

  ngOnInit() {
    this.markFormGroupTouched(this.form)
  }
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  create(){
    Swal.fire({
      icon: 'info',
      title: "Creando tipo de producto...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.newTypeItem.NAME_TYPE_ITEM = this.form.value.tipoItem
    var self = this;
    this._typeItemService.create(this.newTypeItem)
    .subscribe(function (value){
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción. Nombre de tipo de item duplicado'
        })
      }else{
        self.popUpCreate()
        self.form.reset();
        self.newTypeItem = new TypeItem;
        self.updateTypeItem.emit()
      }
    })
  }

  popUpCreate(){
    Swal.fire({
      icon: 'success',
      title: 'Tipo de item agregado',
      text: 'El tipo de item fué agregado correctamente'
    })    
  }

}
