export class Expense {
    constructor(
        public ID_EXPENSE: number = 0,
        public ID_EMPLOYEES: number = 0,
        public ID_TYPE_EXPENSE: number = 0,
        public DATE_EXPENSE: string = "",
        public DESCRIPTION_EXPENSE: string = "",
        public EXPENSE: number = 0,
        public VOUCHER: string = "",
        public FECHA_TABLA: string = "",
        public EMPLEADO_TABLA : string = "",
        public TIPO_GASTO_TABLA : string = ""

    ){
    }
}
