import { Component, OnInit, ViewChild, Input, SimpleChanges, Output } from '@angular/core';
import {TypeWorkComponent} from '../type-work.component'
import {TypeWorkService} from '../type-work.service'
import {TypeWork} from '../type-work'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import Swal from 'sweetalert2';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tabla-type-work',
  templateUrl: './tabla-type-work.component.html',
  styleUrls: ['./tabla-type-work.component.css']
})

export class TablaTypeWorkComponent extends Modal implements OnInit {
  @Input() typesWorks : TypeWork[];
  public typeWorkSeleccionado : TypeWork;
  public editable : Boolean = false;
  displayedColumns: string[] = ['ID_TYPE_WORK','NAME_TYPE_WORK','DESCRIPTION','PRICE','OPCIONES'];
  dataSource = new MatTableDataSource<TypeWork>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private typeWorkComponent : TypeWorkComponent,
    private typeWorkService: TypeWorkService, private modalServiceExt: NgbModal
  ) { super(modalServiceExt);}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.typesWorks);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
}

  editar(typeWork:TypeWork){
    this.typeWorkSeleccionado = typeWork
    this.editable = true;
  }
  
  eliminarTypeWork(nombre:TypeWork)
  {
    Swal.fire({
      icon: 'info',
      title: "Eliminando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var self = this;
    this.typeWorkService.destroy(nombre)
    .subscribe(function (value) {
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al eliminar'
        })
      }else{
        if (value == 501){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No es posible eliminar este producto. Ya se encuentra asociado a una orden de trabajo.'
          })
        }else{
           self.popUpDelete()
        }
      }
    });
  }
  popUpDelete(){
    Swal.fire({
      icon: 'success',
      title: 'Tipo de trabajo eliminado',
      text: 'El tipo de trabajo fué eliminado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      this.getTypesWork()
    })
  }


  getTypesWork()
  {
    var self = this;
    this.typeWorkService.getTypesWork()
    .subscribe((x:any) => {
      self.typesWorks = x;
      self.dataSource = new MatTableDataSource(self.typesWorks);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
