import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import {TypeTreasury} from '../type-treasury'
@Component({
  selector: 'app-type-treasury-edit',
  templateUrl: './type-treasury-edit.component.html',
  styleUrls: ['./type-treasury-edit.component.css']
})
export class TypeTreasuryEditComponent implements OnInit {
  @Input() typesTreasure : TypeTreasury;
  @Output() updateTypeTreasuryEvent = new EventEmitter();
  editTypeTreasure : TypeTreasury = new TypeTreasury;
  constructor() { }

  ngOnInit() {
    Object.assign(this.editTypeTreasure,this.typesTreasure)
  }
  update(){
    this.editTypeTreasure.editable = false;
    this.updateTypeTreasuryEvent.emit(this.editTypeTreasure);
  }
}
