import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Region } from '../region';
import {} from '../region.component'
import {UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { RegionService } from '../region.service';

@Component({
  selector: 'app-region-edit',
  templateUrl: './region-edit.component.html',
  styleUrls: ['./region-edit.component.css']
})
export class RegionEditComponent implements OnInit {
  @Input() region : Region;
  @Output() updateRegion = new EventEmitter();
  form = new UntypedFormGroup({
    nombre: new UntypedFormControl('',Validators.required)
  });
  editRegion: Region = new Region;

  constructor(
    private _regionService : RegionService
  ) { 

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }

  ngOnInit() {
    this.clone();
    this.markFormGroupTouched(this.form)
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  clone()
  {
    Object.assign(this.editRegion,this.region);
    this.form.controls.nombre.setValue(this.editRegion.NAME_REGION)

  }
  update(){
    Swal.fire({
      icon: 'info',
      title: "Actualizando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.editRegion.NAME_REGION = this.form.value.nombre
    this.editRegion.editable = false;
    var self = this;
    this._regionService.update(this.editRegion)
    .subscribe(function (value) {
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la edición. Nombre duplicado'
        })
      }else{
        self.popUpUpdate()
      }
    });
  }

  habilitar(){
    Swal.fire({
      icon: 'info',
      title: "Habilitando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.editRegion.ID_TYPE_AVAILABLE = 1;
    var self = this;
    this._regionService.update(this.editRegion)
    .subscribe(function (value){
      if(value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del tipo de trabajo'
        })
      }else{
        self.updateHabilitar()
      }
    })
  }

  deshabilitar(){
    Swal.fire({
      icon: 'info',
      title: "Deshabilitando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading()
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.editRegion.ID_TYPE_AVAILABLE = 2;
    var self = this;
    this._regionService.update(this.editRegion)
    .subscribe(function (value){
      if(value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado de la zona'
        })
      }else{
        self.updateDeshabilitar()
      }
    })
  }

  updateDeshabilitar(){
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'La zona deshabilitada.',
      text: 'La zona fué deshabilitada',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      self.updateRegion.emit()
    })
  }

  updateHabilitar(){
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'La zona habilitada.',
      text: 'La zona fué habilitada',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      self.updateRegion.emit()
    })
  }

  popUpUpdate(){
    Swal.fire({
      icon: 'success',
      title: 'Tipo de item actualizado',
      text: 'El tipo de item fué actualizado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      this.updateRegion.emit()
    })
  }

}
