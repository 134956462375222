import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Expense } from '../expense';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-expense-info',
  templateUrl: './expense-info.component.html',
  styleUrls: ['./expense-info.component.css'],
})
export class ExpenseInfoComponent implements OnInit {
  @Input() expense: Expense;

  maxDate: string;

  constructor(private datePipe: DatePipe) {}
  form = new UntypedFormGroup({
    id_employee: new UntypedFormControl('', Validators.required),
    id_type_expense: new UntypedFormControl('', Validators.required),
    date_expense: new UntypedFormControl('', Validators.required),
    voucher: new UntypedFormControl(''),
    description_expense: new UntypedFormControl('', [Validators.required]),
    expense: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
  });
  ngOnInit(): void {
    this.setForm();
  }
  setForm() {
    this.form.controls.id_employee.setValue(this.expense.ID_EMPLOYEES);
    this.form.controls.id_type_expense.setValue(this.expense.TIPO_GASTO_TABLA);
    var date = this.datePipe.transform(new Date(this.expense.DATE_EXPENSE), 'yyyy-MM-dd');
    this.form.controls.date_expense.setValue(date);
    this.maxDate = date;
    this.form.controls.voucher.setValue(this.expense.VOUCHER);
    this.form.controls.description_expense.setValue(this.expense.DESCRIPTION_EXPENSE);
    this.form.controls.expense.setValue(this.expense.EXPENSE);
  }
}
