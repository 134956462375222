import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Employee } from '../employee/employee';
import { RequestEdit } from './request-edit';
@Injectable({
  providedIn: 'root',
})
export class RequestEditService {
  constructor(private _http: HttpClient) {}

  getRequestPending(employee: Employee) {
    return this._http.post('/requestPending', employee);
  }

  without(request: RequestEdit) {
    return this._http.put('/requestWithoutProcessingEdit', request);
  }

  getWithoutProcessing(employee: Employee) {
    return this._http.post('/requestWithoutProcessing', employee);
  }
}
