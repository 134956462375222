import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CostaRicaService {

  constructor(private http:HttpClient) { }

  getProvincias(){
    var baseURL = environment.crURL+ '/provincias.json';
    return this.http.get(baseURL,{headers:{skip:"true"}});
  }
  getCantones(provincia : number){
    var baseURL = environment.crURL+'/provincia/'+provincia+'/cantones.json';
    return this.http.get(baseURL,{headers:{skip:"true"}});
  }
  getDistritos(provincia : number, canton : number){
    var baseURL = environment.crURL+'/provincia/'+provincia+'/canton/'+canton+'/distritos.json';
    return this.http.get(baseURL,{headers:{skip:"true"}});
  }
}
