import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import {Client} from './client'


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private _http:HttpClient
  ) { }

  create(client: Client){
    return this._http.post('/client',client)
  }

  update(client: Client){
    return this._http.put('/client' ,client)
  }

  getClients(){
    return this._http.get('/client')
  }

  getClient(client: Client){
    return this._http.post('/clientByID', client)
  }


}
