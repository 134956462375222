import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Comment} from '../comment'

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.css']
})
export class CommentListComponent implements OnInit {
@Input() comentarios: Comment[]
@Output() updateCommentEvent = new EventEmitter()
@Output() destroyCommentEvent = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  update(comment:Comment)
  {
    this.updateCommentEvent.emit(comment)
  }
  destroy(comment:Comment)
  {
    const response = confirm('delete?')
    if(response){
      this.destroyCommentEvent.emit(comment);
    }
  }

}
