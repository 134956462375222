import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {RegionService} from '../region.service'
import { Region } from '../region';
import {UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-region-new',
  templateUrl: './region-new.component.html',
  styleUrls: ['./region-new.component.css']
})
export class RegionNewComponent implements OnInit {
  newRegion = new Region;
  @Output() updateRegion = new EventEmitter();
  form = new UntypedFormGroup({
    nombre: new UntypedFormControl('',Validators.required)
  });
  constructor(
    private regionService : RegionService
  ) { }

  ngOnInit() {
    this.markFormGroupTouched(this.form)
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  create(){
    Swal.fire({
      icon: 'info',
      title: "Creando región...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var self = this;
    this.newRegion.NAME_REGION = this.form.value.nombre
    this.regionService.create(this.newRegion)
    .subscribe(value => {
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción. Nombre de ruta duplicado'
        })
      }else{
        self.popUpCreate()
        self.form.reset();
        self.newRegion = new Region;
        self.updateRegion.emit()
      }
    })
  }

  popUpCreate(){
    Swal.fire({
      icon: 'success',
      title: 'Ruta agregada',
      text: 'La ruta fué agregada correctamente'
    })    
  }
  

}
