import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-type-state-request-details',
  templateUrl: './type-state-request-details.component.html',
  styleUrls: ['./type-state-request-details.component.css']
})
export class TYPESTATEREQUESTDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
