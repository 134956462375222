import { Component, OnInit, ViewChild, Input, SimpleChanges, Output } from '@angular/core';
import { TypeExpense } from '../type-expense'
import { TypeExpenseComponent } from '../type-expense.component'
import { TypeExpenseService } from '../type-expense.service'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tabla-type-expense',
  templateUrl: './tabla-type-expense.component.html',
  styleUrls: ['./tabla-type-expense.component.css']
})

export class TablaTypeExpenseComponent extends Modal implements OnInit {
  @Input() typeExpenses: TypeExpense[];
  public typeExpenseSeleccionado: TypeExpense;
  public editable : Boolean = false;
  displayedColumns: string[] = ['ID_TYPE_EXPENSE', 'NAME_TYPE_EXPENSE','DESCRIPTION', 'OPCIONES'];
  dataSource = new MatTableDataSource<TypeExpense>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private typeExpenseService: TypeExpenseService,
    private typeExpenseComponent: TypeExpenseComponent,

    private modalServiceExt: NgbModal
  ) {super(modalServiceExt) }

  ngOnInit() {
    this.getTypeExpenses();
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.typeExpenses);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
  }

  editar(typeExpense: TypeExpense) {
    this.typeExpenseSeleccionado = typeExpense;
    this.editable = true;
  }

  

  eliminarTypeExpense(nombre: TypeExpense) {
    Swal.fire({
      icon: 'info',
      title: "Eliminando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading()
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var self = this
    this.typeExpenseService.destroy(nombre)
    .subscribe(function (value) {
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al eliminar'
        })
      }else{
        if (value == 501){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No es posible eliminar este tipo de gasto. Ya se encuentra asociado a un gasto.'
          })
        }else{
          self.popUpDelete()
        }
      }
    });
  }
  popUpDelete(){
    Swal.fire({
      icon: 'success',
      title: 'Tipo de trabajo eliminado',
      text: 'El tipo de trabajo fué eliminado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      this.getTypeExpenses()
    })
  }

  getTypeExpenses() {
    var self = this;
    this.typeExpenseService.getTypeExpenses()
      .subscribe((x : any) => {
        self.typeExpenses = x;
        self.dataSource = new MatTableDataSource(self.typeExpenses);
        self.dataSource.paginator = self.paginator;
        self.dataSource.sort = self.sort;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
