import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { RequestEdit } from './request-edit';
import { RequestEditService } from './request-edit.service';
import { TypeWorkService } from 'src/app/type-work/type-work.service';
import { ItemService } from 'src/app/item/item.service';
import { Item } from '../item/item';
import { TypeWork } from '../type-work/type-work';
import { EmployeeService } from '../employee/employee.service';
import { AuthService } from '../services/auth.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EditPendingComponent } from './edit-pending/edit-pending.component';
import { SearchComponent } from './search/search.component';

@Component({
  selector: 'app-request-edit',
  templateUrl: './request-edit.component.html',
  styleUrls: ['./request-edit.component.css'],
})
export class RequestEditComponent implements OnInit {
  requestsPending: RequestEdit[] = [];
  requestsWithoutProcessing: RequestEdit[] = [];
  editSeleccionado: RequestEdit;
  @ViewChildren('editView') childrenComponent: QueryList<EditPendingComponent>;
  @ViewChildren('searchView') searchChildrenComponent: SearchComponent;

  items: Item[] = [];
  typeWorks: TypeWork[] = [];
  edit: boolean = false;
  idRequest: number = -1;

  form = new UntypedFormGroup({
    processing: new UntypedFormControl(true, Validators.required),
  });

  constructor(
    private _typeWorkService: TypeWorkService,
    private _itemsService: ItemService,
    private requestEditService: RequestEditService,
    private employeeService: EmployeeService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getRequestsPending();
    this.getRequestsWithoutProcessing();
    this.getItems();
    this.getTypeWorks();
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }

  getRequestsPending() {
    this.requestEditService.getRequestPending(this.userIsLogged()).subscribe((requestsPending: any) => {
      this.requestsPending = requestsPending;
    });
  }

  getRequestsWithoutProcessing() {
    this.requestEditService
      .getWithoutProcessing(this.userIsLogged())
      .subscribe((requestsWithoutProcessing: any) => (this.requestsWithoutProcessing = requestsWithoutProcessing));
  }

  getTypeWorks() {
    this._typeWorkService.getTypesWork().subscribe((x: any) => {
      this.typeWorks = x;
      this.typeWorks.forEach(element => {
        if (element.ID_TYPE_AVAILABLE == 2) {
          element.disabled = true;
        }
      });
    });
  }
  getItems() {
    this._itemsService.getItems().subscribe((x: any) => {
      this.items = x;
      this.items.forEach(element => {
        if (element.ID_TYPE_AVAILABLE == 2) {
          element.disabled = true;
        }
      });
    });
  }

  editRequest(request: RequestEdit) {
    this.edit = true;
    this.idRequest = request.ID_REQUEST;
    this.editSeleccionado = request;
    this.childrenComponent.changes.subscribe((comps: QueryList<EditPendingComponent>) => {
      if (comps.last != undefined) {
        comps.last.scroll();
      }
    });
    this.childrenComponent.notifyOnChanges();
  }

  cancelEdit() {
    this.edit = false;
    this.editSeleccionado = null;
    this.idRequest = -1;
  }

  update(request: RequestEdit) {
    if (request.ID_REQUEST == -1) {
      this.getRequestsPending();
    }
  }
}
