import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeExpense } from '../type-expense';

@Component({
  selector: 'app-type-expense-list',
  templateUrl: './type-expense-list.component.html',
  styleUrls: ['./type-expense-list.component.css']
})
export class TypeExpenseListComponent implements OnInit {
  @Input() typeExpenses: TypeExpense[];
  @Output() destroyTypeExpenseEvent = new EventEmitter();
  @Output() updateTypeExpenseEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  destroy(typeExpense:TypeExpense){
    const response = confirm('delete?')
    if(response){
      this.destroyTypeExpenseEvent.emit(typeExpense);
    }
  }

  update(typeExpense:TypeExpense){
    this.updateTypeExpenseEvent.emit(typeExpense);
  }
}
