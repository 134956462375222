export class Employee {
  constructor(
    public ID_EMPLOYEE: number = 0,
    public ID_TYPE_EMPLOYEE: number = 0,
    public ID_TYPE_AVAILABLE: number = 0,
    public USERNAME: string = '',
    public PWD: string = '',
    public NAME_EMPLOYEE: string = '',
    public PHONE: string = '',
    public EMAIL: string = '',
    public ADDRESS: string = '',
    public NAME_TYPE_EMPLOYEE: string = '',
    public TYPE_AVAILABLE_DESC: string = '',
    public DESCRIPTION: string = '',
    public editable: boolean = false,
    public editPWD: boolean = false
  ) {}
}
