import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TypeItemComponent } from '../type-item.component';
import { TypeItemService } from '../type-item.service';
import { TypeItem } from '../type-item';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tabla-type-item',
  templateUrl: './tabla-type-item.component.html',
  styleUrls: ['./tabla-type-item.component.css'],
})
export class TablaTypeItemComponent extends Modal implements OnInit {
  @Input() typeItems: TypeItem[];
  public typeItemSeleccionado: TypeItem;
  public editable: Boolean = false;
  displayedColumns: string[] = ['ID_TYPE_ITEM', 'NAME_TYPE_ITEM', 'OPCIONES'];
  dataSource = new MatTableDataSource<TypeItem>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private typeItemService: TypeItemService,
    private typeItemComponent: TypeItemComponent,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  ngOnInit() {
    this.getTypeItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.typeItems);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
  }

  editar(typeItem: TypeItem) {
    this.typeItemSeleccionado = typeItem;
    this.editable = true;
  }

  eliminarTypeItem(nombre: TypeItem) {
    Swal.fire({
      icon: 'info',
      title: 'Eliminando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    var self = this;
    this.typeItemService.destroy(nombre).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al eliminar',
        });
      } else {
        if (value == 501) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No es posible eliminar este tipo de producto. Ya se encuentra asociado a un producto.',
          });
        } else {
          self.popUpDelete();
        }
      }
    });
  }
  popUpDelete() {
    Swal.fire({
      icon: 'success',
      title: 'Tipo de item eliminado',
      text: 'El tipo de item fué eliminado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      this.getTypeItems();
    });
  }

  getTypeItems() {
    var self = this;
    this.typeItemService.getTypeItems().subscribe((x: any) => {
      self.typeItems = x;
      self.dataSource = new MatTableDataSource(self.typeItems);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
