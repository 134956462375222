import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Report } from 'src/app/report/report';
import { ReportService } from 'src/app/report/report.service';
import { RequestEdit } from 'src/app/request-edit/request-edit';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { RequestDisplayService } from '../request-display.service';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-request-waiting',
  templateUrl: './request-waiting.component.html',
  styleUrls: ['./request-waiting.component.css']
})
export class RequestWaitingComponent extends Modal implements OnInit {
  reportsHistory : Report[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['ID_REQUEST','NAME_CLIENT','LAST_NAME','LAST_2_NAME', 'NAME_REGION' , 'TOTAL_PRICE', 'DATE_REQUEST', 'OPCIONES'];
  dataSource = new MatTableDataSource<Report>();
  
  constructor(
    private _reportService: ReportService,
    private _requestDisplayService : RequestDisplayService,
    private authService : AuthService, private modalServiceExt: NgbModal
    ){super(modalServiceExt)}

  ngOnInit() {
    this.getRequestNotReady();
  }

  userIsLogged(){
    return this.authService.getUserLogged()
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.reportsHistory);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
    self.dataSource.sortingDataAccessor = (item, property) =>{
      switch(property){
        case 'DATE_REQUEST': 
        var date = new Date(item.DATE_REQUEST)
        return date
        
        default: 
          
          return item[property];
      }
    };
  }

  getRequestNotReady(){
    var self = this;
    this._requestDisplayService.getRequestNotReady(this.userIsLogged())
    .subscribe((x : any) => {
      self.reportsHistory = x;
      self.reportsHistory.forEach(element => {
        var reportSend = new Report();
          reportSend.ID_REQUESTP = element.ID_REQUEST;
          element.TOTAL_WITHOUT_TAX = 0;
          self._reportService.getReport7(reportSend).subscribe(xv => {element.lines = xv
            element.lines.forEach(line => {
              element.TOTAL_WITHOUT_TAX += line.TOTAL_BEFORE_TAX
            });
          });
      })
      
      self.dataSource = new MatTableDataSource(self.reportsHistory);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
      self.dataSource.sortingDataAccessor = (item, property) =>{
        switch(property){
          case 'DATE_REQUEST': 
          var date = new Date(item.DATE_REQUEST)
          return date
          
          default: 
            
            return item[property];
        }
      };

    })
  }

  popUpReady(){
    Swal.fire({
      icon: 'success',
      title: 'Orden de trabajo actualizada',
      text: 'La orden de trabajo fué actualizada con éxito'
    })
  }
  
  ready(request : RequestEdit){
    request.ID_TYPE_STATE_READY = 2
    Swal.fire({
      icon: 'info',
      title: "Actualizando orden...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var self = this;
    this._requestDisplayService.ready(request)
    .subscribe((x:any) => {
      if(x['Response'] == 200){
        self.getRequestNotReady();
        self.popUpReady()
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
