import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Client } from '../client';
import { Employee } from '../../employee/employee';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RegionService } from 'src/app/region/region.service';
import { Region } from 'src/app/region/region';
import { Console } from 'console';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-payment-table',
  templateUrl: './payment-table.component.html',
  styleUrls: ['./payment-table.component.css'],
})
export class PaymentTableComponent extends Modal implements OnInit {
  @Input() clients: Client[];
  @Output() selectClientEvent = new EventEmitter();
  checkValue = false;
  usuario: Employee;

  isDesktop: boolean = false;
  allClients: any;
  displayedColumns: string[] = ['NAME_CLIENT', 'LAST_NAME', 'LAST_2_NAME', 'TOTAL_DEBT', 'OPCIONES'];
  dataSource = new MatTableDataSource<Client>();

  public regionFilterControl: FormControl = new FormControl();
  public filteredRegion: ReplaySubject<Client[]> = new ReplaySubject(1);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  protected _onDestroy = new Subject();
  form = new UntypedFormGroup({
    region: new UntypedFormControl([]),
  });

  regions: Region[] = [];
  constructor(
    private authService: AuthService,
    private _regionService: RegionService,
    private deviceService: DeviceDetectorService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  getRegions() {
    this._regionService.getRegions().subscribe((regions: any) => {
      this.regions = regions;
      this.regions.forEach(element => {
        if (element.ID_TYPE_AVAILABLE == 2) {
          element.disabled = true;
        }
      });
      this.filteredRegion.next(this.regions.slice());
      this.regionFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterRegionComboBox();
      });
    });
  }

  protected filterRegionComboBox() {
    if (!this.regions) {
      return;
    }

    let search = this.regionFilterControl.value;
    if (!search) {
      this.filteredRegion.next(this.regions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredRegion.next(this.regions.filter(region => region.NAME_REGION.toLowerCase().indexOf(search) > -1));
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  filtrarZona() {
    var result = this.form.value.region.NAME_REGION;
    if (result != undefined) {
      this.applyFilterCustom(result);
    } else {
      this.applyFilterCustom('');
    }
  }

  applyFilterCustom(filterValue: string) {
    if (filterValue == '') {
      this.dataSource.data = this.allClients;
    } else {
      var newData = [];
      this.allClients.forEach(client => {
        if (client.NAME_REGION == filterValue) {
          newData.push(client);
        }
      });
      this.dataSource.data = newData;

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  ngOnInit() {
    this.getInfoDevice();
    this.getRegions();
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }
  getUserLogged() {
    this.usuario = this.userIsLogged();
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(this.clients);
    this.allClients = this.clients;
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
  }

  cmbChange() {
    if (this.checkValue) {
      var newClients: Client[] = [];
      this.clients.forEach(element => {
        if (element.TOTAL_DEBT > 0) {
          newClients.push(element);
        }
      });
      this.dataSource = new MatTableDataSource(newClients);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource(this.clients);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  viewClient(c: Client) {
    this.selectClientEvent.emit(c);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
