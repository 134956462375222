import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import Swal from 'sweetalert2';
import { Employee } from '../employee/employee'

import { JwtHelperService } from '@auth0/angular-jwt';
import decode from 'jwt-decode'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _http:HttpClient,
    private jwtHelper : JwtHelperService
  ) { }

  login(user: string, pwd: string){
    var md5 = require('md5');
    var pass = md5(pwd)

    var employee : Employee = new Employee()

    employee.USERNAME = user;
    employee.PWD = pass;
    return this._http.post('/login',employee);
  }

    
  isAuth(): boolean{
    const token = localStorage.getItem('token');
    if(token == null){
      return false
    }else{
      if(!localStorage.getItem('token') || this.jwtHelper.isTokenExpired(token)  ){
        return false
      }
      return true;
    }
   
  }

  getUserLogged(){
    if(this.isAuth()){
      const token = localStorage.getItem('token')
      const user : Employee = decode(token);
      return user;
    }
    return null
  }
}
