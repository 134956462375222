import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { Item } from '../item';
import { TypeItemService } from '../../type-item/type-item.service';
import { TypeItem } from '../../type-item/type-item';
import { ItemService } from '../item.service';
import Swal from 'sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-item-new',
  templateUrl: './item-new.component.html',
  styleUrls: ['./item-new.component.css']
})
export class ItemNewComponent implements OnInit {
  @Output() updateItem = new EventEmitter();
  isDesktop : boolean = false;
  newItem = new Item;
  typesItems: TypeItem[] = [];
  form = new UntypedFormGroup({
    tipoItem: new UntypedFormControl([],Validators.required),
    name: new UntypedFormControl('',Validators.required),
    precio : new UntypedFormControl('', [Validators.required,
      Validators.pattern('[1-9][0-9]*')]),
    cantidad : new UntypedFormControl('', [Validators.required,
      Validators.pattern('[0-9]*')])  
  }
  );
  public tipoItemFilterControl: FormControl = new FormControl();
  public filteredTipoItem: ReplaySubject<TypeItem[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  constructor(
    private _typeItemService: TypeItemService,
    private _itemService : ItemService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.getTypeItems();
    this.markFormGroupTouched(this.form)
    this.getInfoDevice()
  }
  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }
  
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  

  getTypeItems(){
    this._typeItemService.getTypeItems()
    .subscribe((typesItems : any) => {this.typesItems = typesItems
      this.typesItems.forEach(element => {
        if(element.ID_TYPE_AVAILABLE == 2){
          element.disabled = true;
        }
    });
    this.filteredTipoItem.next(this.typesItems.slice());
      //Apply changes subscripted to the filter
      this.tipoItemFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTypeItemComboBox();
      });
    })
  }

   protected filterTypeItemComboBox() {
    if (!this.typesItems) {
      return;
    }
  
    let search = this.tipoItemFilterControl.value;
    if (!search) {
      this.filteredTipoItem.next(this.typesItems.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
  
    this.filteredTipoItem.next(
      this.typesItems.filter(itemType => itemType.NAME_TYPE_ITEM.toLowerCase().indexOf(search) > -1)
    );
  }
  create(){
    Swal.fire({
      icon: 'info',
      title: "Generando item...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.newItem.NAME_ITEM = this.form.value.name
    this.newItem.ID_TYPE_ITEM = this.form.value.tipoItem
    this.newItem.PRICE = this.form.value.precio
    this.newItem.STOCK = this.form.value.cantidad

    var self = this;
    this._itemService.create(this.newItem)
    .subscribe((value) =>{
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción. Nombre de item duplicado'
        })
      }else{
        self.popUpCreate()
        self.form.reset();
        self.newItem = new Item;
        self.updateItem.emit()
      }
    })
  }

  popUpCreate(){
    Swal.fire({
      icon: 'success',
      title: 'Item agregado',
      text: 'El item fué agregado correctamente'
    })    
  }

}
