import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RequestEdit } from '../../request-edit';
import { Client } from 'src/app/client/client';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { TypeTreasuryService } from 'src/app/type-treasury/type-treasury.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TypeTreasury } from 'src/app/type-treasury/type-treasury';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header-request-edit',
  templateUrl: './header-request-edit.component.html',
  styleUrls: ['./header-request-edit.component.css']
})
export class HeaderRequestEditComponent implements OnChanges, OnInit{
  @Input() request: RequestEdit;
  @Input() client: Client;
  @Input() factureForm: UntypedFormGroup;
  @Output() updateHacienda = new EventEmitter();
  
  renderLastNames: boolean;

  typeTreasury: TypeTreasury[] = [];
  public typeTreasuryFilterControl: FormControl = new FormControl();
  public filteredTypeTreasury: ReplaySubject<TypeTreasury[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
   constructor(
      private _typeTreasuryService: TypeTreasuryService,
      private deviceService: DeviceDetectorService,
  ){

  }
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.client != undefined && changes.client.currentValue != undefined){
      if (this.client.JURIDICA){
        this.renderLastNames = false
      }
      else{
        this.renderLastNames = true
      }
    }
    if(changes.request != undefined && changes.request.currentValue != undefined){
      this.getTypeTreasury();
    }
  }

  changeTypeTreasury() {
    this.updateHacienda.emit();
  }

   getTypeTreasury() {
    this._typeTreasuryService.getTypesTreasure().subscribe((x: any) => {
      this.typeTreasury = x
      this.filteredTypeTreasury.next(this.typeTreasury.slice());

          // Apply changes subscribed to the filter
          this.typeTreasuryFilterControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterTypeTreasuryComboBox();
            });
          var treasury = this.typeTreasury.find(x => x.ID_TYPE_TREASURY == this.request.ID_TYPE_TREASURY)
          this.factureForm.controls.idTypeTreasury.setValue(treasury);

    });
  }

  protected filterTypeTreasuryComboBox() {
    if (!this.typeTreasury) {
      return;
    }
  
    let search = this.typeTreasuryFilterControl.value;
    if (!search) {
      this.filteredTypeTreasury.next(this.typeTreasury.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
  
    this.filteredTypeTreasury.next(
      this.typeTreasury.filter(typeTreasury => typeTreasury.NAME_TYPE.toLowerCase().indexOf(search) > -1)
    );
  }
}
