import { Component, OnInit } from '@angular/core';
import { TypeExpenseService } from './type-expense.service';
import { TypeExpense } from './type-expense';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from '../misc/modal';

@Component({
  selector: 'app-type-expense',
  templateUrl: './type-expense.component.html',
  styleUrls: ['./type-expense.component.css']
})
export class TypeExpenseComponent extends Modal implements OnInit {

  agregadoNew: boolean = true
  agregadoEdit: boolean = true
  exitoNew: boolean = false
  exitoEdit: boolean = false
  typeExpenses: TypeExpense[] = []
  edit : boolean = false
  editSeleccionado : TypeExpense

  constructor(
    private _typeExpenseService: TypeExpenseService, modalServiceExt: NgbModal) {
      super(modalServiceExt);
    }

  ngOnInit() {
    this.getTypeExpenses();
  }
  editTypeExpense(typeExpense: TypeExpense)
  {
    this.edit = true;
    this.editSeleccionado = typeExpense;
    this.createError(3);
    this.editError(1);
  }


  getTypeExpenses(){
    this._typeExpenseService.getTypeExpenses()
    .subscribe((typeExpenses:any) => {this.typeExpenses = typeExpenses
    })
  }

  create(typeExpense: TypeExpense){
    this.createError(1);
    if(typeExpense.NAME_TYPE_EXPENSE == "" 
      || typeExpense.DESCRIPTION == "")
      {
        this.createError(2);
        return;
      }
    for(var i = 0 ; i<this.typeExpenses.length;i++)
      {
        if(this.typeExpenses[i].NAME_TYPE_EXPENSE == typeExpense.NAME_TYPE_EXPENSE)
        {
          this.createError(2);
          return;
        }
      }
    if(this.exitoNew)
    {
      this._typeExpenseService.create(typeExpense)
      .subscribe(status => this.getTypeExpenses())
    }
  }

  destroy(typeExpense: TypeExpense){
    this._typeExpenseService.destroy(typeExpense)
    .subscribe(status => this.getTypeExpenses())
  }

  update(typeExpense: TypeExpense){
    this.editError(1);
    if(typeExpense.ID_TYPE_EXPENSE != -1)
    {
      if(typeExpense.NAME_TYPE_EXPENSE == "" 
      || typeExpense.DESCRIPTION == "")
      {
        this.editError(2);
        return;
      }
      for(var i = 0 ; i<this.typeExpenses.length;i++)
      {
        if(this.typeExpenses[i].NAME_TYPE_EXPENSE == typeExpense.NAME_TYPE_EXPENSE
          && this.typeExpenses[i].ID_TYPE_EXPENSE != typeExpense.ID_TYPE_EXPENSE)
        {
          this.editError(2);
          return;
        }
      }

    if(this.exitoEdit)
      {
      this._typeExpenseService.update(typeExpense)
      .subscribe(status => this.getTypeExpenses())
      }
    }
    this.edit = false;
    this.editError(3)
  }

  createError(valido : number)
  {
    //Agregado con exito
    if(valido == 1)
    {
      this.agregadoNew = true;
      this.exitoNew = true;
    }
    else{
    //Error de ingreso
      if(valido == 2)
      {
        this.agregadoNew = false;
        this.exitoNew = false;
      }
      //Estado inicial o neutro
      else{
        this.agregadoNew = true;
        this.exitoNew = false;
      }
    }
  }

  editError(valido: number)
  {
     //Agregado con exito
     if(valido == 1)
     {
       this.agregadoEdit = true;
       this.exitoEdit = true;
     }
     else{
     //Error de ingreso
       if(valido == 2)
       {
         this.agregadoEdit = false;
         this.exitoEdit = false;
       }
       //Estado inicial o neutro
       else{
         this.agregadoEdit = true;
         this.exitoEdit = false;
       }
     }
  }

}
