import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {Employee} from '../employee/employee'
import {EmployeeService} from '../employee/employee.service'
import {AuthGuard} from '../guards/auth.guard'
import { Route, Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-navbar-employee',
  templateUrl: './navbar-employee.component.html',
  styleUrls: ['./navbar-employee.component.css']
})
export class NavbarEmployeeComponent implements OnInit {
  navbar : boolean = true;
  @ViewChild('navbarToggler', {static: false}) navbarToggler:ElementRef;
  @Output() logginOutEvent = new EventEmitter();
  usuario : Employee
  constructor(
    private appComponent: AppComponent,  
    private router: Router,
    private _EmployeeService : EmployeeService,
    private auth : AuthGuard,
    private authService : AuthService) { }

    userIsLogged(){
      const employee : Employee = this.authService.getUserLogged()
      if(employee != null){
        this.usuario =employee
      }
    }

  ngOnInit() {
    this.userIsLogged();
  }
  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapse()
  {
    if(this.navBarTogglerIsVisible()){
      this.navbarToggler.nativeElement.click()
    }
  }

  logOut(){
    this.logginOutEvent.emit(3)
    this.router.navigate(['login'])
  }
}
