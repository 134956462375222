import { Component, OnInit, Input } from '@angular/core';
import { Client } from '../client';
import { Report } from '../../report/report';
import {
  UntypedFormGroup,
  UntypedFormControl,
  FormBuilder,
  FormArray,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { PaymentService } from '../payment.service';
import { ReportService } from '../../report/report.service';
import { imageData } from '../../resources/image';
import { DatePipe, formatDate } from '@angular/common';
import Swal from 'sweetalert2';

const { jsPDF } = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-payment-pdf',
  templateUrl: './payment-pdf.component.html',
  styleUrls: ['./payment-pdf.component.css'],
})
export class PaymentPdfComponent implements OnInit {
  pdfForm: UntypedFormGroup;
  @Input() client: Client;
  minDate = '';
  reports: Report[] = [];
  constructor(private _paymentService: PaymentService, private _reportService: ReportService) {}

  ngOnInit() {
    this.formInit();
  }

  formInit() {
    this.pdfForm = new UntypedFormGroup({
      //FORM CLIENTE
      initialDateValue: new UntypedFormControl('', Validators.required),
      finalDateValue: new UntypedFormControl('', Validators.required),
    });
  }

  async create() {
    Swal.fire({
      icon: 'info',
      title: 'Generando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    await this.getRequestHistory(this.client);
    this.generatePDF();
    Swal.fire({
      icon: 'success',
      title: 'Listo!',
      showConfirmButton: false,
      timer: 1000,
    });
  }

  generatePDF() {
    const doc = new jsPDF();
    doc.addImage(imageData, 'JPEG', 10, 240, 190, 46);

    doc.setFontSize(22);
    doc.setTextColor(14, 168, 147);
    doc.text(20, 20, 'Reporte de facturación');

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    var format = 'dd/MM/yyyy';
    const locale = 'en-US';
    var hoy = formatDate(new Date(), format, locale);
    var doo = new Date(this.pdfForm.value.initialDateValue);
    var fechaInicial = formatDate(new Date(doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)), format, locale);
    var doo = new Date(this.pdfForm.value.finalDateValue);
    var fechaFinal = formatDate(new Date(doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)), format, locale);

    var splitText = doc.splitTextToSize(
      'Reporte de facturación expendido en la fecha: ' +
        hoy +
        '.\n' +
        'Este reporte contiene todas las facturas generadas desde el ' +
        fechaInicial +
        ' hasta el ' +
        fechaFinal,
      180
    );
    doc.text(20, 30, splitText);

    doc.autoTable({
      head: [['Descripción', 'Valor']],
      body: [
        ['Nombre del cliente: ', this.client.NAME_CLIENT],
        ['Cédula cliente: ', this.client.IDN],
        ['Deuda total del cliente: ', this.client.TOTAL_DEBT],
        ['Dias de transcurridos hasta la cancelación: ', this.client.DAYS_OF_INDEBTEDNESS],
      ],
      startY: 45,
    });

    this.reports.forEach(element => {
      doc.addPage();
      doc.setFontSize(22);
      doc.setTextColor(14, 168, 147);
      doc.text(20, 20, 'Número de factura ' + element.ID_REQUEST);

      doc.autoTable({
        head: [
          [
            'Nombre del colaborador creador',
            'Fecha de creación',
            'Precio total',
            'Estado de la factura',
            'Estado de la factura',
            'Fecha de entrega',
          ],
        ],
        body: [
          [
            element.NAME_EMPLOYEE,
            element.DATE_REQUEST,
            element.TOTAL_PRICE,
            element.NAME_TYPE_REQUEST,
            element.NAME_REQUEST_STATUS,
            element.DATE_DELIVERY,
          ],
        ],
        startY: 30,
      });

      doc.setFontSize(12);
      doc.setTextColor(12, 32, 131);
      doc.text(20, doc.previousAutoTable.finalY + 10, 'Detalle de la factura');

      var lines = [];
      element.lines.forEach(dataline => {
        lines.push([dataline.NAME, dataline.AMOUNT, dataline.PRICE, dataline.TOTAL_PRICE]);
      });
      doc.autoTable({
        head: [['Articulo/Trabajo', 'Cantidad', 'Precio unitario', 'Precio subtotal']],
        body: lines,
        startY: doc.previousAutoTable.finalY + 15,
      });

      doc.setFontSize(12);
      doc.setTextColor(12, 32, 131);
      doc.text(20, doc.previousAutoTable.finalY + 10, 'Pagos asociados:');

      var linespa = [];
      element.logs.forEach(dataline => {
        linespa.push([
          dataline.NAME_EMPLOYEE,
          dataline.AMOUNT_DEPOSIT,
          dataline.NAME_TYPE_PAYMENT,
          dataline.AMOUNT_DUE,
          dataline.DUE_AFTER_DEPOSIT,
          dataline.DATE_PAY,
        ]);
      });
      doc.autoTable({
        head: [
          [
            'Nombre del colaborador',
            'Monto del deposito',
            'Método de pago',
            'Deuda inicial',
            'Deuda depues del deposito',
            'Fecha de pago',
          ],
        ],
        body: linespa,
        startY: doc.previousAutoTable.finalY + 15,
      });
      doc.addImage(imageData, 'JPEG', 10, 240, 190, 46);
    });
    //doc.output('dataurlnewwindow');
    doc.save('Historial ' + this.client.NAME_CLIENT + ' ' + hoy + ' .pdf');
  }

  async getRequestHistory(client: Client) {
    var self = this;
    client.INITIALDATE = this.pdfForm.value.initialDateValue.toISOString().substring(0, 10);
    client.FINALDATE = this.pdfForm.value.finalDateValue.toISOString().substring(0, 10);
    await this._paymentService
      .getClientHistoryRange(client)
      .toPromise()
      .then((data: any) => {
        self.reports = data;
        self.reports.forEach(report => {
          var datepipe: DatePipe = new DatePipe('en-US');
          if (report.DATE_DELIVERY) {
            var dateDelivery = new Date(report.DATE_DELIVERY);
            report.DATE_DELIVERY = datepipe.transform(dateDelivery, 'dd/MM/yyyy, h:mm:ss a');
          } else {
            report.DATE_DELIVERY = '-';
          }

          if (report.DATE_REQUEST) {
            var dateRequest = new Date(report.DATE_REQUEST);
            report.DATE_REQUEST = datepipe.transform(dateRequest, 'dd/MM/yyyy, h:mm:ss a');
          } else {
            report.DATE_DELIVERY = '-';
          }
        });
      });
    for (let index = 0; index < self.reports.length; index++) {
      var reportSend = new Report();
      reportSend.ID_REQUESTP = self.reports[index].ID_REQUEST;

      self.reports[index].logs = await self._paymentService.getRequestLogHistory(reportSend).toPromise();
      self.reports[index].logs.forEach(log => {
        var datepipe: DatePipe = new DatePipe('en-US');

        var datePay = new Date(log.DATE_PAY);
        log.DATE_PAY = datepipe.transform(datePay, 'dd/MM/yyyy, h:mm:ss a');
      });
      self.reports[index].lines = await self._reportService.getReport7(reportSend).toPromise();
    }
  }

  setMinDate() {
    var date = this.pdfForm.value.initialDateValue;
    var finalDate = this.pdfForm.value.finalDateValue;
    var dateI = new Date(date);
    var dateF = new Date(finalDate);

    if (dateI >= dateF || finalDate == '') {
      this.pdfForm.controls.finalDateValue.setValue(date);
    }
    this.minDate = date;
  }

  stringToDateF(datep) {
    var date = new Date(datep);
    var strTime =
      date.getDate().toString() + '/' + (date.getMonth() + 1).toString() + '/' + date.getFullYear().toString();
    return strTime;
  }

  formatAMPM(date: Date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var minutesP = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
    var strTime =
      date.getDate().toString() +
      '/' +
      (date.getMonth() + 1).toString() +
      '/' +
      date.getFullYear().toString() +
      ' : ' +
      hours.toString() +
      ':' +
      minutesP +
      ' ' +
      ampm;
    return strTime;
  }
}
