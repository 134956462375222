import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

import {TypeTreasury} from './type-treasury';
import {TypeTreasuryService} from './type-treasury.service';

@Component({
  selector: 'app-type-treasury',
  templateUrl: './type-treasury.component.html',
  styleUrls: ['./type-treasury.component.css']
})
export class TypeTreasuryComponent implements OnInit {

  typesTreasure: TypeTreasury[] = []
  constructor(
    private _typesTreasureService: TypeTreasuryService
  ) {}

  ngOnInit() {
    this.getTypesTreasure()
  }

  getTypesTreasure(){
    this._typesTreasureService.getTypesTreasure()
    .subscribe((typesTreasure:any) => this.typesTreasure = typesTreasure)
  }

  create(typesTreasure: TypeTreasury){
    this._typesTreasureService.create(typesTreasure).subscribe(function (value){
      if(value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción del tipo de hacienda',
          footer: '<a>Por favor intentarlo más tarde</a>'
        })
      }else{
        this.getTypesTreasure()
      }
    })
  }

  destroy(typesTreasure: TypeTreasury){
    this._typesTreasureService.destroy(typesTreasure).subscribe(function(value){
      if(value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la ejecución de la operación tipo de hacienda.',
          footer: '<a>La operación a realizar puede tener problemas de dependencias?</a>'
        })
      }else{
        this.getTypesTreasure()
      }
    })
  }

  update(typesTreasure: TypeTreasury){
    this._typesTreasureService.update(typesTreasure).subscribe(function (value){
      if(value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la actualización del tipo de hacienda.',
          footer: '<a>La operación a realizar puede tener problemas de dependencias</a>'
        })
      }else{
        this.getTypesTreasure()
      }
    })
  }
}
