import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from '../misc/modal';
import { Provider } from './provider';
import { ProviderService } from './provider.service';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css']
})
export class ProviderComponent extends Modal implements OnInit {

  providers: Provider[] = []
  constructor(
    private _providerService : ProviderService, private modalServiceExt: NgbModal
    ) {super(modalServiceExt ) }
  ngOnInit() {
    this.getProviders();
  }

  getProviders(){
    this._providerService.getProviders()
    .subscribe((providers:any) => {this.providers = providers
    })
  }

}
