import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Region } from './region';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  constructor(private _http: HttpClient) {}

  create(region: Region) {
    return this._http.post('/regions', region);
  }

  destroy(region: Region) {
    return this._http.delete('/regions/' + region.ID_REGION);
  }

  update(region: Region) {
    return this._http.put('/regions', region);
  }

  getRegions() {
    return this._http.get('/regions');
  }

  getRegion(region: Region) {
    return this._http.get('/regions/' + region.ID_REGION);
  }
}
