export class Inventory {
  constructor(
    public ID_INVENTORY: number = 0,
    public ID_ITEM: number = 0,
    public ITEM_NAME: string = '',
    public NAME_ITEM: string = '',
    public ID_PROVIDER: number = 0,
    public PROVIDER_NAME: string = '',
    public NAME_PROVIDER: string = '',
    public AMOUNT: number = 0,
    public PRICE_BY_UNIT_PROVIDER: number = 0,
    public PRICE_TOTAL_PROVIDER: number = 0,
    public PRICE_BY_UNIT_STOCK: number = 0,
    public PRICE_TOTAL_STOCK: number = 0,
    public PROFIT: number = 0,
    public TOTAL_PROFIT: number = 0,
    public STOCK: number = 0,
    public DATE_IN: string = '',
    public COMMENTS: string = ''
  ) {}
}
