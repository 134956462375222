import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Client } from '../client';
import { Report } from '../../report/report';
import { PaymentService } from '../payment.service';
import { ReportService } from '../../report/report.service';
import { Employee } from 'src/app/employee/employee';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css'],
})
export class PaymentHistoryComponent extends Modal implements OnInit {
  @Input() client: Client;
  @Output() refreshClientEvent = new EventEmitter();
  reportsHistory: Report[] = [];

  indexModalClicked = 0;
  sumWithoutTax = 0;
  bdNames: String[] = [];
  showNames: String[] = [];
  reportSeleccionado: Report;
  usuario: Employee;
  employee: boolean;
  changes: boolean;
  displayedColumns: string[] = [
    'ID_REQUEST',
    'NAME_EMPLOYEE',
    'TOTAL_PRICE',
    'DATE_REQUEST',
    'TOTAL_DUE',
    'NAME_TYPE_REQUEST',
    'DATE_DELIVERY',
    'OPCIONES',
  ];
  dataSource = new MatTableDataSource<Report>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private _paymentService: PaymentService,
    private _reportService: ReportService,
    private authService: AuthService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getRequest(this.client);
  }

  ngOnInit() {
    this.getRequest(this.client);
    this.getUserLogged();
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }

  getUserLogged() {
    this.usuario = this.userIsLogged();
    if (this.usuario.ID_TYPE_EMPLOYEE == 1) {
      this.employee = true;
    } else {
      this.employee = false;
    }
  }

  getRequest(client: Client) {
    var self = this;
    var clientRequest: Client = new Client();
    Object.assign(clientRequest, client);
    clientRequest.ID_TYPE_COLLECTABLE = 1;
    clientRequest.ID_TYPE_STATE_READY = 2;
    this._paymentService.getClientHistory(clientRequest).subscribe((value: any) => {
      self.reportsHistory = value;
      self.reportsHistory.forEach(element => {
        var reportSend = new Report();
        reportSend.ID_REQUESTP = element.ID_REQUEST;
        reportSend.COMMENTS = element.COMMENTS;
        self._paymentService.getRequestLogHistory(reportSend).subscribe(xv => {
          element.logs = xv;
        });
        element.TOTAL_WITHOUT_TAX = 0;
        self._reportService.getReport7(reportSend).subscribe(xv => {
          element.lines = xv;
          element.lines.forEach(line => {
            element.TOTAL_WITHOUT_TAX += line.TOTAL_BEFORE_TAX;
          });
        });
      });
      self.dataSource = new MatTableDataSource(self.reportsHistory);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
      self.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'DATE_REQUEST':
            var date = new Date(item.DATE_REQUEST);
            return date;
          case 'DATE_DELIVERY':
            var date = new Date(item.DATE_DELIVERY);
            return date;
          default:
            return item[property];
        }
      };
    });
  }

  entregarFactura(report) {
    Swal.fire({
      icon: 'info',
      title: 'Generando la entrega...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    var self = this;
    report.ID_EMPLOYEE = this.usuario.ID_EMPLOYEE;
    this._paymentService.setRequestStatus(report).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la entrega de la orden',
          footer: '<a> Problemas con la actualización del producto.</a>',
        });
      } else {
        self.popUpEntregar();
        self.getRequest(self.client);
      }
    });
  }

  popUpEntregar() {
    Swal.fire({
      icon: 'success',
      title: 'Orden entegada',
      text: 'La orden de trabajo fue entrega con éxito',
    });
  }

  test(x: any) {
    this.indexModalClicked = x;
  }

  eliminarFactura(report: Report) {
    var self = this;
    this._paymentService.deleteRequest(report).subscribe(function (value) {
      self.getRequest(self.client);
    });
  }

  updateClients(changes: boolean) {
    this.changes = changes;
  }

  reload() {
    if (this.changes) {
      this.refreshClientEvent.emit(this.client);
      this.changes = false;
    }
  }

  onChangeCollectible(change: boolean) {
    this.closeModal();
    this.changes = change;
    this.reload();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
