import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Employee } from '../employee';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
  @Input() employees: Employee[];
  @Output() updateEmployeeEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  update(employee:Employee){
    this.updateEmployeeEvent.emit(employee);
  }
}
