import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Employee } from '../../employee/employee';
import { Client } from '../client';
import { Payment } from '../payment';
import { PaymentService } from '.././payment.service';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { TypePayment } from '../..//type-payment/type-payment';
import { TypePaymentService } from '../..//type-payment/type-payment.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-payment-new',
  templateUrl: './payment-new.component.html',
  styleUrls: ['./payment-new.component.css'],
})
export class PaymentNewComponent implements OnInit {
  @Input() client: Client;
  @Input() employee: Employee;
  @Output() createNewPaymentEvent = new EventEmitter();

  form = new UntypedFormGroup({
    abono: new UntypedFormControl([0], [Validators.required, Validators.pattern('[0-9]*')]),
    idTypePayment: new UntypedFormControl([], Validators.required),
  });
  typePayment: TypePayment[] = [];
  isDesktop: boolean = false;
  public typePaymentFilterControl: FormControl = new FormControl();
  public filteredTypePayment: ReplaySubject<TypePayment[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  constructor(
    private _paymentService: PaymentService,
    private _TypePaymentService: TypePaymentService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.getUserLogged();
    this.getTypePayment();
    this.form.controls['abono'].setValue(0);
    this.markFormGroupTouched(this.form);
    this.getInfoDevice();
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getTypePayment() {
    this._TypePaymentService.getTypePayments().subscribe((typePayment: any) => {
      this.typePayment = typePayment;
      if (this.employee.ID_TYPE_EMPLOYEE == 2) {
        this.typePayment[0].disabled = true;
        this.typePayment[2].disabled = true;
        this.typePayment[3].disabled = true;
      }
      this.filteredTypePayment.next(
        this.typePayment.filter(typePayment => {
          return !typePayment.disabled;
        })
      );
      // Apply changes subscribed to the filter
      this.typePaymentFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTypePaymentComboBox();
      });
    });
  }

  protected filterTypePaymentComboBox() {
    if (!this.typePayment) {
      return;
    }

    let search = this.typePaymentFilterControl.value;
    if (!search) {
      this.filteredTypePayment.next(
        this.typePayment.filter(typePayment => {
          return !typePayment.disabled;
        })
      );
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredTypePayment.next(
      this.typePayment.filter(typePayment => {
        return typePayment.NAME.toLowerCase().indexOf(search) > -1 && !typePayment.disabled;
      })
    );
  }

  onChangePayment() {
    var ID_PAYMENT = this.form.get('idTypePayment').value;
    if (this.employee.ID_TYPE_EMPLOYEE == 2) {
      if (ID_PAYMENT == 1 || ID_PAYMENT == 3 || ID_PAYMENT == 4) {
        this.form.controls['abono'].disable();
        this.form.controls['abono'].setValue(0);
      } else {
        this.form.controls['abono'].enable();
        this.form.controls['abono'].setValue(0);
      }
    }
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }

  getUserLogged() {
    this.employee = this.userIsLogged();
  }

  newPayment() {
    Swal.fire({
      icon: 'info',
      title: 'Pagando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    var payment = new Payment();
    payment.ID_CLIENT = this.client.ID_CLIENT;
    payment.ID_TYPE_PAYMENT = this.form.get('idTypePayment').value;
    payment.AMOUNT_DEPOSIT = this.form.get('abono').value;
    payment.ID_EMPLOYEES = this.employee.ID_EMPLOYEE;
    var self = this;

    this._paymentService.create(payment).subscribe(function (value) {
      var paymentResult: Payment;
      paymentResult = value;
      Swal.fire({
        title: 'El vuelto es de: ' + paymentResult['0']['SALDO_DISPONIBLE'],
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.value) {
          self.createNewPaymentEvent.emit(self.client);
        }
      });
    });
    this.form.reset();
    this.form.controls['abono'].enable();
    this.form.controls['abono'].setValue(0);
  }
}
