import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Client } from '../client'
import { ClientService } from '../client.service'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegionService } from 'src/app/region/region.service';
import { Region } from 'src/app/region/region';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-tabla-client',
  templateUrl: './tabla-client.component.html',
  styleUrls: ['./tabla-client.component.css']
})
export class TablaClientComponent extends Modal implements OnInit {
  @Input() clients: Client[];

  public clientSeleccionado: Client;
  public editable : Boolean = false;
  displayedColumns: string[] = ['IDN', 'NAME_CLIENT', 'LAST_NAME', 'LAST_2_NAME', 'PHONE_NUMBER', 'OPCIONES'];
  dataSource = new MatTableDataSource<Client>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  regions: Region[] = []

  allClients : any;
  isDesktop : boolean = false;
  
  form = new UntypedFormGroup({
    region: new UntypedFormControl([]),
    filtro: new UntypedFormControl("")
  });
  //Use another control for filter data
  public regionFilterControl: FormControl = new FormControl();
  //Filter region after apply search on Combobox
  public filteredRegions: ReplaySubject<Region[]> = new ReplaySubject(1);
  
  protected _onDestroy = new Subject();
  constructor(
    private _regionService: RegionService,
    private clientService: ClientService,
    private deviceService: DeviceDetectorService, private modalServiceExt: NgbModal
    ) {super(modalServiceExt) }
    @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  ngOnInit() {
    this.getRegions();
    this.getClients();
    this.getInfoDevice();
    
  }
  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.filteredRegions
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
          this.singleSelect.compareWith = (a: Region, b: Region) => a && b && a.ID_REGION === b.ID_REGION;
      });
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  filterPredicate(data: Client, filter: string)
  {
    console.log(data)
    return false;
  }

  getRegions(){
    this._regionService.getRegions()
    .subscribe((regions:any) => {this.regions = regions
    this.filteredRegions.next(this.regions.slice());
    
    //Apply changes subscripted to the filter
    this.regionFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRegionsComboBox();
      });
    })
  }

  /**
   * Write code on Method
   *
   * method logical code
   */
  ngOnDestroy() {
    //this._onDestroy.next();
    this._onDestroy.complete();
  }
  
  
  /**
   * Write code on Method
   *
   * method logical code
   */
  protected filterRegionsComboBox() {
    if (!this.regions) {
      return;
    }
  
    let search = this.regionFilterControl.value;
    if (!search) {
      this.filteredRegions.next(this.regions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
  
    this.filteredRegions.next(
      this.regions.filter(bank => bank.NAME_REGION.toLowerCase().indexOf(search) > -1)
    );
  }
  
  filtrarZona(){
    var result = this.form.value.region
    this.form.controls['filtro'].setValue("")
    if (result != undefined){
      this.applyFilterCustom(result)
    }else{
      this.applyFilterCustom(null)
    }
  }

  applyFilterCustom(filterValue: Region) {
   if (filterValue == null){
     this.dataSource.data = this.allClients
   }else{
      var newData = []
      this.allClients.forEach(client => {
        if (client.NAME_REGION == filterValue.NAME_REGION) {
          newData.push(client)
        }
      });
      this.dataSource.data = newData
      
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.clients);
    this.allClients = this.clients
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
}


  getClients() {
    var self = this;
    this.clientService.getClients()
      .subscribe((x : any) => {
        self.clients = x;
        self.dataSource = new MatTableDataSource(self.clients);
        this.allClients = self.clients
        self.dataSource.paginator = self.paginator;
        self.dataSource.sort = self.sort;
      });
  }

  editClient(client:Client){
    this.clientSeleccionado = client;
    this.editable = true;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
