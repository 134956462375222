import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export class Modal {
  content: any;
  constructor(private modalService: NgbModal) {}
  triggerModal(content, size) {
    this.content = content;
    this.modalService.open(content, {
      size: size,
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title',
    });
  }

  closeModal() {
    this.modalService.dismissAll(this.content);
  }
}
