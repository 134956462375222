import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Expense } from '../expense';
import { ExpenseService } from '../expense.service';
import { ExpenseComponent } from '../expense.component';
import { EmployeeService } from '../../employee/employee.service';
import { formatDate } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { Employee } from 'src/app/employee/employee';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tabla-expense',
  templateUrl: './tabla-expense.component.html',
  styleUrls: ['./tabla-expense.component.css'],
})
export class TablaExpenseComponent extends Modal implements OnInit {
  @Input() expenses: Expense[];
  @Output() updateExpense = new EventEmitter();
  usuario: Employee;

  public expenseSeleccionado: Expense;
  displayedColumns: string[] = ['DESCRIPTION_EXPENSE', 'EXPENSE', 'TIPO_GASTO_TABLA', 'FECHA_TABLA', 'OPCIONES'];
  dataSource = new MatTableDataSource<Expense>();
  public editable: Boolean = false;
  userLogged = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private expenseService: ExpenseService,
    private expenseComponent: ExpenseComponent,
    private _employeeService: EmployeeService,
    private authService: AuthService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  ngOnInit() {
    this.usuario = this.userIsLogged();
    //TODO: Improve
    if (this.usuario.ID_TYPE_EMPLOYEE == 1) {
      this.userLogged = true;
    } else {
      this.userLogged = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;

    self.dataSource = new MatTableDataSource(self.expenses);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
    self.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'FECHA_TABLA':
          var date = new Date(item.DATE_EXPENSE);
          return date;
        default:
          return item[property];
      }
    };
  }

  editar(expense: Expense) {
    //this.expenseComponent.editExpense(p)

    this.expenseSeleccionado = expense;
    this.editable = true;
    //window.scroll(0, 0);
  }
  userIsLogged() {
    return this.authService.getUserLogged();
  }

  getExpenses() {
    var self = this;
    if (this.usuario.ID_TYPE_EMPLOYEE == 1) {
      this.expenseService.getExpenses().subscribe((x: any) => {
        this.expenses = x;
        self.dataSource = new MatTableDataSource(self.expenses);
        self.dataSource.paginator = self.paginator;
        self.dataSource.sort = self.sort;
        self.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'FECHA_TABLA':
              var date = new Date(item.DATE_EXPENSE);
              return date;
            default:
              return item[property];
          }
        };
      });
    } else {
      this.expenseService.getExpensesByEmployee(this.usuario).subscribe((x: any) => {
        this.expenses = x;
        self.dataSource = new MatTableDataSource(self.expenses);
        self.dataSource.paginator = self.paginator;
        self.dataSource.sort = self.sort;
        self.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'FECHA_TABLA':
              var date = new Date(item.DATE_EXPENSE);
              return date;
            default:
              return item[property];
          }
        };
      });
    }
  }

  updateTable() {
    this.updateExpense.emit();
  }

  eliminarExpense(nombre: Expense) {
    Swal.fire({
      icon: 'info',
      title: 'Eliminando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    var self = this;
    this.expenseService.destroy(nombre).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la eliminación. Intentelo más tarde.',
        });
      } else {
        self.popUpCreate();
      }
    });
  }
  popUpCreate() {
    Swal.fire({
      icon: 'success',
      title: 'Gasto eliminado',
      text: 'El gasto fué eliminado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      this.updateTable();
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
