import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Client } from './client';
import { Payment } from './payment';
import { Report } from '../report/report';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private _http: HttpClient) {}

  getClients() {
    return this._http.get('/clientdebts');
  }

  getClientHistory(client: Client) {
    return this._http.post('/clienthistory', client);
  }

  getClientHistoryRange(client: Client) {
    return this._http.post('/clienthistoryrange', client);
  }

  getRequestLogHistory(report: Report) {
    return this._http.post('/requestlogs', report);
  }

  setRequestUncollectible(client: Client) {
    client.ID_TYPE_COLLECTABLE = 2;
    return this._http.put('/uncollectable', client);
  }

  setRequestCollectible(client: Client) {
    client.ID_TYPE_COLLECTABLE = 1;
    return this._http.put('/uncollectable', client);
  }

  setRequestStatus(report: Report) {
    return this._http.put('/requeststatus', report);
  }

  deleteRequest(report: Report) {
    return this._http.put('/requestcomplete', report);
  }

  create(payment: Payment) {
    return this._http.post('/payment', payment);
  }
  createIndividual(payment: Payment) {
    return this._http.post('/paymentIndividual', payment);
  }
}
