import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { PaymentService } from './payment.service';
import { Client } from './client';
import { Employee } from '../employee/employee';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { PaymentViewComponent } from './payment-view/payment-view.component';
import Swal from 'sweetalert2';
import { Modal } from '../misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent extends Modal implements OnInit {
  employee = new Employee();
  clients: Client[];
  clientSeleccionado: Client = null;
  @ViewChildren('viewPaymentEl') childrenComponent: QueryList<PaymentViewComponent>;

  @ViewChild('closebutton', { static: true }) closebutton: ElementRef;

  formEmployee = new UntypedFormGroup({
    nameEmployee: new UntypedFormControl('', Validators.required),
    phoneEmployee: new UntypedFormControl('', Validators.required),
    emailEmployee: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  form = new UntypedFormGroup({
    historial: new UntypedFormControl(true, Validators.required),
  });

  constructor(
    private _paymentService: PaymentService,
    private authService: AuthService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  ngOnInit() {
    this.getClients();
    this.getUserLogged();
    this.setValues();
  }


  setValues() {
    this.formEmployee.controls.nameEmployee.setValue(this.employee.NAME_EMPLOYEE);
    this.formEmployee.controls.phoneEmployee.setValue(this.employee.PHONE);
    this.formEmployee.controls.emailEmployee.setValue(this.employee.EMAIL);
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }

  getUserLogged() {
    this.employee = this.userIsLogged();
    
  }

  getClients() {
    var self = this;
    this._paymentService.getClients().subscribe((clients: any) => {
      self.clients = clients;
    });
  }

  refreshClients(client: Client) {
    var self = this;
    this._paymentService.getClients().subscribe((value: any) => {
      self.clients = value;
      var cliente = self.clients.find(x => x.ID_CLIENT == client.ID_CLIENT);
      if (cliente) {
        this.clientSeleccionado = cliente;
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Realizado.',
          timer: 2200,
          text: 'El cliente no tiene cuentas pendientes.',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        this.clientSeleccionado = null;
      }
    });
  }

  scrolled(first: boolean) {
    this.childrenComponent.changes.subscribe((comps: QueryList<PaymentViewComponent>) => {
      if (comps.last != undefined) {
        if (first) {
          if (this.clientSeleccionado.JURIDICA == 1) {
            comps.last.scroll(-295);
          } else {
            comps.last.scroll(-390);
          }
        } else {
          comps.last.scroll(95);
        }
      }
    });
    this.childrenComponent.notifyOnChanges();
  }

  viewClient(client: Client) {
    this.clientSeleccionado = client;
    this.scrolled(false);
  }
}
