import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { Client } from '../client';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-change-collectible',
  templateUrl: './change-collectible.component.html',
  styleUrls: ['./change-collectible.component.css'],
})
export class ChangeCollectibleComponent implements OnInit {
  @Input() isCollectible: boolean;
  @Input() client: Client;
  @Input() rowToChange: any;
  @Output() closeModalEvent = new EventEmitter();

  title: string;
  body: string;
  changes: boolean = false;

  constructor(private _paymentService: PaymentService) {}

  ngOnInit(): void {
    if (this.isCollectible) {
      this.title = 'Registrar como incobrable';
      this.body = '¿Está seguro de que desea marcar como incobrable esta factura?';
    } else {
      this.title = 'Registrar como cobrable';
      this.body = '¿Está seguro de que desea marcar como cobrable esta factura?';
    }
  }

  closeModal() {
    this.closeModalEvent.emit(this.changes);
  }

  changeCollectible() {
    console.log(this.rowToChange)
    if (this.rowToChange.ID_TYPE_COLLECTABLE == 1) {
      this._paymentService.setRequestUncollectible(this.rowToChange).subscribe(value => {
        if (value['Response'] == 200) {
          this.changes = true;
          this.reload();
          this.popUpIncobrable();
        }
      });
    } else {
      this._paymentService.setRequestCollectible(this.rowToChange).subscribe(value => {
        if (value['Response'] == 200) {
          this.changes = true;
          this.reload();
          this.popUpCobrable();
        }
      });
    }
  }
  reload() {
    if (this.changes) {
      this.closeModal();
      //this.refreshClientEvent.emit(this.client);
      this.changes = false;
    }
  }

  popUpIncobrable() {
    Swal.fire({
      icon: 'success',
      title: 'Factura actualizada',
      text: 'La factura fué actualizada como incobrable.',
    });
  }

  popUpCobrable() {
    Swal.fire({
      icon: 'success',
      title: 'Factura actualizada',
      text: 'La factura fué actualizada como cobrable.',
    });

    this.closeModal();
  }
}
