import { Component, Injectable, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { Employee } from './employee/employee';
import { ChangeDetectorRef } from '@angular/core';

import decode from 'jwt-decode'
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

@Injectable({
  providedIn: 'root' // just before your class
})
export class AppComponent implements OnInit, OnChanges {
  public isViewable: boolean;
  title = 'afiladuria-app';
  adminLogged = false;
  userLogged =  false;
  inicio = true;

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }
  ngOnInit() {
    this.userIsLogged()
  }
  
  constructor(
    private auth : AuthGuard,
    private location: LocationStrategy,
    private authService : AuthService,
    private cdr:ChangeDetectorRef)
    {
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    this.userIsLogged()  

  }

  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }

  userIsLogged(){
    const employee : Employee = this.authService.getUserLogged()
    if(employee != null){
      this.setLogIn(employee.ID_TYPE_EMPLOYEE)
    }else
    {
      this.setLogIn(-1)
    }
  }

  logOut(option : number){
    this.setLogIn(option)
  }

  public setLogIn(numeroNavBar: number){
  
    if (numeroNavBar == 1){
      //Admin
      this.adminLogged = true
      this.userLogged = false
      this.inicio = false
    } else if (numeroNavBar == 2){
      //Employee
      this.userLogged = true
      this.adminLogged = false
      this.inicio = false
    } else {
      //Init
      this.userLogged = false
      this.adminLogged = false
      this.inicio = true
    }
  }

}

