import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from '../misc/modal';
import { Inventory } from './inventory';
import { InventoryService } from './inventory.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent extends Modal implements OnInit {

  inventory: Inventory[] = []
  
  constructor(
    private _inventoryService : InventoryService,
    private modalServiceExt: NgbModal
    ) {super(modalServiceExt) }

  ngOnInit() {
    this.getInventory()
  }

  getInventory(){
    this._inventoryService.getInventory()
    .subscribe((inventory:any) => {this.inventory = inventory})
  }

}
