export class Request {
    constructor(
        public ID_REQUEST: number = 0,
        public ID_CLIENT: number = 0,
        public ID_EMPLOYEE: number = 0,
        public DATE_REQUEST: string = "",
        public TOTAL_PRICE : number = 0,
        public ID_TYPE_STATE_REQUEST: number = 0,
        public ID_TYPE_TREASURY: number = 0,
        public ID_TYPE_PAYMENT: number = 0,
        public ID_REQUEST_STATUS: number = 0,
        public DATE_DELIVERY: string = "",
        public DETAILS: boolean = false,
        public ID_TYPE_COLLECTABLE: number = 0
    ){

    }
}
