import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {Comment} from '../comment'
import {CommentService} from '../comment.service'
import {Client} from '../../client/client'
import {ClientService} from '../../client/client.service'

@Component({
  selector: 'app-comment-new',
  templateUrl: './comment-new.component.html',
  styleUrls: ['./comment-new.component.css']
})
export class CommentNewComponent implements OnInit {
  @Output() createNewCommentEvent = new EventEmitter();
newComment = new Comment;

clients: Client[] = []
  constructor(
    private _clientService: ClientService
  ) { }

  ngOnInit() {
    this.getClients();
  }

  create(){
    this.createNewCommentEvent.emit(this.newComment)
    this.newComment = new Comment;
  }

  getClients(){
    this._clientService.getClients()
    .subscribe((clients : any) => this.clients = clients)
  }
}
