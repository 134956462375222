import { Component, Input } from '@angular/core';
import { Report } from '../report/report';

@Component({
  selector: 'app-pay-log',
  templateUrl: './pay-log.component.html',
  styleUrls: ['./pay-log.component.css']
})
export class PayLogComponent {
  @Input() payLog: Report;
  constructor() {}

  ngOnInit(): void {
    console.log('');
  }
}
