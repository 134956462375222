import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Client } from 'src/app/payment/client';
import { PaymentService } from 'src/app/payment/payment.service';
import { Report } from 'src/app/report/report';
import { ReportService } from 'src/app/report/report.service';
import { ClientHistoryService } from '../client-history.service';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
})
export class HistoryComponent extends Modal implements OnInit {
  @Input() client: Client;

  reportsHistory: Report[] = [];

  indexModalClicked = 0;
  sumWithoutTax = 0;
  bdNames: String[] = [];
  showNames: String[] = [];
  reportSeleccionado: Report;
  employee: boolean;
  changes: boolean;
  displayedColumns: string[] = [
    'ID_REQUEST',
    'DATE_REQUEST',
    'TOTAL_PRICE',
    'NAME_TYPE_REQUEST',
    'DATE_DELIVERY',
    'OPCIONES',
  ];
  dataSource = new MatTableDataSource<Report>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private elRef: ElementRef,
    private _reportService: ReportService,
    private _clientHistoryService: ClientHistoryService,
    private _paymentService: PaymentService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  getRowBackgroundColor(row: any): string {
    if (row.ID_TYPE_COLLECTABLE === 2) {
      return 'uncollectible_row'; // Set your desired background color CSS class
    }
  }

  ngOnInit() {
    this.getRequest(this.client);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getRequest(this.client);
  }

  getRequest(client: Client) {
    var self = this;
    var clientRequest: Client = new Client();
    Object.assign(clientRequest, client);
    clientRequest.ID_TYPE_COLLECTABLE = 1;
    clientRequest.ID_TYPE_STATE_READY = 2;
    this._clientHistoryService.getAllClientHistory(clientRequest).subscribe((value: any) => {
      self.reportsHistory = value;
      self.reportsHistory.forEach(element => {
        var reportSend = new Report();
        reportSend.ID_REQUESTP = element.ID_REQUEST;
        reportSend.COMMENTS = element.COMMENTS;
        self._paymentService.getRequestLogHistory(reportSend).subscribe(xv => {
          element.logs = xv;
        });
        element.TOTAL_WITHOUT_TAX = 0;
        self._reportService.getReport7(reportSend).subscribe(xv => {
          element.lines = xv;
          element.lines.forEach(line => {
            element.TOTAL_WITHOUT_TAX += line.TOTAL_BEFORE_TAX;
          });
        });
      });
      self.dataSource = new MatTableDataSource(self.reportsHistory);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
      self.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'DATE_REQUEST':
            var date = new Date(item.DATE_REQUEST);
            return date;

          default:
            return item[property];
        }
      };
    });
  }

  scroll(scrolled: number) {
    this.elRef.nativeElement.scrollIntoView();
    // now account for fixed header
    var scrolledY = window.scrollY;

    if (scrolledY) {
      window.scroll(0, scrolledY - scrolled);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
