import { Component, OnInit, Input} from '@angular/core';
import {Comment} from './comment'
import {CommentService} from './comment.service'
import {Client} from '../client/client'

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
@Input() client: Client;
  comentarios: Comment[] = []
  constructor(
    private _commentService: CommentService
  ) { }

  ngOnInit() {
    this.getComments()
  }
  getComments(){
    this._commentService.getCommentOfClient(this.client)
    .subscribe((comentarios : any) => this.comentarios = comentarios)
  }

  create(comment: Comment){
    this._commentService.create(comment)
    .subscribe(status => this.getComments())
  }
  destroy(comment: Comment){
    this._commentService.destroy(comment)
    .subscribe(status => this.getComments())
  }
  update(comment:Comment){
    this._commentService.update(comment)
    .subscribe(status => this.getComments())
  }
}
