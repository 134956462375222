export class Item {
    
    constructor(
        public ID_ITEM: number = 0,
        public ID_TYPE_ITEM: number = 0,
        public STOCK: number = 0,
        public NAME_ITEM: string = "",
        public PRICE: number = 0,
        public ID_TYPE_AVAILABLE: number = 0,
        public disabled: boolean = false,
        public editable: boolean = false
    ){

    }
}
