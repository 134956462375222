import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from '../payment/client';

@Injectable({
  providedIn: 'root'
})
export class ClientHistoryService {

  constructor(private _http:HttpClient) { }

  getAllClients(){
    return this._http.get('/allclientdebts')
  }

  getAllClientHistory(client:Client){
    return this._http.post('/allclienthistory', client)
  }
}
