import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { Employee } from './employee'
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private _http:HttpClient
  ) { }
  
  login(user: string, pwd: string){
    var md5 = require('md5');
    var pass = md5(pwd)

    var employee : Employee = new Employee()

    employee.USERNAME = user;
    employee.PWD = pass;
    
    return this._http.post('/login',employee)
  }

  create(employee: Employee){
    var md5 = require('md5');
    employee.PWD = md5(employee.PWD);

    return this._http.post('/employees',employee)
  }

  update(employee: Employee){
    if (employee.editPWD){
      var md5 = require('md5');
      employee.PWD = md5(employee.PWD);
    }
    
    return this._http.put('/employees' ,employee)
  }

  getEmployees(){
    return this._http.get('/employees')
  }

  getEmployee(employee: Employee){
    return this._http.get('/employees/' + employee.ID_EMPLOYEE)
  }
}
