import { Component, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-request-display',
  templateUrl: './request-display.component.html',
  styleUrls: ['./request-display.component.css']
})
export class RequestDisplayComponent implements OnInit {

  form = new UntypedFormGroup({
    ready: new UntypedFormControl(true,Validators.required)
  });
  
  constructor(
  ) { }

  ngOnInit() {
  }

}
