import { LineItem } from '../line-item/line-item';

export class FullRequest {
    constructor(
        public ID_REQUEST: number = 0,
        public ID_CLIENT: number = 0,
        public ID_TYPE_PROCESSING : number = 0,
        public ID_EMPLOYEE: number = 0,
        public TOTAL_PRICE : number = 0,
        public ID_TYPE_STATE_REQUEST: number = 0,
        public ID_TYPE_TREASURY: number = 0,
        public ID_TYPE_PAYMENT: number = 0,
        public ID_REQUEST_STATUS: number = 0,
        public TAX : number = 0,
        public LINES_ITEM : LineItem[] = [],
        public LINES_TYPEWORK : LineItem[] = [],
        public COMMENTS: String = '',
        public ID_TYPE_COLLECTABLE: number = 0,
        public DETAILS: boolean = false
    ){

    }
}
