import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { Client } from '../../payment/client';
import { Employee } from '../../employee/employee';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { Console } from 'console';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegionService } from 'src/app/region/region.service';
import { Region } from 'src/app/region/region';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-table-client-history',
  templateUrl: './table-client-history.component.html',
  styleUrls: ['./table-client-history.component.css'],
})
export class TableClientHistoryComponent extends Modal implements OnInit {
  @Input() clients: Client[];
  @Output() selectClientEvent = new EventEmitter();
  usuario: Employee;
  regions: Region[] = [];

  isAdmin: boolean;
  public regionFilterControl: FormControl = new FormControl();
  public filteredRegion: ReplaySubject<Region[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();

  allClients: any;

  displayedColumns: string[] = ['NAME_CLIENT', 'LAST_NAME', 'LAST_2_NAME', 'TOTAL_DEBT', 'OPCIONES'];
  dataSource = new MatTableDataSource<Client>();

  isDesktop: boolean = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private authService: AuthService,
    private _regionService: RegionService,
    private deviceService: DeviceDetectorService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
    this.getUserLogged();
  }

  ngOnInit() {
    this.getRegions();
    this.getInfoDevice();
  }
  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  filtrarZona() {
    var result = this.form.value.region.NAME_REGION;
    this.form.controls['filtro'].setValue('');
    if (result != undefined) {
      this.applyFilterCustom(result);
    } else {
      this.applyFilterCustom('');
    }
  }

  applyFilterCustom(filterValue: string) {
    if (filterValue == '') {
      this.dataSource.data = this.allClients;
    } else {
      var newData = [];
      this.allClients.forEach(client => {
        if (client.NAME_REGION == filterValue) {
          newData.push(client);
        }
      });
      this.dataSource.data = newData;

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  form = new UntypedFormGroup({
    region: new UntypedFormControl([]),
    filtro: new UntypedFormControl(''),
  });

  getRegions() {
    this._regionService.getRegions().subscribe((regions: any) => {
      this.regions = regions;
      this.filteredRegion.next(this.regions.slice());
      this.regionFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTypePaymentComboBox();
      });
    });
  }

  protected filterTypePaymentComboBox() {
    if (!this.regions) {
      return;
    }

    let search = this.regionFilterControl.value;
    if (!search) {
      this.filteredRegion.next(this.regions);
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredRegion.next(this.regions.filter(x => x.NAME_REGION.toLowerCase().indexOf(search) > -1));
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    this.allClients = this.clients;
    self.dataSource = new MatTableDataSource(this.clients);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }
  getUserLogged() {
    this.usuario = this.userIsLogged();
    if (this.usuario.ID_TYPE_EMPLOYEE == 1) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  viewClient(c: Client) {
    this.selectClientEvent.emit(c);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
