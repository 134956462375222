import { Component, OnInit } from '@angular/core';
import {EmployeeService} from '../employee/employee.service'
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(
    private _employeeService : EmployeeService,
    private authService : AuthService
  ) { }
  
  userIsLogged(){
    return this.authService.getUserLogged()
  }

  ngOnInit() {
  }

}
