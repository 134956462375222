import { Component, OnInit } from '@angular/core';

import { ItemService } from './item.service';
import { Item } from './item';
import { Modal } from '../misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css'],
})
export class ItemComponent extends Modal implements OnInit {
  items: Item[] = [];
  edit: boolean = false;
  editSeleccionado: Item;

  constructor(private _itemService: ItemService, private modalServiceExt: NgbModal) {
    super(modalServiceExt);
  }

  ngOnInit() {
    this.getItems();
  }

  getItems() {
    this._itemService.getItems().subscribe((items: any) => (this.items = items));
  }
}
