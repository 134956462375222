import { Component,ViewChild, Input, SimpleChanges, OnInit, EventEmitter, Output } from '@angular/core';
import {RequestEditService} from '../request-edit.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RequestEdit } from '../request-edit';
import { AuthService } from 'src/app/services/auth.service';
import { formatDate } from '@angular/common';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent extends Modal implements OnInit {
  @Input() requestsPending : RequestEdit[];
  @Input() idRequest : number;

  @Output() editRequestSelected = new EventEmitter();

  displayedColumns: string[] = ['ID_REQUEST','NAME_CLIENT','LAST_NAME','LAST_2_NAME', 'NAME_REGION' , 'TOTAL_PRICE', 'DATE_REQUEST', 'OPCIONES'];
  dataSource = new MatTableDataSource<RequestEdit>();
  editButton : boolean = false
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  

  constructor(
    private requestEditService : RequestEditService,
    private authService : AuthService,
    private modalServiceExt: NgbModal
    ) {super(modalServiceExt
  ) }

  ngOnInit() {
    this.getRequestsPending();
    this.editButton = false
  }
  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.requestsPending);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
    self.dataSource.sortingDataAccessor = (item, property) =>{
      switch(property){
        case 'DATE_REQUEST': 
        var date = new Date(item.DATE_REQUEST)
        return date
        default: 
          
          return item[property];
      }
    };
    if (self.idRequest == -1){
      this.editButton = false
    }
  }

  userIsLogged(){
    return this.authService.getUserLogged()
  }

  getRequestsPending(){
    var self = this;
    this.requestEditService.getRequestPending(this.userIsLogged())
    .subscribe((x : any) =>{
      self.requestsPending = x;
      self.dataSource = new MatTableDataSource(self.requestsPending);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
      self.dataSource.sortingDataAccessor = (item, property) =>{
        switch(property){
          case 'DATE_REQUEST': 
          var date = new Date(item.DATE_REQUEST)
          return date
          
          default: 
            return item[property];
        }
      };
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editar (request : RequestEdit){
    this.editButton = true
    this.idRequest = request.ID_REQUEST
    this.editRequestSelected.emit(request);
  }

  without(request : RequestEdit){
    request.ID_TYPE_PROCESSING = 2;
    this.requestEditService.without(request).toPromise().
    then(x => {
      if (x['Response'] == 200){
        this.getRequestsPending()
      }
    }
    )
  }

}
