import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";

import {LineRequest} from './line-request'
import {Request} from '../request/request'

@Injectable({
  providedIn: 'root'
})
export class LineRequestService {

  constructor(
    private _http:HttpClient
  ) { }

  create(lineRequest:LineRequest)
  {
    return this._http.post('/lineRequest',lineRequest)
  }

  getLines(request: Request)
  {
    return this._http.get('/lineRequest/'+request.ID_REQUEST)
  }
}
