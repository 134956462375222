import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import {TypeTreasury} from './type-treasury'
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TypeTreasuryService {
  constructor(
    private _http:HttpClient
  ) {}

  create(typeTreasury: TypeTreasury){
    return this._http.post('/type_treasury',typeTreasury)
    /*.catch(err => {
      
  });*/
  }

  destroy(typeTreasury: TypeTreasury){
    return this._http.delete('/type_treasury/' + typeTreasury.ID_TYPE_TREASURY)
  }

  update(typeTreasury: TypeTreasury){
    return this._http.put('/type_treasury' ,typeTreasury)
  }

  getTypesTreasure(){
    return this._http.get('/type_treasury');
  }

  getTypeTreasure(typeTreasury: TypeTreasury){
    return this._http.get('/type_treasury/' + typeTreasury.ID_TYPE_TREASURY);
  }
}
