import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { TypeExpense } from './type-expense'
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TypeExpenseService {

  constructor(
    private _htpp:HttpClient
  ) { }

  create(typeExpense: TypeExpense){
    return this._htpp.post('/type_expenses',typeExpense)
  }

  destroy(typeExpense: TypeExpense){
    return this._htpp.delete('/type_expenses/' + typeExpense.ID_TYPE_EXPENSE)
  }

  update(typeExpense: TypeExpense){
    return this._htpp.put('/type_expenses' ,typeExpense)
  }

  getTypeExpenses(){
    return this._htpp.get('/type_expenses')
  }

  getTypeExpense(typeExpense: TypeExpense){
    return this._htpp.get('/type_expenses/' + typeExpense.ID_TYPE_EXPENSE)
  }
}
