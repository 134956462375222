import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Provider } from '../provider';
import { ProviderService } from '../provider.service';

@Component({
  selector: 'app-provider-edit',
  templateUrl: './provider-edit.component.html',
  styleUrls: ['./provider-edit.component.css']
})
export class ProviderEditComponent implements OnInit {
  @Input() provider : Provider;
  @Output() updateProvider = new EventEmitter();
  form = new UntypedFormGroup({
    name: new UntypedFormControl('',Validators.required),
    email: new UntypedFormControl('',[Validators.email]),
    phone: new UntypedFormControl('',[Validators.required,
        Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]')])  
  });

  editProvider: Provider = new Provider;
  constructor(
    private _providerService : ProviderService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }

  ngOnInit() {
    this.clone();
    this.markFormGroupTouched(this.form)
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  clone()
  {
    Object.assign(this.editProvider,this.provider);
    this.form.controls.name.setValue(this.editProvider.NAME_PROVIDER)
    this.form.controls.email.setValue(this.editProvider.EMAIL) 
    this.form.controls.phone.setValue(this.editProvider.PHONE_NUMBER)
  }

  update(){
    Swal.fire({
      icon: 'info',
      title: "Actualizando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.editProvider.editable = false;
    this.editProvider.NAME_PROVIDER = this.form.value.name
    this.editProvider.EMAIL = this.form.value.email
    this.editProvider.PHONE_NUMBER = this.form.value.phone

    var self = this;
    this._providerService.update(this.editProvider)
    .subscribe(function (value) {
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la edición. Nombre duplicado'
        })
      }else{
        self.popUpUpdate()
      }
    });
  }
  popUpUpdate(){
    Swal.fire({
      icon: 'success',
      title: 'Proveedor actualizado',
      text: 'El proveedor fué actualizado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      this.updateProvider.emit()
    })
  }

}
