import { Component, OnInit, Input } from '@angular/core';
import {TYPESTATEREQUESTComponent} from '../type-state-request.component'
import {TYPESTATEREQUESTService} from '../type-state-request.service'
import {TYPESTATEREQUEST} from '../type-state-request'
@Component({
  selector: 'app-tabla-type-state-request',
  templateUrl: './tabla-type-state-request.component.html',
  styleUrls: ['./tabla-type-state-request.component.css']
})
export class TablaTypeStateRequestComponent implements OnInit {
  @Input() typeStateRequest : TYPESTATEREQUEST[];
  public typeStateSeleccionado : TYPESTATEREQUEST
  display = 'none'
  typeStateEliminar : TYPESTATEREQUEST;

  constructor(
    private  tYPESTATEREQUESTService : TYPESTATEREQUESTService,
    private tYPESTATEREQUESTComponent : TYPESTATEREQUESTComponent
  ) { }

  ngOnInit() {
    this.getTypeStateRequests()
  }

  editar(p:TYPESTATEREQUEST){
    this.tYPESTATEREQUESTComponent.editTypeStateRequest(p)
  }
  eliminarTypeStateRequest()
  {
    this.tYPESTATEREQUESTService.destroy(this.typeStateEliminar).
    subscribe(status =>
      this.getTypeStateRequests())
  }
  getTypeStateRequests()
  {
    this.tYPESTATEREQUESTService.getTypeStateRequests()
    .subscribe((x:any) => this.typeStateRequest = x);
  }

   //Model implementation
   openModalDialog(nombre:TYPESTATEREQUEST){
     this.typeStateEliminar = nombre;
    this.display = 'block';
  }

  closeModalDialog(){
    this.display = 'none';
  }
}
