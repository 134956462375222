import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TypeEmployee } from 'src/app/type-employee/type-employee';
import { TypeEmployeeService } from 'src/app/type-employee/type-employee.service';
import Swal from 'sweetalert2';
import { TypeExpense } from '../type-expense';
import { TypeExpenseService } from '../type-expense.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-type-expense-new',
  templateUrl: './type-expense-new.component.html',
  styleUrls: ['./type-expense-new.component.css'],
})
export class TypeExpenseNewComponent implements OnInit {
  @Output() updateTypeExpense = new EventEmitter();
  newTypeExpense = new TypeExpense();
  typeEmployees: TypeEmployee[] = [];
  form = new UntypedFormGroup({
    tipoGasto: new UntypedFormControl('', Validators.required),
    tipoEmpleado: new UntypedFormControl([], Validators.required),
    descripcion: new UntypedFormControl('', Validators.required),
  });
  isDesktop: boolean = false;

  public typeEmployeeFilterControl: FormControl = new FormControl();
  public filteredtypeEmployee: ReplaySubject<TypeEmployee[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();

  constructor(
    private _typeExpenseService: TypeExpenseService,
    private _typeEmployee: TypeEmployeeService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.getTypeEmploye();
    this.markFormGroupTouched(this.form);
    this.getInfoDevice();
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  getTypeEmploye() {
    this._typeEmployee.getTypeEmployees().subscribe((x: any) => {
      this.typeEmployees = x;
      this.filteredtypeEmployee.next(this.typeEmployees.slice());
      //Apply changes subscripted to the filter
      this.typeEmployeeFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTypeItemComboBox();
      });
    });
  }

  protected filterTypeItemComboBox() {
    if (!this.typeEmployees) {
      return;
    }

    let search = this.typeEmployeeFilterControl.value;
    if (!search) {
      this.filteredtypeEmployee.next(this.typeEmployees.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredtypeEmployee.next(
      this.typeEmployees.filter(typeEmployee => typeEmployee.NAME_TYPE_EMPLOYEE.toLowerCase().indexOf(search) > -1)
    );
  }

  create() {
    Swal.fire({
      icon: 'info',
      title: 'Creando tipo de gasto...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.newTypeExpense.DESCRIPTION = this.form.value.descripcion;
    this.newTypeExpense.NAME_TYPE_EXPENSE = this.form.value.tipoGasto;
    this.newTypeExpense.ID_TYPE_EMPLOYEE = this.form.value.tipoEmpleado.ID_TYPE_EMPLOYEE;
    var self = this;
    this._typeExpenseService.create(self.newTypeExpense).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción. Nombre de tipo de gasto duplicado',
        });
      } else {
        self.popUpCreate();
        self.form.reset();
        self.newTypeExpense = new TypeExpense();
        self.updateTypeExpense.emit();
      }
    });
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  popUpCreate() {
    Swal.fire({
      icon: 'success',
      title: 'Tipo de gasto agregado',
      text: 'El tipo de gasto fué agregado correctamente',
    });
  }
}
