import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import {TypeWork} from './type-work'
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class TypeWorkService {

  constructor(
    private _htpp:HttpClient
  ) { }

  create(type: TypeWork){
    return this._htpp.post('/type_work',type)
  }

  destroy(type: TypeWork){
    return this._htpp.delete('/type_work/' + type.ID_TYPE_WORK)
  }

  update(type: TypeWork){
    return this._htpp.put('/type_work' ,type)
  }

  getTypesWork(){
    return this._htpp.get('/type_work')
  }

  getTypeWork(type: TypeWork){
    return this._htpp.get('/type_work/' + type.ID_TYPE_WORK)
  }
}
