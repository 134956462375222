import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Expense } from '../expense';
import { ExpenseService } from '../expense.service';
import { EmployeeService } from '../../employee/employee.service';
import { Employee } from '../../employee/employee';
import { TypeExpenseService } from '../../type-expense/type-expense.service';
import { TypeExpense } from '../../type-expense/type-expense';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-expense-new',
  templateUrl: './expense-new.component.html',
  styleUrls: ['./expense-new.component.css'],
})
export class ExpenseNewComponent implements OnInit {
  @Output() updateExpenses = new EventEmitter();
  newExpense = new Expense();
  employee: Employee;
  form = new UntypedFormGroup({
    id_employee: new UntypedFormControl('', Validators.required),
    id_type_expense: new UntypedFormControl([], Validators.required),
    date_expense: new UntypedFormControl('', Validators.required),
    description_expense: new UntypedFormControl('', [Validators.required]),
    voucher: new UntypedFormControl([]),
    expense: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
  });

  public typeExpenseFilterControl: FormControl = new FormControl();
  public filteredTypeExpense: ReplaySubject<TypeExpense[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  TypeExpenses: TypeExpense[] = [];
  Employees: Employee[] = [];
  deviceInfo = null;
  isDesktop: boolean = false;

  maxDate: string;
  constructor(
    private _expenseService: ExpenseService,
    private _employeeService: EmployeeService,
    private _typeExpenseService: TypeExpenseService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private deviceService: DeviceDetectorService
  ) {
    this.getInfoDevice();
  }

  ngOnInit() {
    this.getUserLogged();
    this.getTypesExpense();
    var date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.form.controls.date_expense.setValue(date);
    this.maxDate = date;
    this.form.controls.voucher.setValue([]);
    this.markFormGroupTouched(this.form);
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }

  getUserLogged() {
    this.employee = this.userIsLogged();
    this.form.controls.id_employee.setValue(this.employee.NAME_EMPLOYEE);
  }

  setDescription() {
    var idExpense = this.form.value.id_type_expense.ID_TYPE_EXPENSE;
    if (idExpense != null) {
      var element = this.TypeExpenses.find(x => x.ID_TYPE_EXPENSE == idExpense);
      this.form.controls.description_expense.setValue(element.DESCRIPTION);
    } else {
      this.form.controls.description_expense.setValue('');
    }
  }

  getTypesExpense() {
    this._typeExpenseService.getTypeExpenses().subscribe((x: any) => {
      this.TypeExpenses = x;
      this.TypeExpenses.forEach(element => {
        if (element.ID_TYPE_AVAILABLE == 2 || (this.employee.ID_TYPE_EMPLOYEE == 2 && element.ID_TYPE_EMPLOYEE == 1)) {
          element.disabled = true;
        }
      });

      this.filteredTypeExpense.next(this.TypeExpenses.slice());
      // Apply changes subscribed to the filter
      this.typeExpenseFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTypeTreasuryComboBox();
      });
    });
  }

  protected filterTypeTreasuryComboBox() {
    if (!this.TypeExpenses) {
      return;
    }

    let search = this.typeExpenseFilterControl.value;
    if (!search) {
      this.filteredTypeExpense.next(this.TypeExpenses.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredTypeExpense.next(
      this.TypeExpenses.filter(expense => expense.NAME_TYPE_EXPENSE.toLowerCase().indexOf(search) > -1)
    );
  }
  create() {
    Swal.fire({
      icon: 'info',
      title: 'Generando el gasto...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.newExpense.DATE_EXPENSE = this.form.value.date_expense;
    this.newExpense.DESCRIPTION_EXPENSE = this.form.value.description_expense;
    this.newExpense.EXPENSE = this.form.value.expense;
    this.newExpense.ID_EMPLOYEES = this.employee.ID_EMPLOYEE;
    this.newExpense.ID_TYPE_EXPENSE = this.form.value.id_type_expense.ID_TYPE_EXPENSE;
    this.newExpense.VOUCHER = this.form.value.voucher;
    var self = this;
    this._expenseService.create(this.newExpense).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción. Intente más tarde',
        });
      } else {
        self.popUpCreate();
        self.form.reset();
        self.ngOnInit();
        self.newExpense = new Expense();
        self.updateExpenses.emit();
      }
    });
  }

  popUpCreate() {
    Swal.fire({
      icon: 'success',
      title: 'Gasto registrado',
      text: 'El gasto fué registrado correctamente',
    });
  }
}
