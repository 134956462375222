import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Employee } from '../employee';
import { TypeEmployeeService } from '../../type-employee/type-employee.service';
import { TypeEmployee } from '../../type-employee/type-employee';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { EmployeeService } from '../employee.service';
import Swal from 'sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-employee-new',
  templateUrl: './employee-new.component.html',
  styleUrls: ['./employee-new.component.css'],
})
export class EmployeeNewComponent implements OnInit {
  @Output() updateEmployee = new EventEmitter();
  newEmployee = new Employee();
  typesEmployees: TypeEmployee[] = [];
  form = new UntypedFormGroup({
    tipoEmpleado: new UntypedFormControl([], Validators.required),
    userName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+')]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
    email: new UntypedFormControl('', [Validators.email]),
    name: new UntypedFormControl('', [Validators.required]),
    phone: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'),
    ]),
    direccion: new UntypedFormControl('', Validators.required),
  });
  isDesktop: boolean = false;
  constructor(
    private _typeEmployeeService: TypeEmployeeService,
    private _employeeService: EmployeeService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.getTypesEmployees();
    this.markFormGroupTouched(this.form);
    this.getInfoDevice();
  }
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  getTypesEmployees() {
    this._typeEmployeeService
      .getTypeEmployees()
      .subscribe((typesEmployees: any) => (this.typesEmployees = typesEmployees));
  }

  create() {
    Swal.fire({
      icon: 'info',
      title: 'Generando el colaborador...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.newEmployee.ADDRESS = this.form.value.direccion;
    this.newEmployee.EMAIL = this.form.value.email;
    this.newEmployee.ID_TYPE_EMPLOYEE = this.form.value.tipoEmpleado;
    this.newEmployee.NAME_EMPLOYEE = this.form.value.name;
    this.newEmployee.PHONE = this.form.value.phone;
    this.newEmployee.PWD = this.form.value.password;
    this.newEmployee.USERNAME = this.form.value.userName;
    var self = this;

    this._employeeService.create(this.newEmployee).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción. Nombre de usuario duplicado',
        });
      } else {
        self.popUpCreate();
        self.form.reset();
        self.newEmployee = new Employee();
        self.updateEmployee.emit();
      }
    });
  }

  popUpCreate() {
    Swal.fire({
      icon: 'success',
      title: 'Colaborador agregado',
      text: 'El colaborador fué agregado correctamente',
    });
  }
}
