import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Inventory } from '../inventory';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InventoryService } from '../inventory.service';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tabla-inventory',
  templateUrl: './tabla-inventory.component.html',
  styleUrls: ['./tabla-inventory.component.css']
})
export class TablaInventoryComponent extends Modal implements OnInit {
  @Input() inventory: Inventory[];
  
  public inventorySeleccionado: Inventory;
  public editable : Boolean = false;
  displayedColumns: string[] = ['NAME_ITEM', 'AMOUNT', 'NAME_PROVIDER', 'DATE_IN', 'OPCIONES'];

  dataSource = new MatTableDataSource<Inventory>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private _inventaryService : InventoryService,
    private modalServiceExt: NgbModal
    ) {super(modalServiceExt
      ) }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.inventory);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
    self.dataSource.sortingDataAccessor = (item, property) =>{
      switch(property){
        case 'DATE_IN': 
        var date = new Date(item.DATE_IN)
        return date
        
        default: 
          return item[property];
      }
    };
  }

  getInventory(){
    var self = this;
    this._inventaryService.getInventory()
    .subscribe((x:any) => {
      self.inventory = x;
      self.dataSource = new MatTableDataSource(self.inventory);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
      self.dataSource.sortingDataAccessor = (item, property) =>{
        switch(property){
          case 'DATE_IN': 
          var date = new Date(item.DATE_IN)
          return date
          
          default: 
            return item[property];
        }
      };
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
