import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Comment} from '../comment'

@Component({
  selector: 'app-comment-edit',
  templateUrl: './comment-edit.component.html',
  styleUrls: ['./comment-edit.component.css']
})
export class CommentEditComponent implements OnInit {
@Input() comentario: Comment;
@Output() updateCommentEvent = new EventEmitter();
editComment: Comment = new Comment;
  constructor() { }

  ngOnInit() {
    Object.assign(this.editComment, this.comentario);
  }

  update(){
    this.editComment.editable = false;
    this.updateCommentEvent.emit(this.editComment);
  }

}
