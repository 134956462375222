import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import Swal from 'sweetalert2';
import {Request} from './request';
import {FullRequest} from './full-request'
import {Email} from './email';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(   
     private _http:HttpClient
    ) { }

    createNewFullRequest(request: FullRequest){
      return this._http.post('/request',request)
    }

  getRequests(){
    return this._http.get('/request')
  }

  getRequest(request: Request){
    return this._http.get('/request/' + request.ID_CLIENT)
  }

  updateRequest(request: FullRequest){
    return this._http.put('/request' ,request)
  }
}
