import { Component, Input, OnInit } from '@angular/core';
import { Report } from '../report/report';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-request-state',
  templateUrl: './request-state.component.html',
  styleUrls: ['./request-state.component.css']
})
export class RequestStateComponent implements OnInit {

  constructor(private datePipe: DatePipe) {}
  ngOnInit(): void {
    console.log(this.requestState);
    this.setForm();
  }
  @Input() requestState: Report;

   form = new UntypedFormGroup({
    nameEmployee: new UntypedFormControl(''),
    nameTypeRequest: new UntypedFormControl(''),
    nameStateReady: new UntypedFormControl(''),
    nameTypeCollectable: new UntypedFormControl(''),
    nameType: new UntypedFormControl(''),
    dateDelivery: new UntypedFormControl(''),
  });

  setForm(): void {
    var date = 'No entregada';
    if (this.requestState.DATE_DELIVERY) {
      //Apply pipe to date with format 'dd/MM/yyyy, h:mm:ss'
      date = this.datePipe.transform(this.requestState.DATE_DELIVERY, 'dd/MM/yyyy, h:mm:ss');
    }
    this.form.setValue({
      nameEmployee: this.requestState.NAME_EMPLOYEE,
      nameTypeRequest: this.requestState.NAME_TYPE_REQUEST,
      nameStateReady: this.requestState.NAME_STATE_READY,
      nameTypeCollectable: this.requestState.NAME_TYPE_COLLECTABLE,
      nameType: this.requestState.NAME_TYPE,
      dateDelivery: date,
    })
  }

}
