import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";

import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

import { Provider } from './provider';
@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(
    private _http:HttpClient
  ) { }

  create(provider: Provider){
    return this._http.post('/provider',provider)
  }

  destroy(provider: Provider){
    return this._http.delete('/provider/' + provider.ID_PROVIDER)
  }

  update(provider: Provider){
    return this._http.put('/provider' ,provider)
  }

  getProviders(){
    return this._http.get('/provider')
  }
}
