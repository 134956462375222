import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {EmployeeService} from '../employee/employee.service'
import {Employee} from '../employee/employee'
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-employee-inicio',
  templateUrl: './employee-inicio.component.html',
  styleUrls: ['./employee-inicio.component.css']
})
export class EmployeeInicioComponent implements OnInit {
  employee : Employee
  employeeReady : boolean = false;
  constructor(
    private authService : AuthService) {
    }

  ngOnInit() {
    this.userIsLogged()
  }

  userIsLogged(){
    const employee : Employee = this.authService.getUserLogged()
    if(employee != null){
      this.employee = employee
      this.employeeReady = true;
    }
  }

}
