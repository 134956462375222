import { Component, Input, OnInit } from '@angular/core';
import { Provider } from '../provider';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-provider-information',
  templateUrl: './provider-information.component.html',
  styleUrls: ['./provider-information.component.css']
})
export class ProviderInformationComponent implements OnInit {
 @Input() provider : Provider;
   form = new UntypedFormGroup({
    idProvider: new UntypedFormControl('', Validators.required),
    nameProvider: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.email]),
    phoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]')]),
  });
  constructor() {}
   ngOnInit(): void {
    this.setForm();
  }
  setForm() {
    this.form.controls.idProvider.setValue(this.provider.ID_PROVIDER);
    this.form.controls.nameProvider.setValue(this.provider.NAME_PROVIDER);
    this.form.controls.email.setValue(this.provider.EMAIL);
    this.form.controls.phoneNumber.setValue(this.provider.PHONE_NUMBER);
  }
}
