import { Component, OnInit, Input } from '@angular/core';
import {LineItem} from './line-item'
import {LineItemService} from './line-item.service'
import {Request} from '../request/request'

@Component({
  selector: 'app-line-item',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.css']
})
export class LineItemComponent implements OnInit {

  lines: LineItem[] = []
  @Input() request: Request;

  constructor(
    private _lineItemService:LineItemService
  ) { }

  ngOnInit() {
    this.getLines();
  }

  getLines()
  {
    this._lineItemService.getLines(this.request)
    .subscribe((lines:any) => this.lines = lines)
  }

  create(lineItem : LineItem)
  {
    this._lineItemService.create(lineItem)
    .subscribe(status => this.getLines())
  }

}
