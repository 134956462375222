import { Component, OnInit, Input } from '@angular/core';
import {LineRequest} from './line-request';
import {Request} from '../request/request'
import {LineRequestService} from './line-request.service'
@Component({
  selector: 'app-line-request',
  templateUrl: './line-request.component.html',
  styleUrls: ['./line-request.component.css']
})
export class LineRequestComponent implements OnInit {

  linesRequest: LineRequest[] = []
  @Input() request: Request;
  constructor(
    private _lineRequestService:LineRequestService
  ) { }

  ngOnInit() {
    this.getLines();
  }

  getLines()
  {
    this._lineRequestService.getLines(this.request)
    .subscribe((linesRequest : any) => this.linesRequest = linesRequest)
  }

  create(LineRequest : LineRequest)
  {
    this._lineRequestService.create(LineRequest)
    .subscribe(status => this.getLines())
  }
}
