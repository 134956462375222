import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { Provider } from '../provider';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProviderService } from '../provider.service';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-provider-table',
  templateUrl: './provider-table.component.html',
  styleUrls: ['./provider-table.component.css']
})
export class ProviderTableComponent extends Modal implements OnInit {
  @Input() providers: Provider[];

  public providerSeleccionado: Provider;
  public editable : Boolean = false;
  displayedColumns: string[] = ['ID_PROVIDER', 'NAME_PROVIDER', 'PHONE_NUMBER', 'OPCIONES'];

  dataSource = new MatTableDataSource<Provider>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private providerService: ProviderService, private modalServiceExt: NgbModal
  ){super(modalServiceExt)}

  ngOnInit() {
    this.getProviders();
  }

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.providers);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
  }

  getProviders(){
    var self = this;
    this.providerService.getProviders()
      .subscribe((x : any) => {
        self.providers = x;
        self.dataSource = new MatTableDataSource(self.providers);
        self.dataSource.paginator = self.paginator;
        self.dataSource.sort = self.sort;
      });
  }

  editProvider(provider:Provider){
    this.providerSeleccionado = provider;
    this.editable = true;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
