import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }

    const formattedValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue.replace('.', '\u00A0');
  }
}
