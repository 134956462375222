import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Client } from '../client';
import { RegionService } from '../../region/region.service';
import { Region } from '../../region/region';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { ClientService } from '../client.service';
import { CostaRicaService } from 'src/app/costa-rica/costa-rica.service';
import { Provincia } from 'src/app/costa-rica/provincia';
import { Canton } from 'src/app/costa-rica/canton';
import { Distrito } from 'src/app/costa-rica/distrito';
import Swal from 'sweetalert2';
import { Employee } from 'src/app/employee/employee';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CedulasService } from 'src/app/costa-rica/cedulas.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-client-new',
  templateUrl: './client-new.component.html',
  styleUrls: ['./client-new.component.css'],
})
export class ClientNewComponent implements OnInit {
  @Output() updateClient = new EventEmitter();

  cedulaPlaceholder = '909990999';
  cedulaLength = 9;
  cedulaLengthMax = 12;
  toggleMessage = 'Física';

  employeeLogged = new Employee();

  newClient = new Client();
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    juridica: new UntypedFormControl(false, Validators.required),
    tax: new UntypedFormControl(13, Validators.required),
    last_name: new UntypedFormControl('', Validators.required),
    last_2_name: new UntypedFormControl('', Validators.required),
    region: new UntypedFormControl([], Validators.required),
    direccion: new UntypedFormControl('', Validators.required),
    provincia: new UntypedFormControl([], Validators.required),
    canton: new UntypedFormControl({ value: [], disabled: true }, Validators.required),
    distrito: new UntypedFormControl({ value: [], disabled: true }, Validators.required),
    email: new UntypedFormControl('', [Validators.email]),
    cedula: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('\\b[1-9]\\d{11}\\b|\\b[1-9]\\d{10}\\b|\\b[1-9]\\d{8}\\b'),
    ]),
    phone: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'),
    ]),
  });

  regions: Region[] = [];
  provincias: Provincia[] = [];
  cantones: Canton[] = [];
  distritos: Distrito[] = [];
  taxes = [
    { label: '13', value: '13', checked: true },
    { label: '4', value: '4', checked: false },
    { label: '2', value: '2', checked: false },
    { label: '1', value: '1', checked: false },
    { label: '0', value: '0', checked: false },
  ];

  isDesktop: boolean = false;
  public regionFilterControl: FormControl = new FormControl();
  public filteredRegion: ReplaySubject<Region[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();

  constructor(
    private _clientService: ClientService,
    private cdr: ChangeDetectorRef,
    private _regionService: RegionService,
    private _crService: CostaRicaService,
    private _crCedulaService: CedulasService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService
  ) {
    this.form.get('name').valueChanges.subscribe(() => {
      this.form.get('name').patchValue(this.form.get('name').value.toUpperCase(), { emitEvent: false });
    });
    this.form.get('last_name').valueChanges.subscribe(() => {
      this.form.get('last_name').patchValue(this.form.get('last_name').value.toUpperCase(), { emitEvent: false });
    });
    this.form.get('last_2_name').valueChanges.subscribe(() => {
      this.form.get('last_2_name').patchValue(this.form.get('last_2_name').value.toUpperCase(), { emitEvent: false });
    });
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }
  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  clientExceptionForAdmins() {
    this.employeeLogged = this.userIsLogged();
    if (this.employeeLogged.ID_TYPE_EMPLOYEE == 1) {
      //admin
      this.form.controls.provincia.clearValidators();
      this.form.controls.canton.clearValidators();
      this.form.controls.distrito.clearValidators();
      this.form.controls.direccion.clearValidators();

      this.form.controls.provincia.updateValueAndValidity();
      this.form.controls.canton.updateValueAndValidity();
      this.form.controls.distrito.updateValueAndValidity();
      this.form.controls.direccion.updateValueAndValidity();

      //Set region Taller as default
      var regionDefault = this.regions.find(region => region.ID_REGION == 1);
      this.form.controls.region.setValue(regionDefault);
      this.form.controls['region'].markAsTouched();
    }
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  async ngOnInit() {
    await this.getRegions();
    this.getProvince();
    this.markFormGroupTouched(this.form);
    this.getInfoDevice();
  }

  create() {
    Swal.fire({
      icon: 'info',
      title: 'Generando el cliente...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.newClient.NAME_CLIENT = this.form.value.name;
    this.newClient.JURIDICA = this.form.value.juridica;
    if (!this.newClient.JURIDICA) {
      this.newClient.LAST_NAME = this.form.value.last_name;
      this.newClient.LAST_2_NAME = this.form.value.last_2_name;
    } else {
      this.newClient.LAST_NAME = '';
      this.newClient.LAST_2_NAME = '';
    }

    this.storeProvincia();
    this.storeCanton();
    this.storeDistrito();

    this.newClient.ID_REGION = this.form.value.region.ID_REGION;
    this.newClient.ADDRESS = this.form.value.direccion;
    this.newClient.EMAIL = this.form.value.email;
    this.newClient.PHONE_NUMBER = this.form.value.phone;
    this.newClient.IDN = this.form.value.cedula;

    this.newClient.TAX = this.form.value.tax / 100;

    var self = this;
    this._clientService.create(self.newClient).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción. Revisar la cédula',
        });
      } else {
        self.popUpCreate();
        self.form.reset();
        self.form.controls.region.setValue([]);
        self.form.controls.provincia.setValue([]);
        self.form.controls.canton.disable();
        self.form.controls.canton.setValue([]);
        self.form.controls.distrito.disable();
        self.form.controls.distrito.setValue([]);
        self.form.controls.email.setValue('');
        self.form.controls.direccion.setValue('');
        self.form.controls.region.setValue(1);
        self.form.controls.tax.setValue(13);
        self.form.controls.juridica.setValue(false);
        self.newClient = new Client();
        self.updateClient.emit();
      }
    });
  }
  storeProvincia() {
    if (this.form.value.provincia == '' || this.form.value.canton == undefined) {
      this.newClient.PROVINCIA = 'N/R';
      this.newClient.ID_PROVINCIA = -1;
    } else {
      this.newClient.ID_PROVINCIA = this.form.value.provincia;
      var prov = this.provincias.find(element => element.ID_PROVINCIA == this.newClient.ID_PROVINCIA);
      this.newClient.PROVINCIA = prov.PROVINCIA;
    }
  }

  storeCanton() {
    if (this.form.value.canton == '' || this.form.value.canton == undefined) {
      this.newClient.CANTON = 'N/R';
      this.newClient.ID_CANTON = -1;
    } else {
      this.newClient.ID_CANTON = this.form.value.canton;
      var canton = this.cantones.find(element => element.ID_CANTON == this.newClient.ID_CANTON);
      this.newClient.CANTON = canton.CANTON;
    }
  }

  storeDistrito() {
    if (this.form.value.distrito == '' || this.form.value.distrito == undefined) {
      this.newClient.DISTRITO = 'N/R';
      this.newClient.ID_DISTRITO = -1;
    } else {
      this.newClient.ID_DISTRITO = this.form.value.distrito;
      var distrito = this.distritos.find(element => element.ID_DISTRITO == this.newClient.ID_DISTRITO);
      this.newClient.DISTRITO = distrito.DISTRITO;
    }
  }
  popUpCreate() {
    Swal.fire({
      icon: 'success',
      title: 'Cliente agregado',
      text: 'El cliente fué agregado correctamente',
    });
  }

  getProvince() {
    this._crService.getProvincias().subscribe(provincias => {
      this.provincias = [];
      for (var key in provincias) {
        var provincia: Provincia = new Provincia();
        provincia.ID_PROVINCIA = +key;
        provincia.PROVINCIA = provincias[key];
        this.provincias.push(provincia);
      }
    });
  }

  getCantones() {
    var provincia: number = this.form.value.provincia;
    if (provincia != null) {
      this._crService.getCantones(provincia).subscribe(cantones => {
        this.cantones = [];
        for (var key in cantones) {
          var canton: Canton = new Canton();
          canton.ID_CANTON = +key;
          canton.CANTON = cantones[key];
          this.cantones.push(canton);
        }
        this.form.controls.canton.enable();
        this.form.controls.canton.setValue([]);
        this.form.controls.distrito.disable();
        this.form.controls.distrito.setValue([]);
      });
    } else {
      this.form.controls.canton.disable();
      this.form.controls.canton.setValue([]);
      this.form.controls.distrito.disable();
      this.form.controls.distrito.setValue([]);
    }
  }
  getDistritos() {
    var provincia: number = this.form.value.provincia;
    var canton: number = this.form.value.canton;
    this._crService.getDistritos(provincia, canton).subscribe(distritos => {
      this.distritos = [];
      for (var key in distritos) {
        var distrito: Distrito = new Distrito();
        distrito.ID_DISTRITO = +key;
        distrito.DISTRITO = distritos[key];
        this.distritos.push(distrito);
      }
      this.form.controls.distrito.enable();
      this.form.controls.distrito.setValue([]);
    });
  }
  getRegions() {
    this._regionService.getRegions().subscribe((regions: any) => {
      this.regions = regions;
      this.clientExceptionForAdmins();
      this.regions.forEach(element => {
        if (element.ID_TYPE_AVAILABLE == 2) {
          element.disabled = true;
        }
      });

      this.filteredRegion.next(this.regions.slice());
      this.regionFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTypeTreasuryComboBox();
      });
    });
  }

  protected filterTypeTreasuryComboBox() {
    if (!this.regions) {
      return;
    }

    let search = this.regionFilterControl.value;
    if (!search) {
      this.filteredRegion.next(this.regions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredRegion.next(this.regions.filter(region => region.NAME_REGION.toLowerCase().indexOf(search) > -1));
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  focusOut() {
    if (!this.form.controls.cedula.invalid) {
      this._crCedulaService.getNombreClientes(this.form.value.cedula).subscribe(x => {
        if (x['results'].length > 0) {
          var persona = x['results'][0];

          if (this.form.value.juridica) {
            this.form.controls.name.setValue(persona['fullname']);
          } else {
            this.form.controls.name.setValue(persona['firstname']);
            this.form.controls.last_name.setValue(persona['lastname1']);
            this.form.controls.last_2_name.setValue(persona['lastname2']);
          }
        }
      });
    }
  }

  changeCedula(e) {
    if (e) {
      this.toggleMessage = 'Juridica';
      this.form.controls['cedula'].setValidators([Validators.required, Validators.pattern('[1-9][0-9]{9}')]);
      this.cedulaPlaceholder = '399991111';
      this.cedulaLength = 10;
      this.cedulaLengthMax = 10;
      this.form.controls['last_name'].clearValidators();
      this.form.controls['last_name'].updateValueAndValidity();
      this.form.controls['last_2_name'].clearValidators();
      this.form.controls['last_2_name'].updateValueAndValidity();
    } else {
      this.toggleMessage = 'Física';
      this.form.controls['cedula'].setValidators([
        Validators.required,
        Validators.pattern('\\b[1-9]\\d{11}\\b|\\b[1-9]\\d{10}\\b|\\b[1-9]\\d{8}\\b'),
      ]);
      this.cedulaPlaceholder = '909990999';
      this.cedulaLength = 9;
      this.cedulaLengthMax = 12;
      this.form.controls['last_name'].setValidators([Validators.required]);
      this.form.controls['last_name'].updateValueAndValidity();
      this.form.controls['last_2_name'].setValidators([Validators.required]);
      this.form.controls['last_2_name'].updateValueAndValidity();
    }
  }
}
