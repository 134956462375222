import { Component, OnInit, ViewChild, Input, SimpleChanges, Output } from '@angular/core';
import {EmployeeComponent} from '../employee.component'
import {EmployeeService} from '../employee.service'
import {Employee} from '../employee'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tabla-employee',
  templateUrl: './tabla-employee.component.html',
  styleUrls: ['./tabla-employee.component.css']
})

export class TablaEmployeeComponent extends Modal implements OnInit {
  @Input() employees : Employee[];
  public employeeSeleccionado : Employee;
  public editable : Boolean = false;
  checkValue = false;
  employeeEliminar : Employee;
  displayedColumns: string[] = ['ID_EMPLOYEE', 'NAME_EMPLOYEE','PHONE', 'OPCIONES'];
  dataSource = new MatTableDataSource<Employee>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private employeeService : EmployeeService,
    private employeeComponent : EmployeeComponent, private modalServiceExt: NgbModal
    ) {super(modalServiceExt
  ) }

  ngOnInit() {
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  
  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.employees);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
}

isAvailable(){
  if(this.checkValue)
  {
    var newEmployees : Employee[] = [];
    this.employees.forEach(element => {
      if(element.ID_TYPE_AVAILABLE == 1)
      {
        newEmployees.push(element);
      }
    });
    this.dataSource = new MatTableDataSource(newEmployees);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }else{
    this.dataSource = new MatTableDataSource(this.employees);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

  editar(employee:Employee){
    this.employeeSeleccionado = employee;
    this.editable = true;
  }

  getEmployees()
  {
    var self = this;
    this.employeeService.getEmployees()
    .subscribe((x:any) =>{
      self.employees = x;
      self.dataSource = new MatTableDataSource(self.employees);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;  
    });
  }
  
}
