import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import {Expense} from './expense'
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

import { Employee } from '../employee/employee';
@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  constructor(
    private _http:HttpClient
  ) { }

  create(expense: Expense){
    return this._http.post('/Expense',expense)
  }

  update(expense: Expense){
    return this._http.put('/Expense' ,expense)
  }

  destroy(expense: Expense){
    return this._http.delete('/Expense/' + expense.ID_EXPENSE)
  }

  getExpenses(){
    return this._http.get('/Expense')
  }

  getExpensesByEmployee(employee : Employee){
    return this._http.post('/ExpenseByEmployee', employee)
  }

  getExpense(expense: Expense){
    return this._http.get('/Expense/' + expense.ID_EXPENSE)
  }
}
