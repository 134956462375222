export class TypeWork {
    constructor(
        public ID_TYPE_WORK: number = 0,
        public ID_TYPE_AVAILABLE: number = 0,
        public NAME_TYPE_WORK: string = "",
        public DESCRIPTION: string = "",
        public PRICE: number = 0,
        public disabled : boolean = false,
        public editable: boolean = false
    ){}
}
