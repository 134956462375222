import { Component, OnInit } from '@angular/core';
import { RegionService } from './region.service';
import { Region } from './region';
import { Modal } from '../misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent extends Modal implements OnInit {

  agregadoNew: boolean = true
  agregadoEdit: boolean = true
  exitoNew: boolean = false
  exitoEdit: boolean = false
  
  regions: Region[] = []
  edit : boolean = false
  editSeleccionado : Region

  constructor(
    private _regionService: RegionService, private modalServiceExt: NgbModal
    ) {super(modalServiceExt
  ) }

  ngOnInit() {
    this.getRegions();
  }
  editRegion(region: Region)
  {
    this.edit = true;
    this.editSeleccionado = region;
  }

  getRegions(){
    this._regionService.getRegions()
    .subscribe((regions:any) => this.regions = regions)
  }

}
