import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TypeEmployee } from '../type-employee';

@Component({
  selector: 'app-type-employee-edit',
  templateUrl: './type-employee-edit.component.html',
  styleUrls: ['./type-employee-edit.component.css']
})
export class TypeEmployeeEditComponent implements OnInit {
  @Input() typeEmployee: TypeEmployee;
  @Output() updateTypeEmployeeEvent = new EventEmitter();
  editTypeEmployee: TypeEmployee = new TypeEmployee;

  constructor() { }

  ngOnInit() {
    this.clone();
  }
  clone()
  {
    Object.assign(this.editTypeEmployee,this.typeEmployee);
   
  }
  cancelar()
  {
    var reg = new TypeEmployee();
    reg.ID_TYPE_EMPLOYEE = -1;
    this.updateTypeEmployeeEvent.emit(reg);
  }

  update(){
    this.editTypeEmployee.editable = false;
    this.updateTypeEmployeeEvent.emit(this.editTypeEmployee);
  }

}
