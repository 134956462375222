import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Provider } from '../provider';
import { ProviderService } from '../provider.service';

@Component({
  selector: 'app-provider-new',
  templateUrl: './provider-new.component.html',
  styleUrls: ['./provider-new.component.css']
})
export class ProviderNewComponent implements OnInit {
  @Output() updateProvider = new EventEmitter();

  newProvider = new Provider;
  form = new UntypedFormGroup({
    name: new UntypedFormControl('',Validators.required),
    email: new UntypedFormControl('',[Validators.email]),
    phone: new UntypedFormControl('',[Validators.required,
        Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]')])  
  });
  constructor(
    private _providerService : ProviderService
  ) { }

  ngOnInit() {
    this.markFormGroupTouched(this.form)
  }
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  create(){
    Swal.fire({
      icon: 'info',
      title: "Creando provedor...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.newProvider.NAME_PROVIDER = this.form.value.name
    this.newProvider.EMAIL = this.form.value.email
    this.newProvider.PHONE_NUMBER = this.form.value.phone

    var self = this;
    this._providerService.create(self.newProvider)
    .subscribe(function (value){
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción. Nombre de proveedor duplicado'
        })
      }else{
        self.popUpCreate()
        self.form.reset();
        self.newProvider = new Provider;
        self.updateProvider.emit()
      }
    })
  }

  popUpCreate(){
    Swal.fire({
      icon: 'success',
      title: 'Proveedor agregado',
      text: 'El proveedor fué agregado correctamente'
    })    
  }
}
