import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { Employee } from '../employee';
import { TypeEmployeeService } from '../../type-employee/type-employee.service';
import { TypeEmployee } from '../../type-employee/type-employee';
import { EmployeeService } from '../employee.service';
import Swal from 'sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { TypeExpense } from 'src/app/type-expense/type-expense';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.css'],
})
export class EmployeeEditComponent implements OnInit {
  @Input() employee: Employee;
  @Output() updateEmployee = new EventEmitter();
  editEmployee: Employee = new Employee();
  typesEmployees: TypeEmployee[] = [];

  public typeEmployeeFilterControl: FormControl = new FormControl();
  public filteredTypeEmployee: ReplaySubject<TypeEmployee[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  form = new UntypedFormGroup({
    tipoEmpleado: new UntypedFormControl([], Validators.required),
    userName: new UntypedFormControl('', Validators.required),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
    email: new UntypedFormControl('', [Validators.email]),
    name: new UntypedFormControl('', [Validators.required]),
    phone: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'),
    ]),
    direccion: new UntypedFormControl('', Validators.required),
  });

  isDesktop: boolean = false;

  constructor(
    private _typeEmployeeService: TypeEmployeeService,
    private _employeeService: EmployeeService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.getTypesEmployees()
      .asObservable()
      .subscribe(x => {
        this.clone();
      });
    this.markFormGroupTouched(this.form);
    this.getInfoDevice();
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  clone() {
    Object.assign(this.editEmployee, this.employee);
    var typeEmployee = this.typesEmployees.find(x => x.ID_TYPE_EMPLOYEE == this.editEmployee.ID_TYPE_EMPLOYEE);
    this.form.controls.tipoEmpleado.setValue(typeEmployee);
    this.form.controls.userName.setValue(this.editEmployee.USERNAME);
    this.form.controls.password.setValue(this.editEmployee.PWD);
    this.form.controls.email.setValue(this.editEmployee.EMAIL);
    this.form.controls.name.setValue(this.editEmployee.NAME_EMPLOYEE);
    this.form.controls.phone.setValue(this.editEmployee.PHONE);
    this.form.controls.direccion.setValue(this.editEmployee.ADDRESS);
  }
  getTypesEmployees() {
    var typeEmployeeSubject = new Subject<TypeEmployee[]>();
    this._typeEmployeeService.getTypeEmployees().subscribe((typesEmployees: any) => {
      this.typesEmployees = typesEmployees;
      this.filteredTypeEmployee.next(this.typesEmployees.slice());
      // Apply changes subscribed to the filter
      this.typeEmployeeFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTypeEmployeeComboBox();
      });

      typeEmployeeSubject.next(this.typesEmployees);
    });
    return typeEmployeeSubject;
  }

  protected filterTypeEmployeeComboBox() {
    if (!this.typesEmployees) {
      return;
    }

    let search = this.typeEmployeeFilterControl.value;
    if (!search) {
      this.filteredTypeEmployee.next(this.typesEmployees.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredTypeEmployee.next(
      this.typesEmployees.filter(typeEmployee => typeEmployee.NAME_TYPE_EMPLOYEE.toLowerCase().indexOf(search) > -1)
    );
  }

  update() {
    Swal.fire({
      icon: 'info',
      title: 'Generando la actualización...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editEmployee.ADDRESS = this.form.value.direccion;
    this.editEmployee.EMAIL = this.form.value.email;
    this.editEmployee.ID_TYPE_EMPLOYEE = this.form.value.tipoEmpleado.ID_TYPE_EMPLOYEE;
    this.editEmployee.NAME_EMPLOYEE = this.form.value.name;
    this.editEmployee.PHONE = this.form.value.phone;

    this.editEmployee.PWD = this.form.value.password;
    if (this.editEmployee.PWD != this.employee.PWD) {
      this.editEmployee.editPWD = true;
    } else {
      this.editEmployee.editPWD = false;
    }
    this.editEmployee.USERNAME = this.form.value.userName;
    this.editEmployee.editable = false;
    var self = this;
    this._employeeService.update(this.editEmployee).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la edición. Nombre de usuario duplicado',
        });
      } else {
        self.popUpUpdate();
      }
    });
  }

  habilitar() {
    Swal.fire({
      icon: 'info',
      title: 'Generando la habilitación...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editEmployee.ID_TYPE_AVAILABLE = 1;
    this.editEmployee.editable = false;
    this.editEmployee.editPWD = false;
    var self = this;
    this._employeeService.update(this.editEmployee).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del tipo de trabajo',
        });
      } else {
        self.updateHabilitar();
      }
    });
  }

  deshabilitar() {
    Swal.fire({
      icon: 'info',
      title: 'Generando la deshabilitación...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editEmployee.ID_TYPE_AVAILABLE = 2;
    this.editEmployee.editable = false;
    this.editEmployee.editPWD = false;
    var self = this;
    this._employeeService.update(this.editEmployee).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del tipo de trabajo',
        });
      } else {
        self.updateDeshabilitar();
      }
    });
  }

  updateDeshabilitar() {
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'El colaborador deshabilitado.',
      text: 'El colaborador fué deshabilitado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      self.updateEmployee.emit();
    });
  }

  updateHabilitar() {
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'El colaborador habilitado.',
      text: 'El colaborador fué habilitado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      self.updateEmployee.emit();
    });
  }
  popUpUpdate() {
    Swal.fire({
      icon: 'success',
      title: 'Colaborador actualizado',
      text: 'El colaborador fué actualizado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      this.updateEmployee.emit();
    });
  }
}
