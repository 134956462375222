import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { Item } from '../item';
import { TypeItemService } from '../../type-item/type-item.service';
import { TypeItem } from '../../type-item/type-item';
import Swal from 'sweetalert2';
import { ItemService } from '../item.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-item-edit',
  templateUrl: './item-edit.component.html',
  styleUrls: ['./item-edit.component.css'],
})
export class ItemEditComponent implements OnInit {
  @Input() item: Item;
  @Output() updateItem = new EventEmitter();
  editItem: Item = new Item();
  isDesktop: boolean = false;
  typesItems: TypeItem[] = [];
  form = new UntypedFormGroup({
    tipoItem: new UntypedFormControl([], Validators.required),
    name: new UntypedFormControl('', Validators.required),
    precio: new UntypedFormControl('', [Validators.required, Validators.pattern('[1-9][0-9]*')]),
    cantidad: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
  }); //Use another control for filter data
  public tipoItemFilterControl: FormControl = new FormControl();
  public filteredTipoItem: ReplaySubject<TypeItem[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  constructor(
    private _typeItemService: TypeItemService,
    private _itemService: ItemService,
    private deviceService: DeviceDetectorService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }
  ngOnInit() {
    this.getTypeItems()
      .then(() => {
        this.clone();
      })
      .catch(error => {
        // handle error if getTypeItems() fails
      });
    this.markFormGroupTouched(this.form);
    this.getInfoDevice();
  }
  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  clone() {
    Object.assign(this.editItem, this.item);
    this.form.controls.name.setValue(this.editItem.NAME_ITEM);
    var item = this.typesItems.find(x => x.ID_TYPE_ITEM == this.editItem.ID_TYPE_ITEM);
    this.form.controls.tipoItem.setValue(item);
    this.form.controls.precio.setValue(this.editItem.PRICE);
    this.form.controls.cantidad.setValue(this.editItem.STOCK);
  }

  getTypeItems(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._typeItemService.getTypeItems().subscribe((typesItems: any) => {
        try {
          this.typesItems = typesItems;
          this.typesItems.forEach(element => {
            if (element.ID_TYPE_AVAILABLE == 2) {
              element.disabled = true;
            }
          });

          this.filteredTipoItem.next(this.typesItems.slice());

          // Apply changes subscribed to the filter
          this.tipoItemFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterTypeItemComboBox();
          });

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  protected filterTypeItemComboBox() {
    if (!this.typesItems) {
      return;
    }

    let search = this.tipoItemFilterControl.value;
    if (!search) {
      this.filteredTipoItem.next(this.typesItems.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredTipoItem.next(
      this.typesItems.filter(itemType => itemType.NAME_TYPE_ITEM.toLowerCase().indexOf(search) > -1)
    );
  }

  habilitar() {
    Swal.fire({
      icon: 'info',
      title: 'Habilitando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editItem.ID_TYPE_AVAILABLE = 1;
    var self = this;
    this._itemService.update(this.editItem).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del producto.',
        });
      } else {
        self.updateHabilitar();
      }
    });
  }

  deshabilitar() {
    Swal.fire({
      icon: 'info',
      title: 'Deshabilitando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editItem.ID_TYPE_AVAILABLE = 2;
    var self = this;
    this._itemService.update(this.editItem).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del producto.',
        });
      } else {
        self.updateDeshabilitar();
      }
    });
  }

  updateDeshabilitar() {
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'Producto habilitado.',
      text: 'El producto fué habilitado.',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      self.updateItem.emit();
    });
  }

  updateHabilitar() {
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'Tipo de trabajo habilitado.',
      text: 'El tipo de trabajo fué habilitado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      self.updateItem.emit();
    });
  }

  update() {
    Swal.fire({
      icon: 'info',
      title: 'Actualizando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editItem.NAME_ITEM = this.form.value.name;
    this.editItem.ID_TYPE_ITEM = this.form.value.tipoItem.ID_TYPE_ITEM;
    this.editItem.PRICE = this.form.value.precio;
    this.editItem.STOCK = this.form.value.cantidad;
    this.editItem.editable = false;
    var self = this;

    this._itemService.update(this.editItem).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la edición. Nombre duplicado',
        });
      } else {
        self.popUpUpdate();
      }
    });
  }
  popUpUpdate() {
    Swal.fire({
      icon: 'success',
      title: 'Item actualizado',
      text: 'El item fué actualizado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      this.updateItem.emit();
    });
  }
}
