import { Component, OnInit } from '@angular/core';
import {Expense} from '../expense/expense'
import {ExpenseService} from './expense.service';
import {EmployeeService} from '../employee/employee.service'
import {Employee} from '../employee/employee'
import {TypeExpenseService} from '../type-expense/type-expense.service'
import {TypeExpense} from '../type-expense/type-expense'


import { formatDate } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { Modal } from '../misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.css']
})
export class ExpenseComponent extends Modal implements OnInit {
  agregadoNew: boolean = true
  agregadoEdit: boolean = true
  exitoNew: boolean = false
  exitoEdit: boolean = false
  expenses: Expense[] = []
  employees : Employee[] = []
  typeExpense : TypeExpense[] = []
  edit : boolean = false
  editSeleccionado : Expense
  employee : Employee

  usuario : Employee


  constructor(
    private _expenseService : ExpenseService,
    private _employeeService : EmployeeService,
    private _typeExpenseService : TypeExpenseService,
    private authService : AuthService, 
    private modalServiceExt: NgbModal
    ) {super(modalServiceExt) }

  ngOnInit() {
    this.userIsLogged();
    this.getExpenses();
  }

  userIsLogged(){
    const employee : Employee = this.authService.getUserLogged()
    if(employee != null){
      this.usuario = employee
    }
  }

  tablePresentation()
  {
    for (var i = 0; i<this.expenses.length;i++)
    {
      var format = 'dd/MM/yyyy';
      const locale = 'en-US';
      var date = formatDate(new Date(this.expenses[i].DATE_EXPENSE),format,locale)
      this.expenses[i].FECHA_TABLA = date
      var empleado = this.employees.find( empleado => empleado.ID_EMPLOYEE ==  this.expenses[i].ID_EMPLOYEES)
      this.expenses[i].EMPLEADO_TABLA = empleado.NAME_EMPLOYEE
      var tipo = this.typeExpense.find( tipo => tipo.ID_TYPE_EXPENSE ==  this.expenses[i].ID_TYPE_EXPENSE)
      this.expenses[i].TIPO_GASTO_TABLA = tipo.NAME_TYPE_EXPENSE
    }
  }
  getExpenses()
  {
    if (this.usuario.ID_TYPE_EMPLOYEE == 1){
    this._expenseService.getExpenses()
    .subscribe((x:any) => {this.expenses = x
      this._employeeService.getEmployees()
      .subscribe((employees:any) => {this.employees = employees
        this._typeExpenseService.getTypeExpenses()
          .subscribe( (typeExpense:any) => {this.typeExpense = typeExpense
            this.tablePresentation()
        })
      
      })
      
    })
    }else{
      this._expenseService.getExpensesByEmployee(this.usuario)
      .subscribe((x:any) => {this.expenses = x
        this._employeeService.getEmployees()
        .subscribe((employees : any) => {this.employees = employees
          this._typeExpenseService.getTypeExpenses()
            .subscribe( (typeExpense: any) => {this.typeExpense = typeExpense
              this.tablePresentation()
          })
        
        })
        
      })
    }
  }
}
