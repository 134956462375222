import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import Swal from 'sweetalert2';
import {LineItem} from './line-item'
import {Request} from '../request/request'
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LineItemService {

  constructor(
    private _http:HttpClient
  ) { }

  create(lineItem:LineItem)
  {
    return this._http.post('/lineItem',lineItem)
  }

  getLines(request: Request)
  {
    return this._http.get('/lineItem/'+request.ID_REQUEST)
  }
}
