import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";



import { Employee } from '../employee/employee';
import { RequestEdit } from '../request-edit/request-edit';
@Injectable({
  providedIn: 'root'
})
export class RequestDisplayService {

  constructor(private _http:HttpClient) { }

  getRequestNotReady(employee : Employee){
    return this._http.post('/getRequestNotReady', employee)
  }
  getRequestReady(employee : Employee){
    return this._http.post('/getRequestReady', employee)
  }

  ready(request : RequestEdit){
    return this._http.put('/readyRequest', request);
  }
  
}
