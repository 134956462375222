import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Canton } from 'src/app/costa-rica/canton';
import { Distrito } from 'src/app/costa-rica/distrito';
import { Provincia } from 'src/app/costa-rica/provincia';
import { Client } from '../client';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css'],
})
export class ClientInfoComponent implements OnInit, OnChanges {
  @Input() client: Client;
  disable = true;
  toggleMessage = 'Personal';
  taxes = [
    { label: '13', value: '13', checked: true },
    { label: '4', value: '4', checked: false },
    { label: '2', value: '2', checked: false },
    { label: '1', value: '1', checked: false },
    { label: '0', value: '0', checked: false },
  ];
  provincias: Provincia[] = [];
  cantones: Canton[] = [];
  distritos: Distrito[] = [];
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    juridica: new UntypedFormControl(false, Validators.required),
    kindId: new UntypedFormControl('', Validators.required),
    tax: new UntypedFormControl(13, Validators.required),
    last_name: new UntypedFormControl('', Validators.required),
    last_2_name: new UntypedFormControl('', Validators.required),
    direccion: new UntypedFormControl('', Validators.required),
    region: new UntypedFormControl([], Validators.required),
    provincia: new UntypedFormControl([], Validators.required),
    canton: new UntypedFormControl({ value: [] }, Validators.required),
    distrito: new UntypedFormControl({ value: [] }, Validators.required),
    provinciaName: new UntypedFormControl([], Validators.required),
    cantonName: new UntypedFormControl({ value: [] }, Validators.required),
    distritoName: new UntypedFormControl({ value: [] }, Validators.required),
    email: new UntypedFormControl('', [Validators.email]),
    cedula: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('\\b[1-9]\\d{11}\\b|\\b[1-9]\\d{10}\\b|\\b[1-9]\\d{8}\\b'),
    ]),
    phone: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'),
    ]),
  });
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.setForm();
  }

  changeCedula(e) {
    if (e) {
      this.toggleMessage = 'Juridica';
    } else {
      this.toggleMessage = 'Personal';
    }
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.form.controls.name.setValue(this.client.NAME_CLIENT);
    this.form.controls.last_name.setValue(this.client.LAST_NAME);
    this.form.controls.last_2_name.setValue(this.client.LAST_2_NAME);
    this.form.controls.region.setValue(this.client.NAME_REGION);
    this.form.controls.direccion.setValue(this.client.ADDRESS);
    this.form.controls.email.setValue(this.client.EMAIL);
    this.form.controls.phone.setValue(this.client.PHONE_NUMBER);
    this.form.controls.provincia.setValue(this.client.ID_PROVINCIA);
    this.form.controls.canton.setValue(this.client.ID_CANTON);
    this.form.controls.distrito.setValue(this.client.ID_DISTRITO);
    this.form.controls.provinciaName.setValue(this.client.PROVINCIA);
    this.form.controls.cantonName.setValue(this.client.CANTON);
    this.form.controls.distritoName.setValue(this.client.DISTRITO);
    this.form.controls.cedula.setValue(this.client.IDN);
    this.changeCedula(this.client.JURIDICA);
    if (this.client.JURIDICA) {
      this.form.controls.kindId.setValue('Jurídica');
    } else {
      this.form.controls.kindId.setValue('Personal');
    }
    this.form.controls.tax.setValue(this.client.TAX * 100);
    var taxClient = String(this.client.TAX * 100);
    this.taxes = [
      { label: '13', value: '13', checked: true },
      { label: '4', value: '4', checked: false },
      { label: '2', value: '2', checked: false },
      { label: '1', value: '1', checked: false },
      { label: '0', value: '0', checked: false },
    ];

    this.taxes.forEach(element => {
      if (element.label === taxClient) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
    if (this.client.JURIDICA) {
      this.form.controls.juridica.setValue(true);
    } else {
      this.form.controls.juridica.setValue(false);
    }
  }
}
