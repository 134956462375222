import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Client } from 'src/app/client/client';
import { Item } from 'src/app/item/item';
import { Modal } from 'src/app/misc/modal';
import { RequestService } from '../request.service';
import { ItemService } from 'src/app/item/item.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSelectChange } from '@angular/material/select';
import { RequestMisc } from 'src/app/misc/requestMisc';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-select-item-request',
  templateUrl: './select-item-request.component.html',
  styleUrls: ['./select-item-request.component.css'],
})
export class SelectItemRequestComponent extends Modal implements OnInit {
  items: Item[] = [];
  @Input() factureForm: UntypedFormGroup;
  @Input() clientSelected: Client;
  @Input() isFromEditRequest: Boolean = false;
  public itemFilteredControl: FormControl = new FormControl();
  public filteredItem: ReplaySubject<Item[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();

  ngOnInit(): void {
    this.getItems();
  }

  constructor(
    private _requestService: RequestService,
    private _itemsService: ItemService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  getItems() {
    this._itemsService.getItems().subscribe((x: any) => {
      this.items = x;
      this.items.forEach(element => {
        if (element.ID_TYPE_AVAILABLE == 2) {
          element.disabled = true;
        }
      });
      this.filteredItem.next(this.items.slice());

      // Apply changes subscribed to the filter
      this.itemFilteredControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filteredItems();
      });
    });
  }

  protected filteredItems() {
    if (!this.items) {
      return;
    }

    let search = this.itemFilteredControl.value;
    if (!search) {
      this.filteredItem.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredItem.next(this.items.filter(typeWork => typeWork.NAME_ITEM.toLowerCase().indexOf(search) > -1));
  }

  removeLineSelected(valuesFromMatSelect: Array<Object>) {
    var id = this.factureForm.value.idItem;
    for (var i = 0; i < this.factureForm.controls.itemsSeleccionados.value.length; i++) {
      var del = true;
      for (var j = 0; j < id.length; j++) {
        if (this.factureForm.controls.itemsSeleccionados.value[i].ID_ITEM == id[j]) {
          del = false;
        }
      }
      if (del) {
        this.removeLineForm(this.factureForm.controls.itemsSeleccionados.value[i].ID_ITEM, 2);
        this.factureForm.controls.itemsSeleccionados.value.splice(i, 1);
        break;
      }
    }
  }

  removeLineForm(index: number, type: number) {
    RequestMisc.setValidatorsLines(this.factureForm);
    const control = <UntypedFormArray>this.factureForm.get('lines');
    for (var i = 0; i < control.length; i++) {
      var line = control.at(i);
      var WorkOrItem = line.get('WorkOrItem').value;
      if (WorkOrItem == type) {
        var ID = line.get('ID').value;
        if (ID == index) {
          control.removeAt(i);

          RequestMisc.getTotalWorks(this.factureForm, this.clientSelected);
          return;
        }
      }
    }
  }
  /**
   * Add or remove some line according to the param event and its changes.
   * @param event The event for mat select change to be applied
   */

  changeLine(event: MatSelectChange) {
    let valuesFromMatSelect = event.value;
    if (this.isAddingItem(valuesFromMatSelect)) {
      //Add new line on the request screen
      this.addNewLine(valuesFromMatSelect);
    } else {
      this.removeLineSelected(valuesFromMatSelect);
    }
    //Set the values on the last items selected for the next interaction with the user.
    this.factureForm.controls.lastItemsSelected.setValue(valuesFromMatSelect);
  }

  /**
   * Check if it is adding a new item according to the length of the last array of item selected
   */
  private isAddingItem(valuesFromMatSelect: Array<Object>): boolean {
    return this.factureForm.value.lastItemsSelected.length < valuesFromMatSelect.length;
  }
  /**
   * Add a new line to the principal panel and the array used by create method on request.ts
   * @param valuesFromMatSelect All values with the new line to be added.
   */
  addNewLine(valuesFromMatSelect: Array<Object>) {
    // Check if will be the first line
    // Because does not has any restriction
    if (this.isTheFirstLine(valuesFromMatSelect)) {
      let idItem = valuesFromMatSelect[0];
      var item: Item = this.items.find(x => x.ID_ITEM == idItem);
      this.factureForm.controls.itemsSeleccionados.value.push(item);
      //Add line to the principal panel
      this.addLineForm(item);
    } else {
      // Check if the Item was added previously
      this.addItemIfNotExist(valuesFromMatSelect);
    }
  }

  addItemIfNotExist(valuesFromMatSelect: Array<Object>) {
    //For all items in the new values
    for (var i = 0; i < valuesFromMatSelect.length; i++) {
      var existe = this.factureForm.controls.itemsSeleccionados.value.find(x => x.ID_ITEM == valuesFromMatSelect[i]);
      // If current item does not exist, so add to the itemsSeleccionados,
      // If not, just try to the another one
      if (existe == undefined) {
        var item = this.items.find(x => x.ID_ITEM == valuesFromMatSelect[i]);
        this.factureForm.controls.itemsSeleccionados.value.push(item);
        this.addLineForm(item);
      }
    }
  }

  /**
   * Check if it is the first line to be added to the panel
   * @param valuesFromMatSelect The array with the values that change
   * @returns True if it is the first line
   */
  private isTheFirstLine(valuesFromMatSelect: Array<Object>): boolean {
    if (this.factureForm.controls.itemsSeleccionados.value.length == 0 && valuesFromMatSelect.length > 0) {
      return true;
    }
    return false;
  }

  /**
   * Add the line to the panel using UntypedFormArray
   * @param item The Item to be added to the panel
   */
  addLineForm(item: Item) {
    RequestMisc.setValidatorsLines(this.factureForm);
    const control = <UntypedFormArray>this.factureForm.get('lines');
    control.push(this.initiateLine(item));
    RequestMisc.getTotalWorks(this.factureForm, this.clientSelected);
    RequestMisc.findInvalidControls(this.factureForm);
  }

  initiateLine(item: Item): UntypedFormGroup {
    var line = this.fb.group({
      cantidad: new UntypedFormControl({ value: '1' }, [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      trabajo: new UntypedFormControl({ value: item.NAME_ITEM }, Validators.required),
      precio: new UntypedFormControl({ value: item.PRICE }, [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      precioSubTotal: new UntypedFormControl({ value: '0' }, [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      precioSubTotalImpuesto: new UntypedFormControl({ value: '0' }),
      WorkOrItem: new UntypedFormControl({ value: '2' }),
      ID: new UntypedFormControl({ value: '' }),
    });
    line.controls.cantidad.setValue('1');
    line.controls.precio.setValue(item.PRICE);
    line.controls.trabajo.setValue(item.NAME_ITEM);
    line.controls.precioSubTotal.setValue(item.PRICE * 1);
    line.controls.precioSubTotalImpuesto.setValue(item.PRICE * (1 + this.clientSelected.TAX));
    line.controls.WorkOrItem.setValue(2); //Uno para los trabajo
    line.controls.ID.setValue(item.ID_ITEM); //Uno para los trabajo
    return line;
  }
}
