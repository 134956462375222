import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-type-treasury-details',
  templateUrl: './type-treasury-details.component.html',
  styleUrls: ['./type-treasury-details.component.css']
})
export class TypeTreasuryDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
