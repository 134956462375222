import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import {TypeTreasury} from '../type-treasury'
@Component({
  selector: 'app-type-treasury-new',
  templateUrl: './type-treasury-new.component.html',
  styleUrls: ['./type-treasury-new.component.css']
})
export class TypeTreasuryNewComponent implements OnInit {
  @Output() createNewTypeTreasuryEvent = new EventEmitter();
  newTypeTreasury = new TypeTreasury;
  constructor() { } 

  ngOnInit() {
  }

  create(){
    this.createNewTypeTreasuryEvent.emit(this.newTypeTreasury);
    this.newTypeTreasury = new TypeTreasury;
  }

}
