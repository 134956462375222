export class LineRequest {
    constructor(
        public ID_LINE_REQUEST: number = 0,
        public ID_REQUEST: number = 0,
        public ID_TYPE_WORK: number = 0,
        public AMOUNT_REQUEST: number = 0,
        public PRICE_REQUEST: number = 0,
        public DELIVERED_QUANTITY: number = 0
    )
    {}
    
}
