import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Client } from '../client';
import { RegionService } from '../../region/region.service';
import { Region } from '../../region/region';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { CostaRicaService } from 'src/app/costa-rica/costa-rica.service';
import { Provincia } from 'src/app/costa-rica/provincia';
import { Canton } from 'src/app/costa-rica/canton';
import { Distrito } from 'src/app/costa-rica/distrito';
import { ClientService } from '../client.service';
import { Employee } from 'src/app/employee/employee';
import { EmployeeService } from 'src/app/employee/employee.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Console } from 'console';
import { CedulasService } from 'src/app/costa-rica/cedulas.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css'],
})
export class ClientEditComponent implements OnInit, OnChanges {
  @Input() client: Client;
  @Output() updateClient = new EventEmitter();
  taxes = [
    { label: '13', value: '13', checked: true },
    { label: '4', value: '4', checked: false },
    { label: '2', value: '2', checked: false },
    { label: '1', value: '1', checked: false },
    { label: '0', value: '0', checked: false },
  ];

  cedulaPlaceholder = '909990999';
  cedulaLength = 9;
  toggleMessage = 'Física';
  cedulaLengthMax = 12;
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    juridica: new UntypedFormControl(false, Validators.required),
    tax: new UntypedFormControl(13, Validators.required),
    last_name: new UntypedFormControl('', Validators.required),
    last_2_name: new UntypedFormControl('', Validators.required),
    region: new UntypedFormControl([], Validators.required),
    direccion: new UntypedFormControl('', Validators.required),
    provincia: new UntypedFormControl([], Validators.required),
    canton: new UntypedFormControl([], Validators.required),
    distrito: new UntypedFormControl([], Validators.required),
    email: new UntypedFormControl('', [Validators.email]),
    cedula: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'),
    ]),
    phone: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'),
    ]),
  });
  @Output() updateClientEvent = new EventEmitter();
  editClient: Client = new Client();
  regions: Region[] = [];
  provincias: Provincia[] = [];
  cantones: Canton[] = [];
  distritos: Distrito[] = [];
  employeeLogged = new Employee();
  public regionFilterControl: FormControl = new FormControl();
  public filteredRegion: ReplaySubject<Region[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  isDesktop: boolean = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private _clientService: ClientService,
    private _regionService: RegionService,
    private _crService: CostaRicaService,
    private _crCedulaService: CedulasService,
    private _EmployeeService: EmployeeService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService
  ) {
    this.form.get('name').valueChanges.subscribe(() => {
      this.form.get('name').patchValue(this.form.get('name').value.toUpperCase(), { emitEvent: false });
    });
    this.form.get('last_name').valueChanges.subscribe(() => {
      this.form.get('last_name').patchValue(this.form.get('last_name').value.toUpperCase(), { emitEvent: false });
    });
    this.form.get('last_2_name').valueChanges.subscribe(() => {
      this.form.get('last_2_name').patchValue(this.form.get('last_2_name').value.toUpperCase(), { emitEvent: false });
    });
  }
  focusOut() {
    if (!this.form.controls.cedula.invalid) {
      this._crCedulaService.getNombreClientes(this.form.value.cedula).subscribe(x => {
        if (x['results'].length > 0) {
          var persona = x['results'][0];

          if (this.form.value.juridica) {
            this.form.controls.name.setValue(persona['fullname']);
          } else {
            this.form.controls.name.setValue(persona['firstname']);
            this.form.controls.last_name.setValue(persona['lastname1']);
            this.form.controls.last_2_name.setValue(persona['lastname2']);
          }
        }
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }
  userIsLogged() {
    return this.authService.getUserLogged();
  }
  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }
  clientExceptionForAdmins() {
    this.employeeLogged = this.userIsLogged();
    if (this.employeeLogged.ID_TYPE_EMPLOYEE == 1) {
      //admin
      this.form.controls.provincia.clearValidators();
      this.form.controls.canton.clearValidators();
      this.form.controls.distrito.clearValidators();
      this.form.controls.direccion.clearValidators();
    }
  }

  async getInit() {
    this.getRegions()
      .asObservable()
      .subscribe(x => {
        this.clone();
        this.clientExceptionForAdmins();
      });
    this.getProvince();
    this.markFormGroupTouched(this.form);
    this.getInfoDevice();
  }

  async ngOnInit() {
    this.getInit();
  }

  setValidatorsProvince() {
    this.form.controls.provincia.setValue([]);

    this.form.controls.canton.setValue([]);
    this.form.controls.canton.disable();

    this.form.controls.distrito.setValue([]);
    this.form.controls.distrito.disable();
  }

  setValidatorsCanton() {
    this.form.controls.canton.setValue([]);

    this.form.controls.distrito.setValue([]);
    this.form.controls.distrito.disable();
  }

  setValidatorsDistrito() {
    this.form.controls.distrito.setValue([]);
  }

  updateHomeId() {
    this.form.controls['tax'].setValue(13);
  }

  clone() {
    Object.assign(this.editClient, this.client);
    this.form.controls.name.setValue(this.editClient.NAME_CLIENT);
    this.form.controls.last_name.setValue(this.editClient.LAST_NAME);
    this.form.controls.last_2_name.setValue(this.editClient.LAST_2_NAME);
    var region = this.regions.find(region => region.ID_REGION == this.editClient.ID_REGION);
    this.form.controls.region.setValue(region);
    this.form.controls.direccion.setValue(this.editClient.ADDRESS);
    this.form.controls.email.setValue(this.editClient.EMAIL);
    this.form.controls.phone.setValue(this.editClient.PHONE_NUMBER);
    if (this.editClient.ID_PROVINCIA != -1) {
      this.form.controls.provincia.setValue(this.editClient.ID_PROVINCIA);

      this.getCantonesEdit(this.editClient.ID_PROVINCIA);
      if (this.editClient.ID_CANTON != -1) {
        this.form.controls.canton.setValue(this.editClient.ID_CANTON);

        this.getDistritosEdit(this.editClient.ID_PROVINCIA, this.editClient.ID_CANTON);
        if (this.editClient.ID_DISTRITO != -1) {
          this.form.controls.distrito.setValue(this.editClient.ID_DISTRITO);
        } else {
          this.setValidatorsDistrito();
        }
      } else {
        this.setValidatorsCanton();
      }
    } else {
      this.setValidatorsProvince();
    }

    this.form.controls.cedula.setValue(this.editClient.IDN);
    this.changeCedula(this.editClient.JURIDICA);
    this.form.controls.tax.setValue(this.editClient.TAX * 100);
    var taxClient = String(this.editClient.TAX * 100);
    this.taxes = [
      { label: '13', value: '13', checked: true },
      { label: '4', value: '4', checked: false },
      { label: '2', value: '2', checked: false },
      { label: '1', value: '1', checked: false },
      { label: '0', value: '0', checked: false },
    ];

    this.taxes.forEach(element => {
      if (element.label === taxClient) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
    if (this.editClient.JURIDICA) {
      this.form.controls.juridica.setValue(true);
    } else {
      this.form.controls.juridica.setValue(false);
    }
  }
  getProvince() {
    this._crService.getProvincias().subscribe(provincias => {
      this.provincias = [];
      for (var key in provincias) {
        var provincia: Provincia = new Provincia();
        provincia.ID_PROVINCIA = +key;
        provincia.PROVINCIA = provincias[key];
        this.provincias.push(provincia);
      }
    });
  }

  getCantones() {
    var provincia: number = this.form.value.provincia;
    if (provincia != null) {
      this._crService.getCantones(provincia).subscribe(cantones => {
        this.cantones = [];
        for (var key in cantones) {
          var canton: Canton = new Canton();
          canton.ID_CANTON = +key;
          canton.CANTON = cantones[key];
          this.cantones.push(canton);
        }
        this.form.controls.canton.enable();
        this.form.controls.canton.setValue([]);
        this.form.controls.distrito.disable();
        this.form.controls.distrito.setValue([]);
      });
    } else {
      this.form.controls.canton.disable();
      this.form.controls.canton.setValue([]);
      this.form.controls.distrito.disable();
      this.form.controls.distrito.setValue([]);
    }
  }
  getDistritos() {
    var provincia: number = this.form.value.provincia;
    var canton: number = this.form.value.canton;
    this._crService.getDistritos(provincia, canton).subscribe(distritos => {
      this.distritos = [];
      for (var key in distritos) {
        var distrito: Distrito = new Distrito();
        distrito.ID_DISTRITO = +key;
        distrito.DISTRITO = distritos[key];
        this.distritos.push(distrito);
      }
      this.form.controls.distrito.enable();
      this.form.controls.distrito.setValue([]);
    });
  }
  getCantonesEdit(provincia: number) {
    this._crService.getCantones(provincia).subscribe(cantones => {
      this.cantones = [];
      for (var key in cantones) {
        var canton: Canton = new Canton();
        canton.ID_CANTON = +key;
        canton.CANTON = cantones[key];
        this.cantones.push(canton);
      }
      this.form.controls.canton.enable();
    });
  }
  getDistritosEdit(provincia: number, canton: number) {
    this._crService.getDistritos(provincia, canton).subscribe(distritos => {
      this.distritos = [];
      for (var key in distritos) {
        var distrito: Distrito = new Distrito();
        distrito.ID_DISTRITO = +key;
        distrito.DISTRITO = distritos[key];
        this.distritos.push(distrito);
      }
      this.form.controls.distrito.enable();
    });
  }
  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  storeProvincia() {
    if (this.form.value.provincia == undefined || this.form.value.provincia == 0) {
      this.editClient.PROVINCIA = 'N/R';
      this.editClient.ID_PROVINCIA = -1;
    } else {
      this.editClient.ID_PROVINCIA = this.form.value.provincia;
      var prov = this.provincias.find(element => element.ID_PROVINCIA == this.editClient.ID_PROVINCIA);
      this.editClient.PROVINCIA = prov.PROVINCIA;
    }
  }

  storeCanton() {
    if (this.form.value.canton == undefined || this.form.value.canton == 0) {
      this.editClient.CANTON = 'N/R';
      this.editClient.ID_CANTON = -1;
    } else {
      this.editClient.ID_CANTON = this.form.value.canton;
      var canton = this.cantones.find(element => element.ID_CANTON == this.editClient.ID_CANTON);
      this.editClient.CANTON = canton.CANTON;
    }
  }

  storeDistrito() {
    if (this.form.value.distrito == undefined || this.form.value.distrito == 0) {
      this.editClient.DISTRITO = 'N/R';
      this.editClient.ID_DISTRITO = -1;
    } else {
      this.editClient.ID_DISTRITO = this.form.value.distrito;
      var distrito = this.distritos.find(element => element.ID_DISTRITO == this.editClient.ID_DISTRITO);
      this.editClient.DISTRITO = distrito.DISTRITO;
    }
  }
  update() {
    Swal.fire({
      icon: 'info',
      title: 'Generando la actualización...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    this.editClient.editable = false;
    this.editClient.NAME_CLIENT = this.form.value.name;
    this.editClient.JURIDICA = this.form.value.juridica;
    if (!this.editClient.JURIDICA) {
      this.editClient.LAST_NAME = this.form.value.last_name;
      this.editClient.LAST_2_NAME = this.form.value.last_2_name;
    } else {
      this.editClient.LAST_NAME = '';
      this.editClient.LAST_2_NAME = '';
    }
    this.editClient.ID_REGION = this.form.value.region.ID_REGION;
    this.editClient.ADDRESS = this.form.value.direccion;
    this.editClient.EMAIL = this.form.value.email;
    this.editClient.PHONE_NUMBER = this.form.value.phone;

    this.editClient.IDN = this.form.value.cedula;
    this.editClient.TAX = this.form.value.tax / 100;

    this.storeProvincia();
    this.storeCanton();
    this.storeDistrito();

    var self = this;
    this._clientService.update(this.editClient).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la edición. Revisar la cédula',
        });
      } else {
        self.popUpCreate();
      }
    });
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  popUpCreate() {
    Swal.fire({
      icon: 'success',
      title: 'Cliente actualizado',
      text: 'El cliente fué actualizado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      this.updateClient.emit();
    });
  }

  getRegions() {
    var regionsSubject = new Subject<Region[]>();
    this._regionService.getRegions().subscribe((regions: any) => {
      this.regions = regions;
      this.regions.forEach(element => {
        if (element.ID_TYPE_AVAILABLE == 2) {
          element.disabled = true;
        }
      });

      this.filteredRegion.next(this.regions.slice());
      this.regionFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTypeTreasuryComboBox();
      });

      regionsSubject.next(this.regions);
    });

    return regionsSubject;
  }

  protected filterTypeTreasuryComboBox() {
    if (!this.regions) {
      return;
    }

    let search = this.regionFilterControl.value;
    if (!search) {
      this.filteredRegion.next(this.regions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredRegion.next(this.regions.filter(region => region.NAME_REGION.toLowerCase().indexOf(search) > -1));
  }
  changeCedula(e) {
    if (e) {
      this.toggleMessage = 'Juridica';
      this.form.controls['cedula'].setValidators([Validators.required, Validators.pattern('[1-9][0-9]{9}')]);
      this.cedulaPlaceholder = '399991111';
      this.cedulaLength = 10;
      this.cedulaLengthMax = 10;
      this.form.controls['last_name'].clearValidators();
      this.form.controls['last_name'].updateValueAndValidity();
      this.form.controls['last_2_name'].clearValidators();
      this.form.controls['last_2_name'].updateValueAndValidity();
    } else {
      this.toggleMessage = 'Física';
      this.form.controls['cedula'].setValidators([
        Validators.required,
        Validators.pattern('\\b[1-9]\\d{11}\\b|\\b[1-9]\\d{10}\\b|\\b[1-9]\\d{8}\\b'),
      ]);
      this.cedulaPlaceholder = '909990999';
      this.cedulaLength = 9;
      this.cedulaLengthMax = 12;
      this.form.controls['last_name'].setValidators([Validators.required]);
      this.form.controls['last_name'].updateValueAndValidity();
      this.form.controls['last_2_name'].setValidators([Validators.required]);
      this.form.controls['last_2_name'].updateValueAndValidity();
    }
  }
}
