import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Client } from '../payment/client';
import { PaymentViewComponent } from '../payment/payment-view/payment-view.component';
import { ClientHistoryService } from './client-history.service'

@Component({
  selector: 'app-client-history',
  templateUrl: './client-history.component.html',
  styleUrls: ['./client-history.component.css']
})
export class ClientHistoryComponent implements OnInit {
  clients: Client[];
  clientSeleccionado : Client = null;
  
  @ViewChildren('viewPaymentEl') childrenComponent:QueryList<PaymentViewComponent>;

  constructor(
    private _clienteHistoryService : ClientHistoryService
  ) { }

  ngOnInit() {
    this.getClients();
  }


  getClients() {
    var self = this;
    this._clienteHistoryService.getAllClients()
      .subscribe( (clients : any) => {
        self.clients = clients;
      })
  }

  scrolled(first:boolean){
    this.childrenComponent.changes.subscribe((comps: QueryList<PaymentViewComponent>) =>
    {
      if(comps.last != undefined){
        if(first){
          if(this.clientSeleccionado.JURIDICA == 1){
            comps.last.scroll(-295);
          }else{
            comps.last.scroll(-390);
          }
        }else{
          comps.last.scroll(95);
        }
        
      }  
    });
    this.childrenComponent.notifyOnChanges();
  }


  viewClient(client: Client) {
    this.clientSeleccionado = client;
    this.scrolled(false);
    
  }

}
