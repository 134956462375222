export class Region {
    
    constructor(
        public ID_REGION: number = 0,
        public NAME_REGION: string = "",
        public ID_TYPE_AVAILABLE : number = 0,
        public disabled: boolean = false,
        public editable: boolean = false
    ){

    }
}
