import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Inventory } from './inventory';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private _http:HttpClient
  ) { }

  create(inventory: Inventory){
    return this._http.post('/inventory',inventory)
  }

  destroy(inventory: Inventory){
    return this._http.delete('/inventory/' + inventory.ID_INVENTORY)
  }

  update(inventory: Inventory){
    return this._http.put('/inventory' ,inventory)
  }

  getInventory(){
    return this._http.get('/inventory')
  }

  getInventoryIndividual(inventory: Inventory){
    return this._http.get('/inventory/' + inventory.ID_INVENTORY)
  }
}
