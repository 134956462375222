import { Component, OnInit, ViewChild, Input, SimpleChanges, Output } from '@angular/core';
import { Region } from '../region';
import { RegionComponent } from '../region.component';
import { RegionService } from '../region.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { Modal } from 'src/app/misc/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tabla-region',
  templateUrl: './tabla-region.component.html',
  styleUrls: ['./tabla-region.component.css'],
})
export class TablaRegionComponent extends Modal implements OnInit {
  @Input() regions: Region[];
  filtro: any = { NAME_REGION: null };
  public regionSeleccionado: Region;
  public editable: Boolean = false;

  displayedColumns: string[] = ['ID_REGION', 'NAME_REGION', 'OPCIONES'];
  dataSource = new MatTableDataSource<Region>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private regionService: RegionService,
    private regionComponent: RegionComponent,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }
  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    var self = this;
    self.dataSource = new MatTableDataSource(self.regions);
    self.dataSource.paginator = self.paginator;
    self.dataSource.sort = self.sort;
  }

  editar(region: Region) {
    this.regionSeleccionado = region;
    this.editable = true;
  }

  eliminarRegion(nombre: Region) {
    Swal.fire({
      icon: 'info',
      title: 'Eliminando...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    var self = this;
    this.regionService.destroy(nombre).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al eliminar',
        });
      } else {
        self.popUpDelete();
      }
    });
  }

  popUpDelete() {
    Swal.fire({
      icon: 'success',
      title: 'La zona eliminada',
      text: 'La zona fué eliminado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      this.getRegions();
    });
  }
  getRegions() {
    var self = this;
    this.regionService.getRegions().subscribe((x: any) => {
      self.regions = x;
      self.dataSource = new MatTableDataSource(self.regions);
      self.dataSource.paginator = self.paginator;
      self.dataSource.sort = self.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
