import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import { TYPESTATEREQUEST } from '../type-state-request'

@Component({
  selector: 'app-type-state-request-new',
  templateUrl: './type-state-request-new.component.html',
  styleUrls: ['./type-state-request-new.component.css']
})
export class TYPESTATEREQUESTNewComponent implements OnInit {
  @Output() createNewTypeStateRequestEvent = new EventEmitter();
  newTypeState = new TYPESTATEREQUEST;
  constructor() { }

  ngOnInit() {
  }

  create(){
    this.createNewTypeStateRequestEvent.emit(this.newTypeState);
    this.newTypeState = new TYPESTATEREQUEST;
  }

}
