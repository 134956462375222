import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { RegionService } from './region/region.service';
import { TypeEmployeeService } from './type-employee/type-employee.service';
import { TypeExpenseService } from './type-expense/type-expense.service';
import { TypeItemService } from './type-item/type-item.service';
import { TypeTreasuryService } from './type-treasury/type-treasury.service';
import { TypeWorkService } from './type-work/type-work.service';
import { EmployeeService } from './employee/employee.service';
import { TYPESTATEREQUESTService } from './type-state-request/type-state-request.service';
import { ClientService } from './client/client.service';
import { ItemService } from './item/item.service';
import { CommentService } from './comment/comment.service';
import { RequestService } from './request/request.service';
import { ReportService } from './report/report.service';
import { PaymentService } from './payment/payment.service';
import { DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegionComponent } from './region/region.component';
import { RegionNewComponent } from './region/region-new/region-new.component';
import { RegionListComponent } from './region/region-list/region-list.component';
import { RegionEditComponent } from './region/region-edit/region-edit.component';
import { RegionDetailsComponent } from './region/region-details/region-details.component';
import { TYPESTATEREQUESTComponent } from './type-state-request/type-state-request.component';
import { TYPESTATEREQUESTNewComponent } from './type-state-request/type-state-request-new/type-state-request-new.component';
import { TYPESTATEREQUESTEditComponent } from './type-state-request/type-state-request-edit/type-state-request-edit.component';
import { TYPESTATEREQUESTDetailsComponent } from './type-state-request/type-state-request-details/type-state-request-details.component';
import { TYPESTATEREQUESTListComponent } from './type-state-request/type-state-request-list/type-state-request-list.component';
import { TypeEmployeeComponent } from './type-employee/type-employee.component';
import { TypeEmployeeNewComponent } from './type-employee/type-employee-new/type-employee-new.component';
import { TypeEmployeeDetailsComponent } from './type-employee/type-employee-details/type-employee-details.component';
import { TypeEmployeeEditComponent } from './type-employee/type-employee-edit/type-employee-edit.component';
import { TypeEmployeeListComponent } from './type-employee/type-employee-list/type-employee-list.component';
import { TypeTreasuryComponent } from './type-treasury/type-treasury.component';
import { TypeTreasuryNewComponent } from './type-treasury/type-treasury-new/type-treasury-new.component';
import { TypeTreasuryDetailsComponent } from './type-treasury/type-treasury-details/type-treasury-details.component';
import { TypeTreasuryListComponent } from './type-treasury/type-treasury-list/type-treasury-list.component';
import { TypeTreasuryEditComponent } from './type-treasury/type-treasury-edit/type-treasury-edit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TypeExpenseComponent } from './type-expense/type-expense.component';
import { TypeExpenseNewComponent } from './type-expense/type-expense-new/type-expense-new.component';
import { TypeExpenseDetailsComponent } from './type-expense/type-expense-details/type-expense-details.component';
import { TypeExpenseEditComponent } from './type-expense/type-expense-edit/type-expense-edit.component';
import { TypeExpenseListComponent } from './type-expense/type-expense-list/type-expense-list.component';
import { TypeItemComponent } from './type-item/type-item.component';
import { TypeItemNewComponent } from './type-item/type-item-new/type-item-new.component';
import { TypeItemDetailsComponent } from './type-item/type-item-details/type-item-details.component';
import { TypeItemListComponent } from './type-item/type-item-list/type-item-list.component';
import { TypeItemEditComponent } from './type-item/type-item-edit/type-item-edit.component';
import { TypeWorkComponent } from './type-work/type-work.component';
import { TypeWorkNewComponent } from './type-work/type-work-new/type-work-new.component';
import { TypeWorkEditComponent } from './type-work/type-work-edit/type-work-edit.component';
import { TypeWorkListComponent } from './type-work/type-work-list/type-work-list.component';
import { EmployeeComponent } from './employee/employee.component';
import { EmployeeNewComponent } from './employee/employee-new/employee-new.component';
import { EmployeeListComponent } from './employee/employee-list/employee-list.component';
import { EmployeeEditComponent } from './employee/employee-edit/employee-edit.component';
import { EmployeeDetailsComponent } from './employee/employee-details/employee-details.component';
import { ClientComponent } from './client/client.component';
import { ClientNewComponent } from './client/client-new/client-new.component';
import { ClientEditComponent } from './client/client-edit/client-edit.component';
import { ClientInfoComponent } from './client/client-info/client-info.component';
import { ItemComponent } from './item/item.component';
import { ItemNewComponent } from './item/item-new/item-new.component';
import { ItemEditComponent } from './item/item-edit/item-edit.component';
import { ItemDetailsComponent } from './item/item-details/item-details.component';
import { ItemListComponent } from './item/item-list/item-list.component';
import { CommentNewComponent } from './comment/comment-new/comment-new.component';
import { CommentListComponent } from './comment/comment-list/comment-list.component';
import { CommentEditComponent } from './comment/comment-edit/comment-edit.component';
import { CommentComponent } from './comment/comment.component';
import { RequestComponent } from './request/request.component';
import { LineItemComponent } from './line-item/line-item.component';
import { LineItemNewComponent } from './line-item/line-item-new/line-item-new.component';
import { LineRequestComponent } from './line-request/line-request.component';
import { SesionComponent } from './sesion/sesion.component';
import { NavbarAdminComponent } from './navbar-admin/navbar-admin.component';
import { NavbarInicioComponent } from './navbar-inicio/navbar-inicio.component';
import { AdminComponent } from './admin/admin.component';
import { TablaRegionComponent } from './region/tabla-region/tabla-region.component';
import { TablaTypeEmployeeComponent } from './type-employee/tabla-type-employee/tabla-type-employee.component';
import { TablaTypeExpenseComponent } from './type-expense/tabla-type-expense/tabla-type-expense.component';
import { TablaTypeItemComponent } from './type-item/tabla-type-item/tabla-type-item.component';
import { TablaTypeStateRequestComponent } from './type-state-request/tabla-type-state-request/tabla-type-state-request.component';
import { TablaTypeWorkComponent } from './type-work/tabla-type-work/tabla-type-work.component';
import { TablaClientComponent } from './client/tabla-client/tabla-client.component';
import { TablaEmployeeComponent } from './employee/tabla-employee/tabla-employee.component';
import { TablaItemComponent } from './item/tabla-item/tabla-item.component';
import { ReportComponent } from './report/report.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NavbarEmployeeComponent } from './navbar-employee/navbar-employee.component';
import { EmployeeInicioComponent } from './employee-inicio/employee-inicio.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentTableComponent } from './payment/payment-table/payment-table.component';
import { PaymentViewComponent } from './payment/payment-view/payment-view.component';
import { PaymentHistoryComponent } from './payment/payment-history/payment-history.component';
import { PaymentNewComponent } from './payment/payment-new/payment-new.component';
import { ExpenseComponent } from './expense/expense.component';
import { ExpenseNewComponent } from './expense/expense-new/expense-new.component';
import { TablaExpenseComponent } from './expense/tabla-expense/tabla-expense.component';
import { ExpenseEditComponent } from './expense/expense-edit/expense-edit.component';
import { TypePaymentComponent } from './type-payment/type-payment.component';
import { AuthGuard } from './guards/auth.guard';
import { PaymentPdfComponent } from './payment/payment-pdf/payment-pdf.component';
import { ReporteIndividualComponent } from './reporte-individual/reporte-individual.component';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RequestEditComponent } from './request-edit/request-edit.component';
import { SearchComponent } from './request-edit/search/search.component';
import { EditPendingComponent } from './request-edit/edit-pending/edit-pending.component';
import { PaymentIndividualComponent } from './payment/payment-individual/payment-individual.component';
import { CostaRicaComponent } from './costa-rica/costa-rica.component';
import { ProviderComponent } from './provider/provider.component';
import { ProviderNewComponent } from './provider/provider-new/provider-new.component';
import { ProviderTableComponent } from './provider/provider-table/provider-table.component';
import { ProviderEditComponent } from './provider/provider-edit/provider-edit.component';
import { InventoryComponent } from './inventory/inventory.component';
import { TablaInventoryComponent } from './inventory/tabla-inventory/tabla-inventory.component';
import { NewInventoryComponent } from './inventory/new-inventory/new-inventory.component';
import { PaymentUncollectibleComponent } from './payment/payment-uncollectible/payment-uncollectible.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { TokeninterceptorService } from './services/tokeninterceptor.service';
import { RequestWithoutProcessComponent } from './request-edit/request-without-process/request-without-process.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RequestDisplayComponent } from './request-display/request-display.component';
import { RequestWaitingComponent } from './request-display/request-waiting/request-waiting.component';
import { RequestReadyComponent } from './request-display/request-ready/request-ready.component';
import { ClientHistoryComponent } from './client-history/client-history.component';
import { TableClientHistoryComponent } from './client-history/table-client-history/table-client-history.component';
import { HistoryComponent } from './client-history/history/history.component';
import { NewsComponent } from './news/news.component';
import { HeaderRequestComponent } from './request/header-request/header-request.component';
import { SelectWorkRequestComponent } from './request/select-work-request/select-work-request.component';
import { SelectItemRequestComponent } from './request/select-item-request/select-item-request.component';
import { HeaderRequestEditComponent } from './request-edit/edit-pending/header-request-edit/header-request-edit.component';
import { ProviderInformationComponent } from './provider/provider-information/provider-information.component';
import { ChangeCollectibleComponent } from './payment/change-collectible/change-collectible.component';
import { RequestInformationComponent } from './request-information/request-information.component';
import { PayLogComponent } from './pay-log/pay-log.component';
import { ExpenseInfoComponent } from './expense/expense-info/expense-info.component';
import { InventoryInfoComponent } from './inventory/inventory-info/inventory-info.component';
import { NumberFormatPipe } from './number-format.pipe';
import { RequestStateComponent } from './request-state/request-state.component';
@NgModule({
  declarations: [
    AppComponent,
    RegionComponent,
    RegionNewComponent,
    RegionListComponent,
    RegionEditComponent,
    RegionDetailsComponent,
    TYPESTATEREQUESTComponent,
    TYPESTATEREQUESTNewComponent,
    TYPESTATEREQUESTEditComponent,
    TYPESTATEREQUESTDetailsComponent,
    TYPESTATEREQUESTListComponent,
    TypeEmployeeComponent,
    TypeEmployeeNewComponent,
    TypeEmployeeDetailsComponent,
    TypeEmployeeEditComponent,
    TypeEmployeeListComponent,
    TypeTreasuryComponent,
    TypeTreasuryNewComponent,
    TypeTreasuryDetailsComponent,
    TypeTreasuryListComponent,
    TypeTreasuryEditComponent,
    TypeExpenseComponent,
    TypeExpenseNewComponent,
    TypeExpenseDetailsComponent,
    TypeExpenseEditComponent,
    TypeExpenseListComponent,
    TypeItemComponent,
    TypeItemNewComponent,
    TypeItemDetailsComponent,
    TypeItemListComponent,
    TypeItemEditComponent,
    TypeWorkComponent,
    TypeWorkNewComponent,
    TypeWorkEditComponent,
    TypeWorkListComponent,
    EmployeeComponent,
    EmployeeNewComponent,
    EmployeeListComponent,
    EmployeeEditComponent,
    EmployeeDetailsComponent,
    ClientComponent,
    ClientNewComponent,
    ClientEditComponent,
    ClientInfoComponent,
    ItemComponent,
    ItemNewComponent,
    ItemEditComponent,
    ItemDetailsComponent,
    ItemListComponent,
    CommentComponent,
    CommentNewComponent,
    CommentListComponent,
    CommentEditComponent,
    RequestComponent,
    LineItemComponent,
    LineItemNewComponent,
    LineRequestComponent,
    SesionComponent,
    NavbarAdminComponent,
    NavbarInicioComponent,
    AdminComponent,
    TablaRegionComponent,
    TablaTypeEmployeeComponent,
    TablaTypeExpenseComponent,
    TablaTypeItemComponent,
    TablaTypeStateRequestComponent,
    TablaTypeWorkComponent,
    ChangeCollectibleComponent,
    TablaClientComponent,
    TablaEmployeeComponent,
    TablaItemComponent,
    ReportComponent,
    NavbarEmployeeComponent,
    EmployeeInicioComponent,
    PaymentComponent,
    PaymentTableComponent,
    PaymentViewComponent,
    PaymentHistoryComponent,
    PaymentNewComponent,
    ExpenseComponent,
    ExpenseNewComponent,
    TablaExpenseComponent,
    ExpenseEditComponent,
    TypePaymentComponent,
    PaymentPdfComponent,
    ReporteIndividualComponent,
    RequestEditComponent,
    SearchComponent,
    EditPendingComponent,
    PaymentIndividualComponent,
    CostaRicaComponent,
    ProviderComponent,
    ProviderNewComponent,
    ProviderTableComponent,
    ProviderEditComponent,
    InventoryComponent,
    TablaInventoryComponent,
    NewInventoryComponent,
    PaymentUncollectibleComponent,
    RequestWithoutProcessComponent,
    RequestDisplayComponent,
    RequestWaitingComponent,
    RequestReadyComponent,
    ClientHistoryComponent,
    TableClientHistoryComponent,
    HistoryComponent,
    NewsComponent,
    HeaderRequestComponent,
    SelectWorkRequestComponent,
    SelectItemRequestComponent,
    HeaderRequestEditComponent,
    ProviderInformationComponent,
    RequestInformationComponent,
    PayLogComponent,
    ExpenseInfoComponent,
    InventoryInfoComponent,
    NumberFormatPipe,
    RequestStateComponent
  ],
  exports: [NumberFormatPipe],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FilterPipeModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    NgbModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true,
    }),
  ],
  providers: [
    RegionService,
    TYPESTATEREQUESTService,
    TypeEmployeeService,
    TypeExpenseService,
    TypeItemService,
    TypeTreasuryService,
    TypeWorkService,
    EmployeeService,
    ClientService,
    ItemService,
    CommentService,
    RequestService,
    ReportService,
    PaymentService,
    AuthGuard,
    DatePipe,
    DeviceDetectorService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: TokeninterceptorService, multi: true },
    NumberFormatPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
