import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TypeWork } from '../type-work';
import { Region } from 'src/app/region/region';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TypeWorkService } from '../type-work.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-type-work-new',
  templateUrl: './type-work-new.component.html',
  styleUrls: ['./type-work-new.component.css'],
})
export class TypeWorkNewComponent implements OnInit {
  @Output() updateTypeWork = new EventEmitter();
  newTypeWork = new TypeWork();
  form = new UntypedFormGroup({
    tipoTrabajo: new UntypedFormControl('', Validators.required),
    descripcion: new UntypedFormControl('', Validators.required),
    precio: new UntypedFormControl('', [Validators.required, Validators.pattern('[1-9][0-9]*')]),
  });

  constructor(private _TypeWorkService: TypeWorkService) {}
  ngOnInit() {
    this.markFormGroupTouched(this.form);
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  create() {
    Swal.fire({
      icon: 'info',
      title: 'Creando tipo de trabajo...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.newTypeWork.DESCRIPTION = this.form.value.descripcion;
    this.newTypeWork.PRICE = this.form.value.precio;
    this.newTypeWork.NAME_TYPE_WORK = this.form.value.tipoTrabajo;
    var self = this;
    this._TypeWorkService.create(self.newTypeWork).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la inserción. Nombre de tipo de trabajo duplicado',
        });
      } else {
        self.popUpCreate();
        self.form.reset();
        self.newTypeWork = new TypeWork();
        self.updateTypeWork.emit();
      }
    });
  }

  popUpCreate() {
    Swal.fire({
      icon: 'success',
      title: 'Tipo de trabajo agregado',
      text: 'El tipo de trabajo fué agregado correctamente',
    });
  }
}
