import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Client } from 'src/app/client/client';
import { TypeWork } from 'src/app/type-work/type-work';
import { RequestService } from '../request.service';
import { TypeWorkService } from 'src/app/type-work/type-work.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from 'src/app/misc/modal';
import { RequestMisc } from 'src/app/misc/requestMisc';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-select-work-request',
  templateUrl: './select-work-request.component.html',
  styleUrls: ['./select-work-request.component.css'],
})
export class SelectWorkRequestComponent extends Modal implements OnInit {
  @Input() factureForm: UntypedFormGroup;
  @Input() clientSelected: Client;

  @Input() isFromEditRequest: Boolean = false;
  typeWorks: TypeWork[] = [];

  public typeWorkFilteredControl: FormControl = new FormControl();
  public filteredTypeWork: ReplaySubject<TypeWork[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();
  ngOnInit(): void {
    this.getTypeWorks();
  }
  constructor(
    private _requestService: RequestService,
    private _typeWorkService: TypeWorkService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private modalServiceExt: NgbModal
  ) {
    super(modalServiceExt);
  }

  customSearchFnWork(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.NAME_TYPE_WORK.toLocaleLowerCase().indexOf(term) > -1 ||
      item.DESCRIPTION.toLocaleLowerCase().indexOf(term) > -1 ||
      (item.NAME_TYPE_WORK + ' : ' + item.DESCRIPTION).toLocaleLowerCase().indexOf(term) > -1
    );
  }

  getTypeWorks() {
    this._typeWorkService.getTypesWork().subscribe((x: any) => {
      this.typeWorks = x;
      this.typeWorks.forEach(element => {
        if (element.ID_TYPE_AVAILABLE == 2) {
          element.disabled = true;
        }
      });
      this.filteredTypeWork.next(this.typeWorks.slice());

      // Apply changes subscribed to the filter
      this.typeWorkFilteredControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filteredTypeWorks();
      });
    });
  }

  protected filteredTypeWorks() {
    if (!this.typeWorks) {
      return;
    }

    let search = this.typeWorkFilteredControl.value;
    if (!search) {
      this.filteredTypeWork.next(this.typeWorks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredTypeWork.next(
      this.typeWorks.filter(
        typeWork =>
          (typeWork.NAME_TYPE_WORK.toLowerCase() + ':' + typeWork.DESCRIPTION.toLowerCase()).indexOf(search) > -1
      )
    );
  }

  /**
   * Add line to form and calculate the total
   * @param typeWork TypeWork to be added
   */
  addLineForm(typeWork: TypeWork) {
    RequestMisc.setValidatorsLines(this.factureForm);
    const control = <UntypedFormArray>this.factureForm.get('lines');
    control.push(this.initiateLine(typeWork));
    RequestMisc.getTotalWorks(this.factureForm, this.clientSelected);
    RequestMisc.findInvalidControls(this.factureForm);
  }

  initiateLine(typeWork: TypeWork): UntypedFormGroup {
    var line = this.fb.group({
      cantidad: new UntypedFormControl({ value: '1' }, [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      trabajo: new UntypedFormControl({ value: typeWork.NAME_TYPE_WORK }, Validators.required),
      precio: new UntypedFormControl({ value: typeWork.PRICE }, [
        Validators.required,
        Validators.pattern('[1-9][0-9]*'),
      ]),
      precioSubTotal: new UntypedFormControl({ value: '0' }, [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      precioSubTotalImpuesto: new UntypedFormControl({ value: '0' }),
      WorkOrItem: new UntypedFormControl({ value: '1' }),
      ID: new UntypedFormControl({ value: '' }),
    });
    line.controls.cantidad.setValue('1');
    line.controls.precio.setValue(typeWork.PRICE);
    line.controls.trabajo.setValue(typeWork.NAME_TYPE_WORK);
    line.controls.precioSubTotal.setValue(typeWork.PRICE * 1);
    line.controls.precioSubTotalImpuesto.setValue(typeWork.PRICE * (1 + this.clientSelected.TAX));
    line.controls.WorkOrItem.setValue(1); //Uno para los trabajo
    line.controls.ID.setValue(typeWork.ID_TYPE_WORK); //Uno para los trabajo
    return line;
  }

  changeLine(event: MatSelectChange) {
    let valuesFromMatSelect = event.value;
    if (this.isAddingWork(valuesFromMatSelect)) {
      this.addNewLine(valuesFromMatSelect);
      this.factureForm.controls.lastWorksSelected.setValue(valuesFromMatSelect);
    } else {
      this.removeLineSelected(valuesFromMatSelect);
      this.factureForm.controls.lastWorksSelected.setValue(valuesFromMatSelect);
    }
  }

  /**
   * Add new line to form, check if is the first line and add work if not exist
   * @param valuesFromMatSelect Array of values from mat select
   */
  addNewLine(valuesFromMatSelect: Array<Object>) {
    if (this.isTheFirstLine(valuesFromMatSelect)) {
      let idWork = valuesFromMatSelect[0];
      var typeWork: TypeWork = this.typeWorks.find(x => x.ID_TYPE_WORK == idWork);
      this.factureForm.controls.typeWorkSeleccionados.value.push(typeWork);
      this.addLineForm(typeWork);
    } else {
      this.addWorkIfNotExist(valuesFromMatSelect);
    }
  }

  /**
   * Add work if doesn't exist in the list typeWorkSeleccionados
   * @param valuesFromMatSelect Array of values from mat select
   */
  addWorkIfNotExist(valuesFromMatSelect: Array<Object>) {
    for (var i = 0; i < valuesFromMatSelect.length; i++) {
      var existe = this.factureForm.controls.typeWorkSeleccionados.value.find(
        x => x.ID_TYPE_WORK == valuesFromMatSelect[i]
      );
      if (existe == undefined) {
        var typeWork = this.typeWorks.find(x => x.ID_TYPE_WORK == valuesFromMatSelect[i]);
        this.factureForm.controls.typeWorkSeleccionados.value.push(typeWork);
        this.addLineForm(typeWork);
      }
    }
  }

  private isAddingWork(valuesFromMatSelect: Array<Object>): boolean {
    return this.factureForm.value.lastWorksSelected.length < valuesFromMatSelect.length;
  }

  private isTheFirstLine(valuesFromMatSelect: Array<Object>): boolean {
    if (this.factureForm.controls.typeWorkSeleccionados.value.length == 0 && valuesFromMatSelect.length > 0) {
      return true;
    }
    return false;
  }

  removeLineSelected(valuesFromMatSelect: Array<Object>) {
    var id = this.factureForm.value.idTypeWork;
    for (var i = 0; i < this.factureForm.controls.typeWorkSeleccionados.value.length; i++) {
      var del = true;
      for (var j = 0; j < id.length; j++) {
        if (this.factureForm.controls.typeWorkSeleccionados.value[i].ID_TYPE_WORK == id[j]) {
          del = false;
        }
      }
      if (del) {
        this.removeLineForm(this.factureForm.controls.typeWorkSeleccionados.value[i].ID_TYPE_WORK, 1);
        this.factureForm.controls.typeWorkSeleccionados.value.splice(i, 1);
        break;
      }
    }
  }

  removeLineForm(index: number, type: number) {
    RequestMisc.setValidatorsLines(this.factureForm);
    const control = <UntypedFormArray>this.factureForm.get('lines');
    for (var i = 0; i < control.length; i++) {
      var line = control.at(i);
      var WorkOrItem = line.get('WorkOrItem').value;
      if (WorkOrItem == type) {
        var ID = line.get('ID').value;
        if (ID == index) {
          control.removeAt(i);

          RequestMisc.getTotalWorks(this.factureForm, this.clientSelected);
          return;
        }
      }
    }
  }
}
