import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import {TYPESTATEREQUEST} from '../type-state-request'
@Component({
  selector: 'app-type-state-request-edit',
  templateUrl: './type-state-request-edit.component.html',
  styleUrls: ['./type-state-request-edit.component.css']
})
export class TYPESTATEREQUESTEditComponent implements OnInit {

  @Input() typeStateRequest:TYPESTATEREQUEST;
  @Output() updateTypeStateRequestEvent = new EventEmitter();
  editTypeStateRequest: TYPESTATEREQUEST = new TYPESTATEREQUEST;
  constructor() { }

  ngOnInit() {
    this.clone();
  }
  clone()
  {
    Object.assign(this.editTypeStateRequest,this.typeStateRequest);
   
  }
  cancelar()
  {
    var reg = new TYPESTATEREQUEST();
    reg.ID_TYPE_STATE_REQUEST = -1;
    this.updateTypeStateRequestEvent.emit(reg);
  }

  update(){
    this.editTypeStateRequest.editable = false;
    this.updateTypeStateRequestEvent.emit(this.editTypeStateRequest);
  }
}
