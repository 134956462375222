import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges  } from '@angular/core';
import {TypeWork} from '../type-work'
import {UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TypeWorkService } from '../type-work.service';

@Component({
  selector: 'app-type-work-edit',
  templateUrl: './type-work-edit.component.html',
  styleUrls: ['./type-work-edit.component.css']
})
export class TypeWorkEditComponent implements OnInit {

  @Input() typeWork : TypeWork
  @Output() updateTypeWork = new EventEmitter();
  editTypeWork:TypeWork = new TypeWork;
  form = new UntypedFormGroup({
    tipoTrabajo : new UntypedFormControl('',Validators.required),
    descripcion : new UntypedFormControl('', Validators.required),
    precio : new UntypedFormControl('', [Validators.required,
      Validators.pattern('[1-9][0-9]*')])
  })
  constructor(
    private _TypeWorkService: TypeWorkService
  ) { }

  ngOnInit() {
    this.clone();
    this.markFormGroupTouched(this.form)
  }
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }
  clone()
  {
    Object.assign(this.editTypeWork, this.typeWork)
    this.form.controls.descripcion.setValue(this.editTypeWork.DESCRIPTION);
    this.form.controls.precio.setValue(this.editTypeWork.PRICE);
    this.form.controls.tipoTrabajo.setValue(this.editTypeWork.NAME_TYPE_WORK);  
  }

  habilitar(){
    Swal.fire({
      icon: 'info',
      title: "Habilitando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.editTypeWork.ID_TYPE_AVAILABLE = 1;
    this.editTypeWork.editable = false;
    var self = this;
    this._TypeWorkService.update(this.editTypeWork)
    .subscribe(function (value){
      if(value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del tipo de trabajo'
        })
      }else{
        self.updateHabilitar()
      }
    })
  }

  deshabilitar(){
    Swal.fire({
      icon: 'info',
      title: "Deshabilitando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.editTypeWork.ID_TYPE_AVAILABLE = 2;
    this.editTypeWork.editable = false;
    var self = this;
    this._TypeWorkService.update(this.editTypeWork)
    .subscribe(function (value){
      if(value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al actualizar el estado del tipo de trabajo'
        })
      }else{
        self.updateDeshabilitar();
      }
    })
  }

  updateDeshabilitar(){
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'Tipo de trabajo deshabilitado.',
      text: 'El tipo de trabajo fué deshabilitado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      self.updateTypeWork.emit()
    })
  }

  updateHabilitar(){
    var self = this;
    Swal.fire({
      icon: 'success',
      title: 'Tipo de trabajo habilitado.',
      text: 'El tipo de trabajo fué habilitado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      self.updateTypeWork.emit()
    })
  }

  update(){
    Swal.fire({
      icon: 'info',
      title: "Actualizando...",
      text: "Por favor espere.",
      didOpen: () => {
        Swal.showLoading(null)
      },
      showConfirmButton: false,
      allowOutsideClick: false
    });
    this.editTypeWork.DESCRIPTION = this.form.value.descripcion;
    this.editTypeWork.PRICE = this.form.value.precio;
    this.editTypeWork.NAME_TYPE_WORK = this.form.value.tipoTrabajo;
    this.editTypeWork.editable = false;
    var self = this;
    this._TypeWorkService.update(this.editTypeWork)
    .subscribe(function (value) {
      if (value == 500){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la edición. Nombre de tipo de trabajo duplicado'
        })
      }else{
        self.popUpUpdate()
      }
    });
  }
  popUpUpdate(){
    Swal.fire({
      icon: 'success',
      title: 'Tipo de trabajo actualizado',
      text: 'El tipo de trabajo fué actualizado',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      this.updateTypeWork.emit()
    })
  }

}

