import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { TYPESTATEREQUEST} from './type-state-request'
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class TYPESTATEREQUESTService {

  constructor(private _htpp:HttpClient) {
   }
   create(typestaterequest: TYPESTATEREQUEST){
    return this._htpp.post('/type_state_request',typestaterequest)
  }

  destroy(typestaterequest: TYPESTATEREQUEST){
    return this._htpp.delete('/type_state_request/' + typestaterequest.ID_TYPE_STATE_REQUEST)
  }

  update(typestaterequest: TYPESTATEREQUEST){
    return this._htpp.put('/type_state_request' ,typestaterequest)
  }

  getTypeStateRequests(){
    return this._htpp.get('/type_state_request')
  }

  getTypeStateRequest(typestaterequest: TYPESTATEREQUEST){
    return this._htpp.get('/type_state_request/' + typestaterequest.ID_TYPE_STATE_REQUEST)
  }
}
