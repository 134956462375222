import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Expense } from '../expense';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { EmployeeService } from '../../employee/employee.service';
import { Employee } from '../../employee/employee';
import { TypeExpense } from '../../type-expense/type-expense';
import { TypeExpenseService } from '../../type-expense/type-expense.service';

import { DatePipe, formatDate } from '@angular/common';
import { ExpenseService } from '../expense.service';
import Swal from 'sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-expense-edit',
  templateUrl: './expense-edit.component.html',
  styleUrls: ['./expense-edit.component.css'],
})
export class ExpenseEditComponent implements OnInit {
  @Input() expense: Expense;
  @Output() updateExpense = new EventEmitter();

  public typeExpenseFilterControl: FormControl = new FormControl();
  public filteredTypeExpense: ReplaySubject<TypeExpense[]> = new ReplaySubject(1);
  protected _onDestroy = new Subject();

  isDesktop: boolean = false;
  maxDate: string;
  empleado: Employee;
  TypeExpenses: TypeExpense[] = [];
  form = new UntypedFormGroup({
    id_employee: new UntypedFormControl('', Validators.required),
    id_type_expense: new UntypedFormControl('', Validators.required),
    date_expense: new UntypedFormControl('', Validators.required),
    voucher: new UntypedFormControl(''),
    description_expense: new UntypedFormControl('', [Validators.required]),
    expense: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
  });
  editExpense: Expense = new Expense();
  constructor(
    private _expenseService: ExpenseService,
    private _EmployeeService: EmployeeService,
    private _typeExpenseService: TypeExpenseService,
    private datePipe: DatePipe,
    private authService: AuthService,

    private deviceService: DeviceDetectorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }
  getInfoDevice() {
    this.isDesktop = this.deviceService.isDesktop();
  }
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  async ngOnInit() {
    await this.getEmployees();
    this.getTypesExpense()
      .asObservable()
      .subscribe(x => {
        this.clone();
      });
    this.markFormGroupTouched(this.form);
  }

  setDescription() {
    var idExpense = this.form.value.id_type_expense;
    if (idExpense != null) {
      var element = this.TypeExpenses.find(x => x.ID_TYPE_EXPENSE == idExpense);
      this.form.controls.description_expense.setValue(element.DESCRIPTION);
    } else {
      this.form.controls.description_expense.setValue('');
    }
  }

  async getEmployees() {
    this._EmployeeService.getEmployees().subscribe((empleados: any) => {
      this.empleado = empleados.find(x => this.expense.ID_EMPLOYEES == x.ID_EMPLOYEE);
      this.form.controls.id_employee.setValue(this.empleado.NAME_EMPLOYEE);
    });
  }

  getUserLogged() {
    this.empleado = this.userIsLogged();
    this.form.controls.id_employee.setValue(this.empleado.NAME_EMPLOYEE);
  }

  userIsLogged() {
    return this.authService.getUserLogged();
  }

  getTypesExpense() {
    var typeExpensesSubject = new Subject<TypeExpense[]>();
    this._typeExpenseService.getTypeExpenses().subscribe((x: any) => {
      this.TypeExpenses = x;
      this.TypeExpenses.forEach(element => {
        if (element.ID_TYPE_AVAILABLE == 2 || (this.empleado.ID_TYPE_EMPLOYEE == 2 && element.ID_TYPE_EMPLOYEE == 1)) {
          element.disabled = true;
        }
      });

      this.filteredTypeExpense.next(this.TypeExpenses.slice());
      // Apply changes subscribed to the filter
      this.typeExpenseFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTypeTreasuryComboBox();
      });
      typeExpensesSubject.next(this.TypeExpenses);
    });

    return typeExpensesSubject;
  }

  protected filterTypeTreasuryComboBox() {
    if (!this.TypeExpenses) {
      return;
    }

    let search = this.typeExpenseFilterControl.value;
    if (!search) {
      this.filteredTypeExpense.next(this.TypeExpenses.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredTypeExpense.next(
      this.TypeExpenses.filter(expense => expense.NAME_TYPE_EXPENSE.toLowerCase().indexOf(search) > -1)
    );
  }

  clone() {
    Object.assign(this.editExpense, this.expense);
    var expenseType = this.TypeExpenses.find(x => x.ID_TYPE_EXPENSE == this.editExpense.ID_TYPE_EXPENSE);
    this.form.controls.id_type_expense.setValue(expenseType);
    this.form.controls.expense.setValue(this.editExpense.EXPENSE);
    this.form.controls.description_expense.setValue(this.editExpense.DESCRIPTION_EXPENSE);

    var date = this.datePipe.transform(new Date(this.editExpense.DATE_EXPENSE), 'yyyy-MM-dd');
    this.form.controls.date_expense.setValue(date);
    this.maxDate = date;

    this.form.controls.voucher.setValue(this.editExpense.VOUCHER);
  }
  update() {
    Swal.fire({
      icon: 'info',
      title: 'Generando la actualización...',
      text: 'Por favor espere.',
      didOpen: () => {
        Swal.showLoading(null);
      },
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.editExpense.ID_TYPE_EXPENSE = this.form.value.id_type_expense.ID_TYPE_EXPENSE;
    this.editExpense.DATE_EXPENSE = this.form.value.date;
    this.editExpense.DESCRIPTION_EXPENSE = this.form.value.description_expense;
    this.editExpense.EXPENSE = this.form.value.expense;
    this.editExpense.ID_EMPLOYEES = this.empleado.ID_EMPLOYEE;

    this.editExpense.VOUCHER = this.form.value.voucher;

    var self = this;
    this._expenseService.update(this.editExpense).subscribe(function (value) {
      if (value == 500) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error en la edición. Intentelo más tarde.',
        });
      } else {
        self.popUpCreate();
      }
    });
  }
  popUpCreate() {
    Swal.fire({
      icon: 'success',
      title: 'Gasto actualizado',
      text: 'El gasto fué actualizado',
      confirmButtonText: 'Aceptar',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      this.updateExpense.emit();
    });
  }
}
